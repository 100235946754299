import React, { useEffect, useState } from "react";
import { ApiCall } from "../../../services/ApiServices";
import { GET_SALARY_BENIFITS, MANAGE_SALARY_BENIFITS } from "../../../routes/ApiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import CustomNotify from "../../common/atoms/CustomNotify";
import { t } from "../translation/Translation";
import Header from "../../common/layout/Header";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import LabelField from "../../common/atoms/LabelField";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import Calender from "../../common/molecules/Calender";
import RadioField from "../../common/atoms/RadioField";
import BackLink from "../../common/atoms/BackLink";
import InputTextfield from "../configurations/manageCoefficientDeviations/InputTexfield";
import Button from "../../common/atoms/Button";
import { MANAGE_SALARY_BENEFITS } from "../../../routes/RouteConstants";

function SalaryBenefitForm() {
  const [data, setData] = useState({
    salaryBenefitName: "Betaalde feestdag",
    startDate: "2023-01-10",
    endDate: "2023-01-10",
    coefficientType: "0",
    coefficientValue: "1",
    occurrence: "",
    occurrenceValue: "0",
    absenceBenefit: false,
    loading: false,
  });

  const navigate = useNavigate();

  const handleRadioChange = (event: any) => {
    setData((prevState: any) => ({
      ...prevState,
      coefficientType: event?.target?.value,
      coefficientValue:
        event?.target?.value === "0" ? [] : prevState?.coefficientValue, // Reset if switching types
    }));
  };

  const handleAbsenceRadioChange = (event: any) => {
    const value = event.target.value === 'true';
    setData((prevState:any) => ({
      ...prevState,
      absenceBenefit: value,
    }));
  };

  const handleChange = (event: any) => {
    const { name, value, selected } = event?.target;
    switch (name) {
      case "coefficient_value":
        setData((prevState: any) => ({
          ...prevState,
          coefficientValue: value,
        }));
        break;
      case "occurrence":
        setData((prevState: any) => ({
          ...prevState,
          occurrence: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleCoefficientChange = (selected: any) => {
    const selectedValue = selected ? selected?.value : null;

    setData((prevState) => ({
      ...prevState,
      coefficientValue: selectedValue,
    }));
  };

  const { id } = useParams(); // Get the ID from the URL

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
 
  const fetchData = async () => {
    let url = `${GET_SALARY_BENIFITS}/${id}`;
    try {
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        true
      );
      if (response.success) {
        setData((prevState: any) => ({
          ...prevState,
          salaryBenefitName: response?.data?.name,
          startDate: response?.data?.start_date,
          endDate: response?.data?.end_date,
          occurrence: response?.data?.occurence,
          occurrenceValue: response?.data?.occurence_value,
          absenceBenefit: response?.data?.is_skip_absence,
          coefficientType: response?.data?.applicable_coefficient_type,
          coefficientValue: response?.data?.coefficient_value,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleSubmit = async (e: any) => {
    let url = `${GET_SALARY_BENIFITS}/${id}`;
    const dataObj = {
      salary_benefits_id: id,
      startdate: data?.startDate,
      enddate: data?.endDate,
      occurance: data?.occurrence,
      occurance_value: Number(data?.occurrenceValue),
      coefficient_type: data?.coefficientType,
      coefficient_value: data?.coefficientValue,
      is_holiday_applicable: data?.absenceBenefit,
      bbright_perfcode_id: id,
    };

    try {
      setData((prevState: any) => ({
        ...prevState,
        loading: true,
      }));
      const response = await ApiCall.service(url, "PUT", dataObj, true);
     
      if (response?.status === 200) {
        if (response.success) {
          setData((prevState: any) => ({
            ...prevState,
            startDate: response?.data?.startdate,
            endDate: response?.data?.enddate,
            occurrence: response?.data?.occurance,
            absenceBenefit: response?.data?.is_holiday_applicable
          }));
        }

        CustomNotify({
          message: t("Salary benefits successfully updated"),
          type: "success",
          autoClose: 3500,
        });
        navigate(`${MANAGE_SALARY_BENEFITS}`);
      } else {
        CustomNotify({
          message: t("Failed to update the data. Please try again."),
          type: "error",
          autoClose: 3500,
        });
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      CustomNotify({
        message: t("An error occurred while updating the data."),
        type: "error",
        autoClose: 3500,
      });
    } finally {
      setData((prevState: any) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleDateChange = (date: Date, field: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    setData((prevData) => ({
      ...prevData,
      [field]: formattedDate,
    }));
  };

  const handleoccurenceValue = (e: any) => {
    setData((prevState) => ({
      ...prevState,
      occurrenceValue: e?.target?.value,
    }));
  };
  const options = [
    { value: "coef_reduced", label: "Coef Reduced" },
    { value: "coef_transport", label: "Coef Transport" },
    { value: "coef_holiday", label: "Coef Holiday" },
    { value: "coef_holiday_reduced", label: "Coef Holiday Reduced" },
    { value: "coef_mtc", label: "Coef MTC" },
    { value: "coef_payroll", label: "Coef Payroll" },
    { value: "coef_meal_vouchers", label: "Coef Meal Vouchers" },
    { value: "coef_selection", label: "Coef Selection" },
    { value: "coef_holiday_payroll", label: "Coef Holiday Payroll" },
    { value: "dimona_cost", label: "Dimona Cost" },
    { value: "coef_eco", label: "Coef Eco" },
  ];
  return (
    <>
      <Header
        headerName={t("Edit salary benefits")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <BackLink backLink={"/manage-salary-benefits"} />
      </div>
      <div className="createpagesBg">
        <div className="row">
          <div className="col-3">
            <LabelWithInputField
              label={t("Salary benefit name")}
              placeholder={t("Please add salary benefit")}
              value={data.salaryBenefitName}
              name="name"
              id="name"
              isDisabled={true}
              labelClassName="mb-2 poppins-regular-18px"
              isMandatory={true}
              className="ps-2"
            />
          </div>
          <div className="col-2 editSalarycalendarSectionWrapper">
            <div>
              <LabelField title={t("Start date") + ":"} className="mb-2" />
            </div>
            <div className="position-relative">
              <Calender
                name="startDate"
                onChange={(date) => { date && handleDateChange(date, "startDate") }}
                selectedDate={new Date(data?.startDate)}
              />
            </div>
          </div>
          <div className="col-2 editSalarycalendarSectionWrapper">
            <div>
              <LabelField title={t("End date") + ":"} className="mb-2" />
            </div>
            <div className="position-relative">
              <Calender
                name="endDate"
                onChange={(date) => { date && handleDateChange(date, "endDate") }}
                selectedDate={new Date(data?.endDate)}
              />
            </div>
          </div>
          <div className="col-5">
            <LabelField title={t("Applicable coefficient")} className="mb-2" />{" "}
            <div>
              <RadioField
                name="coefficient"
                id="coefficient-employee-type"
                value="0"
                ischecked={data?.coefficientType === "0"}
                handleChange={() => {
                  setData((prevState) => ({
                    ...prevState,
                    coefficientType: "0",
                  }));
                }}
                label={t("Based on employee type in the cooperation agreement")}
                disabled={false} // If you want it enabled
                inputClass="editSalaryRadio me-2"
              />
              {data?.coefficientType === "0" && (
                <div className="multiSelectWrapper">
                  <SelectWithSearch
                    search
                    options={options}
                    value={
                      options?.find(
                        (option) => option?.value === data?.coefficientValue
                      ) || null
                    }
                    onChange={handleCoefficientChange}
                    isMulti={false}
                    error=""
                    id="coefficient_value"
                    name="coefficient_value"
                    isDisabled={false}
                  />
                </div>
              )}
            </div>
            <div>
              <RadioField
                name="coefficient_value"
                id="coefficient-other"
                value="1"
                ischecked={data?.coefficientType === "1"}
                handleChange={handleRadioChange}
                label={t("Other")}
                inputClass="editSalaryRadio me-2"
                disabled={false} // If you want it enabled
              />
              {data?.coefficientType === "1" && (
                <InputTextfield
                  type="text"
                  name="coefficient_value"
                  placeholder={t("Enter value")}
                  value={(data?.coefficientType === "1") ?  data?.coefficientValue : ''}
                  handleChange={handleChange} // Same handler function for value changes
                  className="ps-2"
                />
              )}
            </div>
          </div>
          <div className="col-3 mb-3">
            <LabelField title={t("Occurrence")} className="mb-2" />
            <div>
              <select
                name="occurrence"
                className="form-control inputFieldColor form-select functionCategoryField ps-2 shadow-none"
                value={data?.occurrence}
                onChange={handleChange}
              >
                <option value="select">{t("Select")}</option>
                <option value="hourly">{t("Hourly")}</option>
                <option value="day">{t("Day")}</option>
                <option value="weekly">{t("Weekly")}</option>
                <option value="contract">{t("Contract")}</option>
              </select>
            </div>
          </div>
          <div className="col-12">
            {data?.occurrence === "day" && (
              <div className="row">
                <div className="col-3">
                  <InputTextfield
                    type="text"
                    name="occurrence_value"
                    placeholder={t("Enter amount")}
                    value={data?.occurrenceValue}
                    handleChange={handleoccurenceValue}
                  />
                </div>
                <div className="col-6">
                  <LabelField
                    title={t(
                      "Is the benefit granted in case of absence of the employee"
                    )}
                  />
                  <div>
                    <RadioField
                      name="absence"
                      id="absence-yes"
                      value="true"
                      ischecked={data?.absenceBenefit === true}
                      handleChange={handleAbsenceRadioChange}
                      label={t("Yes")}
                      containerClassName="d-inline-block w-auto me-4" // Customize if needed
                      inputClass="editSalaryRadio"
                    />
                    <br />
                    <RadioField
                      name="absence"
                      id="absence-no"
                      value="false"
                      ischecked={data?.absenceBenefit === false}
                      handleChange={handleAbsenceRadioChange}
                      label={t("No")}
                      containerClassName="d-inline-block w-auto me-4" // Customize if needed
                      inputClass="editSalaryRadio"
                    />
                  </div>
                </div>
              </div>
            )}
            {data?.occurrence === "hourly" && (
              <div>
                <LabelField
                  title={t(
                    "Is the benefit granted in case of absence of the employee"
                  )}
                />
                <div>
                  <RadioField
                    name="absence"
                    id="absence-yes"
                    value="true"
                    ischecked={data?.absenceBenefit === true}
                    handleChange={handleAbsenceRadioChange}
                    label={("Yes")}
                    containerClassName="d-inline-block w-auto me-4" // Customize if needed
                    inputClass="editSalaryRadio"
                  />
                  <br />
                  <RadioField
                    name="absence"
                    id="absence-no"
                    value="false"
                    ischecked={data?.absenceBenefit === false}
                    handleChange={handleAbsenceRadioChange}
                    label={t("No")}
                    containerClassName="d-inline-block w-auto me-4" // Customize if needed
                    inputClass="editSalaryRadio"
                  />
                </div>
              </div>
            )}
            {(data?.occurrence === "weekly" ||
              data?.occurrence === "contract") && (
                <div className="row">
                  <div className="col-3">
                    <InputTextfield
                      type="text"
                      name="occurrence_value"
                      placeholder={t("Enter amount")}
                      value={data?.occurrenceValue}
                      handleChange={handleoccurenceValue}
                      className="ps-2"
                    />
                  </div>
                </div>
              )}
          </div>
          <div className="col-12">
            <Button title={t("Save")} className="searchBtn float-end" handleClick={handleSubmit} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SalaryBenefitForm;
