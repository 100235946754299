import React from "react";
import { t } from "../common/atoms/translation";
import ToggleButton from "../common/atoms/ToggleButton";
import LabelField from "../common/atoms/LabelField";
import { EmployeeAgreementsProps } from "./services/Interfaces";

const EmployeeAgreements: React.FC<EmployeeAgreementsProps> = ({
  state,
  handleToggle,
}) => {
  return (
    <div className="row mb-3">
      <div className="col-12 d-flex align-items-center mb-3">
        <LabelField
          title={t("I agree to the GDPR data")}
          className="mb-0 me-3 colorPrimary poppins-medium"
          isMandatory
        />
        <ToggleButton
          value={state.user_terms.is_gdpr}
          onToggle={handleToggle("user_terms", "is_gdpr")}
        />
        {state.errors["user_terms.is_gdpr"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_gdpr"]}
          </div>
        )}
      </div>
      <div className="col-12 d-flex align-items-center mb-3">
        <LabelField
          title={t(
            "The temporary employment agency and the candidate temporary worker confirm the intention to enter into one or more employment contract(s) for temporary work.Letter of intent"
          )}
          className="mb-0 me-3 colorPrimary poppins-medium"
          isMandatory
        />
        <ToggleButton
          value={state.user_terms.is_temp_employement}
          onToggle={handleToggle("user_terms", "is_temp_employement")}
        />
        {state.errors["user_terms.is_temp_employement"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_temp_employement"]}
          </div>
        )}
      </div>
      <div className="col-12 d-flex align-items-center mb-3">
        <LabelField
          title={t("I agree to digitally sign contracts")}
          className="mb-0 me-3 colorPrimary poppins-medium"
          isMandatory
        />
        <ToggleButton
          value={state.user_terms.is_contract_sign}
          onToggle={handleToggle("user_terms", "is_contract_sign")}
        />
        {state.errors["user_terms.is_contract_sign"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_contract_sign"]}
          </div>
        )}
      </div>
      <div className="col-12 d-flex align-items-center mb-3">
        <LabelField
          title={t("I agree to the Terms and Conditions")}
          className="mb-0 me-3 colorPrimary poppins-medium"
          isMandatory
        />
        <ToggleButton
          value={state.user_terms.is_term_condition}
          onToggle={handleToggle("user_terms", "is_term_condition")}
        />
        {state.errors["user_terms.is_term_condition"] && (
          <div className="text-danger">
            {state.errors["user_terms.is_term_condition"]}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeAgreements;
