import { AxiosCall } from "../../../services/AxiosService";
import {
  validateAddress,
  validateAddressHouseNumberFields,
  validateAddressNumberFields,
  validateAddressStringFields,
  validateEmail,
  validateMultiChoiceAnswer,
  validateNameFields,
  validateNumberFields,
  validatePassword,
  validateSSN,
  validateStringFields,
} from "../../../services/ValidationService";
import { t } from "../../CentralDataMangement/translation/Translation";
import { EmployeeFormData, FormErrors } from "./Interfaces";
import { ADDRESS_FIELDS } from "./State";

export const updateField = (formData: any, fieldPath: string, value: any) => {
  const fieldNames = fieldPath.split("."); // Split the field path
  let updatedFormData = { ...formData }; // Shallow copy of formData
  let currentLevel = updatedFormData;

  // Traverse through field names to reach the final field
  for (let i = 0; i < fieldNames.length - 1; i++) {
    const fieldName = fieldNames[i];
    if (!currentLevel[fieldName]) {
      currentLevel[fieldName] = {}; // Initialize if undefined
    }
    currentLevel = currentLevel[fieldName];
  }

  // Set the new value at the final field level
  currentLevel[fieldNames[fieldNames.length - 1]] = value;

  return updatedFormData;
};

export const uploadFile = async (
  file: File,
  name: string,
  fileType: number,
  path: string
) => {
  const fileData = new FormData();
  fileData.append("file", file);
  fileData.append("name", name);
  fileData.append("file_type", fileType.toString());

  try {
    const response = await AxiosCall.fileUploadService(fileData, path);
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};

export const setResponseData = (data: any, registry_number: string = "") => {
  return (prevFormData: any) => {
    const existingDefaultEmpLegal = prevFormData.default_emp_legal || [];

    return {
      ...prevFormData,
      id: data?.id || prevFormData.id,
      is_national: data?.is_national || prevFormData.is_national,
      nationality: data?.nationality || prevFormData.nationality,
      registry_number: registry_number || prevFormData.registry_number,
      national_registry_number:
        registry_number || prevFormData.national_registry_number,
      work_permit: {
        number: data?.work_permit_number || prevFormData.work_permit.number,
        end_date:
          data?.work_permit_end_date || prevFormData.work_permit.end_date,
      },
      title: data?.title || prevFormData.title,
      first_name: data?.first_name || prevFormData.first_name,
      last_name: data?.last_name || prevFormData.last_name,
      email: data?.email || prevFormData.email,
      confirm_email: data?.email || prevFormData.confirm_email,
      phone_number: data?.phone_number || prevFormData.phone_number,
      education: data?.education || prevFormData.education,
      security: {
        password: data?.security_password || prevFormData.security.password,
        confirm_password:
          data?.security_confirm_password ||
          prevFormData.security.confirm_password,
      },
      gender: data?.gender || prevFormData.gender,
      date_of_birth: data?.date_of_birth || prevFormData.date_of_birth,
      place_of_birth: data?.place_of_birth || prevFormData.place_of_birth,
      country_of_birth: data?.country_of_birth || prevFormData.country_of_birth,
      civil_status: data?.civil_status || prevFormData.civil_status,
      desired_employee_type:
        data?.desired_employee_type || prevFormData.desired_employee_type,
      language: data?.language || prevFormData.language,
      bank_account: {
        type: data?.bank_account?.type || prevFormData.bank_account.type,
        iban: data?.bank_account?.iban ?? prevFormData.bank_account.iban,
        bic: data?.bank_account?.bic || prevFormData.bank_account.bic,
        iban_country_code: (
          data?.bank_account?.iban_country_code ||
          prevFormData.bank_account.iban_country_code
        )?.toUpperCase(),
      },
      address: {
        domicile: {
          ...prevFormData?.address.domicile,
          id: data?.id
            ? data?.address?.domicile?.id
            : data?.address?.residence?.id ?? prevFormData.address.domicile.id,
          street: data?.id
            ? data?.address?.domicile?.street
            : data?.address?.residence?.street ??
              prevFormData.address.domicile.street,
          number: data?.id
            ? data?.address?.domicile?.number
            : prevFormData.sameAddress
            ? data?.address?.residence?.number
            : prevFormData.address?.domicile?.number,
          postal_code: data?.id
            ? data?.address?.domicile?.postal_code
            : data?.address?.residence?.postal_code ??
              prevFormData.address.domicile.postal_code,
          box: data?.id
            ? data?.address?.domicile?.box
            : data?.address?.residence?.box ??
              prevFormData.address.domicile.box,
          city: data?.id
            ? data?.address?.domicile?.city
            : data?.address?.residence?.city ??
              prevFormData.address.domicile.city,
          country: data?.id
            ? data?.address?.domicile?.country
            : data?.address?.residence?.country ??
              prevFormData.address.domicile.country,
          latitude: data?.id
            ? data?.address?.domicile?.latitude
            : data?.address?.residence?.latitude ??
              prevFormData.address.domicile.latitude,
          longitude: data?.id
            ? data.address?.domicile?.longitude
            : data.address?.residence?.longitude ??
              prevFormData.address.domicile.longitude,
        },
        residence: {
          ...prevFormData.address.residence,
          id: data?.address?.residence?.id ?? prevFormData.address.residence.id,
          street:
            data?.address?.residence?.street ??
            prevFormData.address.residence.street,
          number:
            data?.address?.residence?.number ??
            prevFormData.address.residence.number,
          postal_code:
            data?.address?.residence?.postal_code ??
            prevFormData.address.residence.postal_code,
          box:
            data?.address?.residence?.box ?? prevFormData.address.residence.box,
          city:
            data?.address?.residence?.city ??
            prevFormData.address.residence.city,
          country:
            data?.address?.residence?.country ??
            prevFormData.address.residence.country,
          latitude:
            data?.address?.residence?.latitude ??
            prevFormData.address.residence.latitude,
          longitude:
            data?.address?.residence?.longitude ??
            prevFormData.address.residence.longitude,
        },
      },
      driving_license: data?.driving_license || prevFormData.driving_license,
      transport_type: data?.transport_type || prevFormData.transport_type,
      dependencies: {
        dependent_spouce:
          data?.dependencies?.dependent_spouce ||
          prevFormData.dependencies.dependent_spouce,
        dependent_children:
          data?.dependencies?.dependent_children ||
          prevFormData.dependencies.dependent_children,
      },
      emergency_contact: {
        ...prevFormData.emergency_contact,
        name:
          data?.emergency_contact?.name ?? prevFormData.emergency_contact.name,
        contact_number:
          data?.emergency_contact?.contact_number ??
          prevFormData.emergency_contact.contact_number,
        email:
          data?.emergency_contact?.email ??
          prevFormData.emergency_contact.email,
        relation:
          data?.emergency_contact?.relation ??
          prevFormData.emergency_contact.relation,
      },
      profile_picture_url:
        data?.profile_picture_url || prevFormData.profile_picture_url,
      profile_picture_id:
        data?.profile_picture_id || prevFormData.profile_picture_id,
      front_pic_url: data?.front_pic_url || prevFormData.front_pic_url,
      front_pic_id: data?.front_pic_id || prevFormData.front_pic_id,
      back_pic_url: data?.back_pic_url || prevFormData.back_pic_url,
      back_pic_id: data?.back_pic_id || prevFormData.back_pic_id,
      work_permit_file_id:
        data?.work_permit_file_id || prevFormData.work_permit_file_id,
      work_permit_file_url:
        data?.work_permit_file_url || prevFormData.work_permit_file_url,
      work_permit_file_name:
        data?.work_permit_file_name || prevFormData.work_permit_file_name,
      front_pic_name: data?.front_pic_name || prevFormData.front_pic_name,
      back_pic_name: data?.back_pic_name || prevFormData.back_pic_name,
      user_terms: {
        ...prevFormData.user_terms,
        is_term_condition:
          data?.user_terms?.is_term_condition ??
          prevFormData.user_terms.is_term_condition,
        is_contract_sign:
          data?.user_terms?.is_contract_sign ??
          prevFormData.user_terms.is_contract_sign,
        is_temp_employement:
          data?.user_terms?.is_temp_employement ??
          prevFormData.user_terms.is_temp_employement,
        is_gdpr: data?.user_terms?.is_gdpr ?? prevFormData.user_terms.is_gdpr,
      },
      default_emp_legal: [
        ...(data?.default_emp_legal ?? []),
        ...existingDefaultEmpLegal.slice(data?.default_emp_legal?.length),
      ].map((item, index) => ({
        paritaire_commitee_id:
          data?.default_emp_legal?.[index]?.paritaire_commitee_id ??
          existingDefaultEmpLegal[index]?.paritaire_commitee_id,
        employee_type_id:
          data?.default_emp_legal?.[index]?.employee_type_id ??
          existingDefaultEmpLegal[index]?.employee_type_id,
        function_id:
          data?.default_emp_legal?.[index]?.function_id ??
          existingDefaultEmpLegal[index]?.function_id,
        minWage:
          data?.default_emp_legal?.[index]?.minWage ??
          existingDefaultEmpLegal[index]?.minWage,
        actual_salary:
          data?.default_emp_legal?.[index]?.actual_salary ??
          existingDefaultEmpLegal[index]?.actual_salary,
        is_actual_added:
          data?.default_emp_legal?.[index]?.is_actual_added ??
          existingDefaultEmpLegal[index]?.is_actual_added,
        company_id:
          data?.default_emp_legal?.[index]?.company_id ??
          existingDefaultEmpLegal[index]?.company_id,
      })),
      companies: data?.companies || prevFormData.companies,
      role: data?.role || prevFormData.role,
      status: prevFormData.status,
      source: data?.source || prevFormData.source,
      extra_ref: data?.extra_ref || prevFormData.extra_ref,
      sameAddress: data?.id
        ? data?.address?.residence?.id === data?.address?.domicile?.id
        : prevFormData.sameAddress,
      ssnVerified: true,  // It will be true to check if the user has verified the ssn ( Register / Update )
    };
  };
};

//Validations
export const formValidation = (
  state: EmployeeFormData,
  user: any
): FormErrors => {
  const errors: any = {};

  validateStringFields(state, errors);
  validateNumberFields(state, errors);
  validateNameFields(state, errors);

  // Validate addresses
  validateAddress(
    errors,
    state.address.domicile,
    "address.domicile",
    ADDRESS_FIELDS
  );
  validateAddress(
    errors,
    state.address.residence,
    "address.residence",
    ADDRESS_FIELDS
  );
  validateAddressStringFields(state, errors);
  validateAddressHouseNumberFields(state, errors);
  validateAddressNumberFields(state, errors);

  const validateNestedObject = (
    obj: Record<string, any>,
    prefix: string,
    requiredFields: string[],
    customValidation?: (obj: Record<string, any>) => void
  ) => {
    requiredFields.forEach((field) => {
      const value = obj[field];

      // Check if the value is undefined, null, or an empty string (after trimming if it's a string)
      if (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      ) {
        errors[`${prefix}.${field}`] = t(`This field is required`);
      }
    });
    // Custom condition for dependent_children being equal to 0
    if (obj.dependent_children === 0 && state.id === 0) {
      errors[`${prefix}.dependent_children`] = t(`This field is required`);
    }

    if (customValidation) {
      customValidation(obj);
    }
  };

  validateNestedObject(state.bank_account, "bank_account", [
    "iban",
    "iban_country_code",
  ]);
  validateNestedObject(state.dependencies, "dependencies", [
    "dependent_spouce",
    "dependent_children",
  ]);
  validateNestedObject(state.emergency_contact, "emergency_contact", [
    "name",
    "contact_number",
  ]);
  validateNestedObject(state.user_terms, "user_terms", [
    "is_term_condition",
    "is_contract_sign",
    "is_temp_employement",
    "is_gdpr",
  ]);
if(state.id === 0) {
  validateNestedObject(
    state.security,
    "security",
    ["password", "confirm_password"],
  );
}

  // Validate default_emp_legal array
  if (state.default_emp_legal.length === 0 && user.token) {
    errors.default_emp_legal =
      t("At least one Function & hourly wage detail is required");
  } else {
    state.default_emp_legal.forEach((legal, index) => {
      if (!legal.company_id) {
        errors[`default_emp_legal.${index}.company_id`] =
          t("This field is required");
      } else {
        errors[`default_emp_legal.${index}.company_id`] = null;
      }
      if (!legal.paritaire_commitee_id) {
        errors[`default_emp_legal.${index}.paritaire_commitee_id`] =
        t("This field is required");
      } else {
        delete errors[`default_emp_legal.${index}.paritaire_commitee_id`];
      }
      if (!legal.employee_type_id) {
        errors[`default_emp_legal.${index}.employee_type_id`] =
        t("This field is required");
      } else {
        delete errors[`default_emp_legal.${index}.employee_type_id`];
      }
      if (!legal.function_id) {
        errors[`default_emp_legal.${index}.function_id`] =
        t("This field is required");
      } else {
        delete errors[`default_emp_legal.${index}.function_id`];
      }
      if (!legal.minWage) {
        errors[`default_emp_legal.${index}.minWage`] = t("This field is required");
      } else {
        delete errors[`default_emp_legal.${index}.minWage`];
      }
      const actualSalaryStr = state.default_emp_legal?.[index]?.actual_salary;
      const minWageStr = state.default_emp_legal?.[index]?.minWage;

      // Regular expression to validate only digits and commas
      const salaryRegex = /^[0-9,]+$/;

      if (!salaryRegex.test(actualSalaryStr) && actualSalaryStr !== "") {
        errors[`default_emp_legal.${index}.actual_salary`] =
          t("Actual salary can only contain numbers and commas");
      } else {
        const actualSalary = parseFloat(actualSalaryStr.replace(",", "."));
        const minWage = parseFloat(minWageStr.replace(",", "."));
        if (!isNaN(actualSalary) && !isNaN(minWage) && actualSalary < minWage) {
          errors[`default_emp_legal.${index}.actual_salary`] =
            t("Actual salary must be greater than or equal to minimum wage");
        }
      }
    });
  }

  // Additional validation
  if (state.is_national) {
    // Step 1: Check if registry_number is missing and nationality is Belgian (29)
    if (!state.registry_number) {
      if (state.nationality === 29) {
        errors.registry_number = t("This field is required"); // Required for Belgian nationals
      } else {
        delete errors.registry_number;
      }
      delete errors["work_permit.number"];
      delete errors["work_permit.end_date"];
      delete errors["work_permit.work_permit_file_url"];
    }
    // Step 2: Validate Belgian SSN format and birth date consistency
    else if (
      state.nationality === 29 &&
      state.country_of_birth === 29 &&
      state.registry_number !== "" &&
      state.date_of_birth !== ""
    ) {
      const errorMessage = validateSSN(state.registry_number); // General SSN validation
      const validationMessage = validateBelgianSSN(
        state.date_of_birth,
        state.registry_number
      ); // Belgian SSN and DOB match

      // If there's an error in either SSN or DOB validation, set the errors and reset DOB
      if (errorMessage || validationMessage) {
        errors.registry_number = errorMessage;
        errors.date_of_birth = validationMessage;
        errors.ssnDOBError = true;
      } else {
        // No errors, clear the error fields
        delete errors.registry_number;
        delete errors.date_of_birth;
        delete errors.ssnDOBError;
      }
    }
    // Step 3: Check if registry_number matches the national_registry_number
    else if (
      state.registry_number !== state.national_registry_number && state.ssnVerified &&
      state.nationality === 29
    ) {
      errors.registry_number = t("Invalid SSN. Please enter valid SSN.");
    }
  } else if(!state.is_national) {
    // Non-EU Citizen: Validate Work Permit fields

    validateNestedObject(state.work_permit, "work_permit", [
      "number",
      "end_date",
      "work_permit_file_id",
    ]);
  }

  const validateEmailFields = () => {
    errors.email = validateEmail(state.email);
    errors.confirm_email = validateEmail(state.confirm_email);
    // Remove keys with null or falsy values
    Object.keys(errors).forEach((key) => {
      if (errors[key] == null) {
        delete errors[key]; // Remove the key from the object
      }
    });
  };
  if (user.token){
    errors.companies = validateMultiChoiceAnswer(state.companies); // Only works for login users
  }
  validateEmailFields();
  return errors;
};

// Only for validate Belgium SSN with DOB
function validateBelgianSSN(date_of_birth: string, registry_number: string) {
  // Format the date_of_birth to YY.MM.DD
  const birthDate = new Date(date_of_birth);
  const formattedDOB = birthDate
    .toISOString()
    .split("T")[0]
    .replace(/-/g, ".")
    .slice(2); // YY.MM.DD
  // Extract the first part of the registry_number
  const ssnDOB = registry_number.slice(0, 8); // YY.MM.DD
  if (formattedDOB !== ssnDOB) {
    return t("The birth date must be consistent with social security number for Belgian nationality");
  }
  return null;
}
