import React, { ChangeEvent, ReactNode } from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";

interface LabelWithInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number | any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  multiLabel?: string;
  isTop?: boolean;
  colClassName?: string;
  labelWrapperClassName?: string;
  children?: ReactNode;
  disableCopyPaste?: boolean; // Optional prop to disable copy-paste
}

export const LabelWithInputField: React.FC<LabelWithInputFieldProps> = ({
  type,
  className,
  placeholder,
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  autoComplete,
  handleChange,
  multiLabel,
  isTop = false,
  colClassName,
  labelWrapperClassName,
  children,
  disableCopyPaste,
}) => {
  const shouldUseFullWidth = !label || (label && isTop);

  return (
    <>
        {label && (
          <div
            className={`text-start ${
              isTop ? "col-12" : ` ${labelWrapperClassName} align-self-center`
            }`}
          >
            <LabelField
              title={label}
              className={labelClassName}
              isMandatory={isMandatory}
              htmlfor={id}
              subtitle={multiLabel}
            />
          </div>
        )}
        <div  className={`text-start ${shouldUseFullWidth ? "col-12" : `${colClassName}`}`}>

        <InputTextfield
          id={id}
          type={type}
          className={`form-control field-shadow rounded-3 inputFieldColor ${className}`}
          value={value}
          isDisabled={isDisabled}
          placeholder={placeholder}
          customStyle={customStyle}
          handleChange={handleChange ?? (() => {})}
          handleBlur={handleBlur}
          name={name}
          error={error}
          min={min}
          step={step}
          max={max}
          readOnly={readOnly}
          autoComplete={autoComplete}
          disableCopyPaste={disableCopyPaste}
        />
        {children}
        </div>
      </>
  );
};
