import React, { ChangeEvent, ReactNode } from "react";
import LabelField from "../atoms/LabelField";
import MaskedInput from "react-text-mask";

interface LabelWithMaskInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number | any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  multiLabel?: string;
  isTop?: boolean;
  colClassName?: string;
  labelWrapperClassName?: string;
  children?: ReactNode;
  disableCopyPaste?: boolean; // Optional prop to disable copy-paste
  mask?: (string | RegExp)[]; // Add mask prop for MaskedInput
  guide?: boolean; // Optional guide prop for MaskedInput
  showMask?: boolean; // Optional showMask prop for MaskedInput
}

export const LabelWithMaskInputField: React.FC<
  LabelWithMaskInputFieldProps
> = ({
  type,
  className,
  placeholder,
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  autoComplete,
  handleChange,
  multiLabel,
  isTop = false,
  colClassName,
  labelWrapperClassName,
  children,
  disableCopyPaste,
  mask,
  guide = true,
  showMask = false,
}) => {
  const shouldUseFullWidth = !label || (label && isTop);

  return (
    <>
      {label && (
        <div
          className={`text-start ${
            isTop ? "col-12" : ` ${labelWrapperClassName} align-self-center`
          }`}
        >
          <LabelField
            title={label}
            className={labelClassName}
            isMandatory={isMandatory}
            htmlfor={id}
            subtitle={multiLabel}
          />
        </div>
      )}
      <div
        className={`text-start ${
          shouldUseFullWidth ? "col-12" : `${colClassName}`
        }`}
      >
        <div>
          <MaskedInput
            mask={mask ?? []} // Provide an empty array if mask is undefined
            guide={guide}
            showMask={showMask}
            id={id}
            type={type}
            className={`form-control field-shadow rounded-3 inputFieldColor ${className}`}
            value={value}
            disabled={isDisabled}
            placeholder={placeholder}
            style={customStyle}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            readOnly={readOnly}
            autoComplete={autoComplete}
            // Custom logic to disable copy-paste if needed
            onCopy={disableCopyPaste ? (e) => e.preventDefault() : undefined}
            onPaste={disableCopyPaste ? (e) => e.preventDefault() : undefined}
          />
          {error && <div className="text-danger">{error}</div>}
        </div>
        {children}
      </div>
    </>
  );
};
