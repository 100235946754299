import { Dispatch } from 'redux'; // Import Dispatch if needed for typing
import { setScheduleErrors, setScheduleWarnings } from '../../../features/planning/planningSlice'; // Adjust the path to your slice
import { RootState } from '../../../store'; // Import the RootState type
import { t } from '../../CentralDataMangement/translation/Translation';
import { pcMaxAndMinHrs } from './PlanningTimingValidations';
import { calculateShiftsTotalTime } from '../../common/utlis/PlanningTimingHelper';

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center?: number;
  shifts: Shifts[];
  error?: string;
  warning?: string[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}
// Helper function to compare if a time is before the current live time (now)
export const isBeforeLiveTime = (date: string, time: string): boolean => {
  const now = new Date(); // Get the current time (live time)
  const shiftTime = new Date(`${date} ${time}`).getTime(); // Parse the shift time

  return shiftTime < now.getTime(); // Return true if the shift is before current live time
};
// Validate time in "HH:MM" format
export const isValidTime = (time: string): boolean =>
  /^([01]\d|2[0-3]):([0-5]\d)$/.test(time) && time.length === 5;

// Validate a shift's start and end times
export const validateShift = (shift: Shifts
): string => {
  const { start_time, end_time } = shift;
  // Allow shifts with empty start or end times
  if (start_time === "" && end_time === "") {
    return "Empty Timings"; // Consider empty start or end times as valid; no error message
  }

  // Check if both start and end times are valid
  if (!isValidTime(start_time)) {
    return "Invalid start time format"; // Invalid start time
  }
  if (!isValidTime(end_time)) {
    return "Invalid end time format"; // Invalid end time
  }
  return ""; // Valid shift; no error message
};
// Validation function with dispatch as a parameter
export const validatePlanningFields = (state: RootState, schedule: ScheduleMap, pcId: number): string => {
  let errorMessage = "";
  // Check each employee's schedule
  if (!schedule || Object.keys(schedule).length === 0) {
    errorMessage = t('No schedule for current employee.');
    // dispatch(setScheduleErrors({ employee_id: employee.employee_id, date: '', errors: errorMessage }));
    // hasErrors = true;
  }

  for (let date in schedule) {
    const shifts = schedule[date]?.shifts;
    const totalHours = calculateShiftsTotalTime(shifts, 2);
    // const is_validated = validateShift(shifts);
    if (!shifts || shifts.length === 0) {
      errorMessage = t('No shifts for employee.');
    }
    // Sort shifts by start_time to check for overlap
    const sortedShifts = [...shifts].sort((a, b) => new Date(`${date} ${a.start_time}`).getTime() - new Date(`${date} ${b.start_time}`).getTime());

    for (let i = 0; i < sortedShifts.length; i++) {
      const shift = sortedShifts[i];

      // Check if start_time or end_time is missing
      if (!shift.start_time || !shift.end_time) {
        errorMessage = t('Empty shift times for employee.');
      }

      // Validate shift timing
      const validationError = validateShift(shift);
      if (validationError) {
        errorMessage = t(validationError);
      }

      // Check if the start time is before the current live time
      if (isBeforeLiveTime(date, shift.start_time)) {
        errorMessage = t('Shift for employee starts before the current time.');
      }
      let maxAndMinValidations = pcMaxAndMinHrs(state, pcId, totalHours);
      if (maxAndMinValidations != "") {
        errorMessage = maxAndMinValidations;
      }

      // Compare shifts to detect overlap
      if (i < sortedShifts.length - 1) {
        const nextShift = sortedShifts[i + 1];
        const currentShiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();
        const nextShiftStartTime = new Date(`${date} ${nextShift.start_time}`).getTime();

        if (currentShiftEndTime > nextShiftStartTime) {
          errorMessage = t('Shift overlap detected for employee.');
        }
      }
    }
  }
  return errorMessage;
};
// Function to handle 422 validation errors from the API
export const handleValidationErrors = (response: any, dispatch: Dispatch) => {
  if (response) {
    const { errors, warnings } = response;
    // Loop through each error key and extract details
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        // Regular expression to match employee ID and date from the error key
        const regex = /planning\.(\d+)\.schedule\.(\d{4}-\d{2}-\d{2})/;
        const match = key.match(regex);
        if (match) {
          const employeeId = match[1]; // Extracted employee ID
          const date = match[2]; // Extracted date

          // Get the error message for the specific employee and date
          const errorMessages = errors[key];
          console.error(errorMessages);
          // Dispatch the errors to the store or handle them in the UI
          dispatch(
            setScheduleErrors({
              employee_id: parseInt(employeeId),
              date,
              errors: errorMessages,
            })
          );
        }
      }
    }
    if (warnings.length > 0) {
      warnings.map((warning: any) => {
        const regex = /planning\.(\d+)\.schedule\.(\d{4}-\d{2}-\d{2})/;
        const match = warning?.field.match(regex);
        if (match) {
          const employeeId = match[1]; // Extracted employee ID
          const date = match[2]; // Extracted date

          // Get the error message for the specific employee and date

          // Dispatch the errors to the store or handle them in the UI
          dispatch(
            setScheduleWarnings({
              employee_id: parseInt(employeeId),
              date,
              warnings: warning?.message,
            })
          );
        }
      })
    }
  };
};
export const validatePlanningFieldsForDate = (
  state: RootState,
  schedule: Schedule,
  pcId: number,
  date: string
): string => {
  let errorMessage = "";

  if (!schedule || !schedule.shifts || schedule.shifts.length === 0) {
    return t('No shifts for employee on this date.');
  }

  const shifts = schedule.shifts;
  const totalHours = calculateShiftsTotalTime(shifts, 2);

  // Sort shifts by start_time to check for overlap
  const sortedShifts = [...shifts].sort(
    (a, b) => new Date(`${date} ${a.start_time}`).getTime() - new Date(`${date} ${b.start_time}`).getTime()
  );

  for (let i = 0; i < sortedShifts.length; i++) {
    const shift = sortedShifts[i];

    // Check if start_time or end_time is missing
    if (!shift.end_time) {
      return t('Empty end shift times for employee.');
    }

    // Validate shift timing
    const validationError = validateShift(shift);
    if (validationError) {
      return t(validationError);
    }

    // Check if the start time is before the current live time
    if (isBeforeLiveTime(date, shift.start_time)) {
      return t('Shift for employee starts before the current time.');
    }

    // Check max and min hours
    let maxAndMinValidations = pcMaxAndMinHrs(state, pcId, totalHours);
    if (maxAndMinValidations !== "") {
      return maxAndMinValidations;
    }

    // Compare shifts to detect overlap
    if (i < sortedShifts.length - 1) {
      const nextShift = sortedShifts[i + 1];
      const currentShiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();
      const nextShiftStartTime = new Date(`${date} ${nextShift.start_time}`).getTime();

      if (currentShiftEndTime > nextShiftStartTime) {
        return t('Shift overlap detected for employee.');
      }
    }
  }

  return errorMessage;
};

