import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import DateAndTime from "../common/atoms/DateWithTime";
import PlanningTable from "../Planning/PlanningTable";
import PlanningTabs from "../Planning/PlanningTabs";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import DashboardPlannings from "../Planning/DashboardPlannings";
import DashboardAccordion from "../common/atoms/DashboardAccordion";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import { GET_TODAY_PLANNINGS } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import TimeRegistrationTable from "../TimeRegistration/TimeRegistrationTable";

interface User {
  user_id: number;
  profilePhoto: string | null;
  name: string;
  start_time: string;
  end_time: string;
  employee_type: string;
  employee_type_id: number;
  pc_name: string;
  salary: string;
  function_name: string;
  function_id: number;
  planning_id: number;
  location_id: string;
  company_id: string;
  planned_date: string;
  shifts: {
    shift1: { start_time: string; end_time: string };
    shift2?: { start_time: string; end_time: string };
  };
  display_timings: string;
  latest_shift_status?: string;
  status?: string;
}

const LocationDashboard = () => {
  const show = useSelector(selectPlanningShow); // Access the `show` state

  const locations = useSelector(selectCurrentLocations) ?? [];
  const user = useSelector((state: any) => state?.auth);
  const companyId = user?.companies[0]?.id;
  // const [todayPlanningsByLocation , setTodayPlanningsByLocation] = useState([]);
  const [todayPlanningsByLocation, setTodayPlanningsByLocation] = useState<Record<number, any>>({});
  const [refresh, setRefresh] = useState<boolean>(false);

  // State to track which accordion is expanded
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    null
  );
  const users: User[] = [];

  const handleToggle = async (locationId: number) => {

    console.log(companyId, locationId);

    // Check if the locationId already exists in the state and has valid user data
    if (
      todayPlanningsByLocation[locationId] &&
      todayPlanningsByLocation[locationId].length > 0 // Ensure there are users present
    ) {
      console.log(`Data for locationId ${locationId} already exists and is valid. Skipping API call.`);
      return; // Skip the API call if the data already exists and is valid
    }
    try {
      const response = await ApiCall.getService(
        `${GET_TODAY_PLANNINGS}?company_id=${companyId}&location_id=${locationId}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode == 200) {

        console.log('API Response:', response.data);

        const { start, pause, stop, yetToStart } = response.data;

        const users: User[] = [];

        const addUsersWithStatus = (statusData: { [key: string]: User }, status: string) => {
          Object.values(statusData).forEach(user => {
            users.push({
              ...user,
              status,
            });
          });
        };

        addUsersWithStatus(start, 'start');
        addUsersWithStatus(pause, 'pause');
        addUsersWithStatus(stop, 'stop');
        addUsersWithStatus(yetToStart, 'yetToStart');

        // Store planning data with users and status in state
        setTodayPlanningsByLocation((prevState) => ({
          ...prevState,
          [locationId]: users,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(todayPlanningsByLocation);

  const updateEmployeeStatus = async (locationId: number, employeeId: number) => {
    console.log(companyId, locationId);
    try {
      const response = await ApiCall.getService(
        `${GET_TODAY_PLANNINGS}?company_id=${companyId}&location_id=${locationId}&employee_id=${employeeId}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.statusCode == 200) {

        console.log('API Response:', response.data);

        const { start, pause, stop, yetToStart } = response.data;

        const users: User[] = [];

        const addUsersWithStatus = (statusData: { [key: string]: User }, status: string) => {
          Object.values(statusData).forEach(user => {
            users.push({
              ...user,
              status,
            });
          });
        };

        addUsersWithStatus(start, 'start');
        addUsersWithStatus(pause, 'pause');
        addUsersWithStatus(stop, 'stop');
        addUsersWithStatus(yetToStart, 'yetToStart');

        setTodayPlanningsByLocation((prevState) => {
          // Create a shallow copy of the existing location data
          const existingLocationData = prevState[locationId] || [];

          // Create a mapping of user IDs to their current data
          //This adds an entry to existingUserMap with the user_id as the key and the user object as the value
          const existingUserMap = existingLocationData.reduce((acc: any, user: User) => {
            acc[user.user_id] = user;
            return acc;
          }, {});

          //Update existing users or add new ones => const users: User[] = [];
          users.forEach(user => {
            if (existingUserMap[user.user_id]) {
              existingUserMap[user.user_id] = {
                ...existingUserMap[user.user_id], // Retain existing data
                ...user, // Merge with new data
              };
            }
          });

          // Convert the user map back to an array
          const updatedLocationData = Object.values(existingUserMap);
          console.log(updatedLocationData, 'updated location data with employees');

          return {
            ...prevState,
            [locationId]: updatedLocationData,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(todayPlanningsByLocation);


  return (
    <>
      <Header
        headerName={t("Dashboard")}
        headerClassName="myAccountCardTitle"
      />
      <div className="row">
        <div className="col-12">
          {locations.length > 0 && locations.map((location: any) => (
            <div className="headerWrapper mb-2" key={location.id}>
              <DashboardAccordion
                accordionTitle={t(location.name)}
                accordionTitleClassName="w-100 text-center poppins-medium dashboardAccordionTitle"
                accordionButton="dashboardAccordionHeader shadow-none"
                id={location.id.toString()}
                expanded={expandedAccordion === location.id.toString()}
                onToggle={() => handleToggle(location.id)}
              >
                <TimeRegistrationTable
                  selectedDate={new Date()} // Pass the selected date if needed
                  page="dashboard"
                  companyId={companyId}
                  selectedLocation={location.id}
                  // selectedEmployee={todayPlanningsByLocation[location.id]}
                  employeesData={todayPlanningsByLocation[location.id]}
                  refresh={(employeeId: number) => updateEmployeeStatus(location.id, employeeId)}
                />
              </DashboardAccordion>
            </div>
          ))}
        </div>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default LocationDashboard;
