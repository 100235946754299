import React from "react";
import Icon from "../../../Icon";
import { t } from "../../CentralDataMangement/translation/Translation";
import Link from "./Link";
import { useNavigate } from "react-router-dom";

interface Props {
  backLink?: string | (() => void); // Accept both string and function
}

const BackLink: React.FC<Props> = ({ backLink }) => {
  const handleClick = (event: React.MouseEvent) => {
    // if (typeof backLink === "function") {
    //   event.preventDefault(); // Prevent default link behavior
    //   backLink();
    // }
    navigate(-1);
  };

  const navigate = useNavigate();

  return (
    <div className="col-6 mb-2">
      {/* <Link
        className="pagebackIcon"
        href={typeof backLink === "string" ? backLink : "#"}
        onClick={handleClick}
      > */}
      <div className="pagebackIcon">
        <span title={t("Back")} className="cursor-pointer">
          <Icon isIcon={true} onClick={handleClick} width="1vw" height="1vw" name="longLeftArrow" />
        </span>          
      </div>
      {/* </Link> */}
    </div>
  );
};

export default BackLink;
