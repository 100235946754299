import React from "react";
import Icon from "../../../Icon";
import AutoCompleteDropdown from "../atoms/AutoCompleteDropdown";

interface AutoCompleteWithSearchProps {
	placeholder?: string;
	suggestions: string[];
	value: string;
	handleChange: (value: string) => void;
	handleOptionClick?: (value: string) => void;
}
const AutoCompleteWithSearch: React.FC<AutoCompleteWithSearchProps> = ({
	placeholder,
	suggestions,
	value,
	handleChange,
	handleOptionClick
}) => {
	return (
		<div className="d-flex justify-content-end align-items-center autoCompleteWithSearchWrapper">
			<AutoCompleteDropdown
				onChange={handleChange}
				handleOptionClick={handleOptionClick}
				suggestions={suggestions}
				value={value}
				placeholder={placeholder}
				className=""
			/>
			<Icon
				name="searchIcon"
				fillColor="#8571b9"
				width="1vw"
				height="1vw"
				className="ms-3"
				isIcon={true}
			/>
		</div>
	);
};

export default AutoCompleteWithSearch;
