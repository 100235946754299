import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import {
  ADMIN_DETAILS_BY_COMPANY,
  EMPLOYEE_REMOVE_ADMIN,
} from "../../routes/ApiEndpoints";
import OverFlowWrapper from "../common/OverFlowWrapper";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import Button from "../common/atoms/Button";
import Search from "../common/atoms/Search";
import SearchBtn from "../common/atoms/SearchBtn";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../common/atoms/CustomNotify";
import LabelField from "../common/atoms/LabelField";
import "./css/administrator.css";

const ManageAdministrator = () => {
  const navigate = useNavigate();
  const companyId = useSelector(
    (state: any) => state?.auth?.currentCompany?.id
  );
  const microService = "central-data-management";
  interface Company {
    id: number;
    name: string;
    official_name: string;
    profile_picture_url: string;
    locations: [];
    projects: [];
  }

  interface DataItem {
    id: number;
    locations: [];
    name: string;
    official_name: string;
    profile_picture_url: string | null;
    projects: [];
    email: string;
    companies: Company[];
  }

  const [state, setState] = useState({
    data: [] as DataItem[], // Store all administrators
    filteredData: [] as DataItem[], // Store filtered results
    searchTerm: "", // Store search input
  });

  // Fetch administrators from API
  const fetchInfo = async (searchTerm = "") => {
    let url = `${ADMIN_DETAILS_BY_COMPANY}?company_id=${companyId}&q=${searchTerm}`;
    try {
      const response = await ApiCall.getService(url, "GET", microService, true);
      if (response.status === 200) {
        setState({
          ...state,
          data: response.data,
          filteredData: response.data, // Initialize filtered data with full response
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Trigger search and filter results based on search term
  const handleSearch = () => {
    fetchInfo(state.searchTerm); // Fetch filtered employees from API based on search term
  };

  // Handle search input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      searchTerm: e.target.value, // Update search term
    });
  };

  useEffect(() => {
    fetchInfo(); // Fetch initial data without any filters
  }, [companyId]);

  const removeAdmin = async (employer_id: number) => {
    let url = `${EMPLOYEE_REMOVE_ADMIN}/${employer_id}`;
    try {
      const response = await ApiCall.getService(url, "GET", microService, true);
      if (response.status === 200) {
        fetchInfo();
        CustomNotify({
          message: t("Admin deleted successfully"),
          type: "success",
          autoClose: 3500,
        });
      }
    } catch (err) {
      CustomNotify({
        message: t(err, "Fail to delete the admin"),
        type: "error",
        autoClose: 3500,
      });
      console.error(err);
    }
  };
  return (
    <div>
      <Header
        headerName={t("Manage administrators")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink={"/manage-salary-benefits"} />
          <div className="col-6 align-self-center">
            <div className="col-12 text-end">
              <Button
                title={t("Add administrator")}
                className="searchBtn px-3"
                handleClick={() => navigate("/add-administrator")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="createpagesBg manageAdministratorPage">
        <LabelField title={t("Administrators:")} />
        {/* Search Input and Button */}
        <div className="row mb-4">
          <div className="col-6">
            <Search
              value={state.searchTerm}
              placeholder={t("Search by name")}
              handleChange={handleInputChange} // Update search term on change
            />
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center">
              <SearchBtn handleSearchClick={handleSearch} />
            </div>
          </div>
        </div>

        <OverFlowWrapper className="manageAdministratorWrapper pt-1">
          <div className="container-fluid ps-1 pe-3">
            <div className="row">
              {state.filteredData.length > 0 ? (
                state.filteredData.map((item) => (
                  <React.Fragment key={item?.id}>
                    <div className="col-3 mb-4">
                      <EmployeeDetailsCard
                        employee_data={item}
                        cardConfig={{
                          isRelatedCompany: true,
                          cardClassName:
                            "text-break text-start d-flex align-items-center justify-content-start",
                          employeeInformationClass: "planningEmployeeDetails",
                          cardMainClassName:
                            "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                          iconClassName:
                            "text-end cardAdministratorEditIcon cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                          isCardIcon: true,
                          iconName: "close",
                          favouriteIconName: "starIcon", // Star icon for favorite
                          noFavouriteIconName: "whiteStarIcon", // Empty star icon for not favorite
                          // isFavourite: item?.is_fav,
                        }}
                        Company={item.companies} // Send the companies array to the card
                        handleClick={() => removeAdmin(item?.id)}
                      />
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="col-12">{t("No administrator available")}</div>
              )}
            </div>
          </div>
        </OverFlowWrapper>
      </div>
    </div>
  );
};

export default ManageAdministrator;
