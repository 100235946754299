import React, { useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
import { useSelector } from "react-redux";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import Icon from "../../Icon";
import { EMPLOYER_TIME_REGISTRATION } from "../../routes/ApiEndpoints";
import CustomNotify from "../common/atoms/CustomNotify";

interface Shift {
  start_time: string;
  end_time: string;
}

interface EventData {
  user_id: number;
  profilePhoto: string | null;
  name: string;
  start_time: string;
  end_time: string;
  employee_type: string;
  employee_type_id: number;
  pc_name: string;
  salary: string;
  function_name: string;
  function_id: number;
  planning_id: number;
  location_id: string;
  company_id: string;
  display_timings: string;
  // timings: Shift[];
  shifts: {
    shift1: Shift;
    shift2?: Shift; // shift2 is optional
  };
  total_break: string;
  total_hrs: string;
  planned_date : string;
  status:string;
  latest_shift_status:string;
}

interface TimeRegistrationTableProps {
  selectedDate?: Date;
  page?: string;
  companyId: number;
  selectedLocation?: {
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  selectedEmployee?: EventData;
  employeesData: EventData[];
  // refresh : () => void;
  refresh: (empId?: number) => void;
}

const IconProps = {
  isIcon: true,
  width: "1vw",
  height: "1vw",
};

const TimeRegistrationTable: React.FC<TimeRegistrationTableProps> = ({
  selectedDate,
  page,
  companyId,
  selectedLocation,
  selectedEmployee,
  employeesData = [],
  refresh
}) => {

  // const dats = data ? [data] : [];
  

  const saveTimeRegistration = async (type: string, emp: EventData) => {
    const payload = {
      employee_id: emp.user_id,
      location_id: emp.location_id,
      company_id: emp.company_id,
      planning_id: emp.planning_id,
      planned_date:emp.planned_date,
      user_id: 123, // replace with actual user_id
      type, // "START", "STOP", "BREAKIN", "BREAKOUT"
      start_date: new Date().toISOString().split('T')[0], // format: YYYY-MM-DD
      end_date: "2024-10-13",
      shifts: [
        {
          start_time: emp.shifts.shift1.start_time,
          end_time: emp.shifts.shift1.end_time,
        },
        emp.shifts.shift2 && {
          start_time: emp.shifts.shift2.start_time,
          end_time: emp.shifts.shift2.end_time,
        },
      ].filter(Boolean), // filter out undefined values (for shift2 if not present)
    };
    console.log(payload , "payload");

  
      try {
        const response = await ApiCall.service(
          EMPLOYER_TIME_REGISTRATION,
          "POST",
          payload,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if(response.statusCode == 201){
          refresh(emp.user_id); 
          CustomNotify({
            type: "success",
            message: t(response.message) || t(response.msg),
          });
        }
      console.log(response);
      } catch (error) {
        console.error('Error during time registration:', error);
        // Handle error, e.g., show an error notification
      }
  };

  return (
    <div className="time-registration-table">
      {/* <h2>{t("Time Registration Details")}</h2> */}
      <table className="table planningTableWrapper table-borderless">
        <thead className="positionSticky">
          <tr>
            <th></th>
            <th>{t("Employee")}</th>
            <th>{t("Status")}</th>
            <th>{t("Function")}</th>
            <th>{t("Paritair committee")}</th>
            <th>{t("Hourly wage")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Break Total")}</th>
            <th>{t("Total work done")}</th>
            <th>{t("Action")}</th>
          </tr>
          <tr className="spacing-row bg-transparent">
            <td colSpan={20} className="bg-transparent rounded-0 p-0" style={{ height: "1vw" }}></td>
          </tr>
        </thead>
        <tbody>
        {employeesData.map((emp: EventData) => (
            <tr key={emp.user_id}>
              <td>
                {/* Assuming LoadImage is defined */}
                <Image
                  src={LoadImage}
                  imageWidth="3vw"
                  imageHeight="3vw"
                  imageBorderRadius="50%"
                />
              </td>
              <td>{emp.name}</td>
              <td>{emp.employee_type}</td>
              <td>{emp.function_name}</td>
              <td>{emp.pc_name}</td>
              <td>€{emp.salary}</td>
              <td>{emp.shifts.shift1.start_time}</td>
              <td>{emp.shifts.shift1.end_time}</td>
              <td>{emp.shifts.shift2?.start_time || '-'}</td>
              <td>{emp.shifts.shift2?.end_time || '-'}</td>
              <td>
                <div className="totalHours text-white poppins-medium">
                  {emp.total_break ?? "00:00"}
                </div>
              </td>
              <td>
                <div className="totalHours text-white poppins-medium">
                  {emp.total_hrs ?? "00:00"}
                </div>
              </td>
              <td className="actionWrapper">
                <AccessControl
                  renderNoAccess={false}
                  requiredPermissions={[
                    {
                      permission: "Planning time registration",
                      read: true,
                      create: true,
                      update: true,
                      delete: true,
                    },
                  ]}
                >
                  <>
                    <AccessControl
                      renderNoAccess={false}
                      requiredPermissions={[
                        { permission: "Check in", read: true },
                      ]}
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title={t("Start")}
                        onClick={() => emp.latest_shift_status === "breakin" ? saveTimeRegistration("breakout", emp) : saveTimeRegistration("start", emp) }
                        className="me-2 btn-success"
                        style={{
                          pointerEvents: emp.latest_shift_status === "yetToStart" || emp.latest_shift_status === "breakin"  ? "auto" : "none",
                          opacity: emp.latest_shift_status === "yetToStart" || emp.latest_shift_status === "breakin"  ? 1 : 0.5,
                        }} 
                      >
                        <Icon name="startIcon" {...IconProps} />
                      </span>
                    </AccessControl>
                    <AccessControl
                      renderNoAccess={false}
                      requiredPermissions={[
                        { permission: "Break in", read: true },
                      ]}
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title={t("Pause")}
                        onClick={() => saveTimeRegistration("breakin", emp)}
                        className="me-2"
                        style={{
                          pointerEvents: emp.latest_shift_status === "start" || emp.latest_shift_status === "breakout"  ? "auto" : "none",
                          opacity: emp.latest_shift_status === "start" || emp.latest_shift_status === "breakout"  ? 1 : 0.5,
                        }} 
                      >
                        <Icon name="pauseIcon" {...IconProps} />
                      </span>
                    </AccessControl>
                    <AccessControl
                      renderNoAccess={false}
                      requiredPermissions={[
                        { permission: "Check out", read: true },
                      ]}
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title={t("Stop")}
                        onClick={() => saveTimeRegistration("stop", emp)}
                        className="me-2"
                        style={{
                          pointerEvents: emp.latest_shift_status === "start" || emp.latest_shift_status === "breakout"  ? "auto" : "none",
                          opacity: emp.latest_shift_status === "start" || emp.latest_shift_status === "breakout"  ? 1 : 0.5,
                        }}
                      >
                        <Icon name="stopIcon" {...IconProps} />
                      </span>
                    </AccessControl>
                  </>
                </AccessControl>

              </td>
            </tr>
          ))}
          {employeesData.length === 0 && (
            <tr>
              <td colSpan={12}>
                <div>
                  <p>{t("There are no schedules for this day")}</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default translate(TimeRegistrationTable);
