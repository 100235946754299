import React, { useState, useContext, useEffect } from "react";
import { PcContext } from "./PcContext";
import { ApiCall } from "../../../services/ApiServices";

import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import {
  FETCH_EMPLOYEE_TYPES_LIST,
  FETCH_PC_EMPLOYEES,
  STORE_PC_EMPLOYEES,
} from "../../../routes/ApiEndpoints";
import Title from "../../common/atoms/Title";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import Button from "../../common/atoms/Button";
import OverFlowWrapper from "../../common/OverFlowWrapper";

const EmployeeType = () => {
  const [disableForm, setDisableForm] = useState(false);
  const [sec_width, setSec_width] = useState("col-md-6");

  const {
    pc_unique_key,
    setPc_unique_key,
    current_sec,
    cat_rightsec,
    setCat_rightsec,
    cat_leftsec,
    setCat_leftsec,
    cat_subsec_type,
    setCat_subsec_type,
    cat_fun_updated,
    setCat_fun_updated,
    sec_completed,
    setSec_completed,
    cat_subsec_id,
    setCat_subsec_id,
    setCurrent_sec,
    pc_view_type,
  } = useContext(PcContext);
  const [id, setId] = useState("");

  const [data, setData] = useState([]);
  const [temp, setTemp] = useState([]);
  const [temp2, setTemp2] = useState([]);

  const [res, setRes] = useState([]);
  const [error_emp_type, setError_emp_type] = useState("");

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await ApiCall.getService(
          FETCH_EMPLOYEE_TYPES_LIST + "/" + pc_unique_key,
          "GET",
          "central-data-management"
        );
        if (response.success) {
          if (response.data) {
            setData(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (setPc_unique_key != "") {
      fetchData1();
    }
  }, [pc_unique_key]);

  useEffect(() => {
    if (pc_view_type == "viewpc") {
      setDisableForm(true);
      setSec_width("col-md-12");
    }
    if (pc_view_type == "editpc") {
      setSec_width("col-md-12");
    }
  }, [pc_view_type]);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await ApiCall.getService(
          FETCH_PC_EMPLOYEES + "/" + pc_unique_key,
          "GET",
          "central-data-management"
        );
        if (response.success) {
          if (response.data) {
            console.log(response.data, "required");

            setRes(response.data);
            setTemp(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData2();
  }, [pc_unique_key]);

  let updateRes = (event) => {
    var res1 = [...res];
    var temp1 = [...temp2];
    var index2 = temp.indexOf(parseInt(event.target.value));

    if (event.target.checked) {
      if (!res1.includes(parseInt(event.target.value))) {
        res1.push(parseInt(event.target.value));
        var index1 = temp2.indexOf(parseInt(event.target.value));

        if (index2 > -1) {
          if (index1 > -1) {
            temp1.splice(index1, 1);
            setTemp2(temp1);
          }
        }
      }

      console.log("✅ Checkbox is checked");
    } else {
      var index = res1.indexOf(parseInt(event.target.value));
      if (index > -1) {
        res1.splice(index, 1);
      }

      if (index2 > -1) {
        if (!temp1.indexOf(parseInt(event.target.value)) > -1) {
          temp1.push(parseInt(event.target.value));
          setTemp2(temp1);
        }
      }

      console.log("⛔️ Checkbox is NOT checked");
    }

    setRes(res1);
  };

  let postdata = (data1) => {
    if (id == "") {
      ApiCall.service(
        STORE_PC_EMPLOYEES,
        "POST",
        data1,
        false,
        "central-data-management"
      )
        .then((result) => {
          if (result.success) {
            if (cat_subsec_type == 5) {
              setCat_fun_updated("employeetype" + result.pcid);
              setCat_rightsec("d-none");
              setCat_leftsec("col-md-12");
              setCat_subsec_type(0);
              setCat_subsec_id("");
            } else {
              setCurrent_sec(5);
              var res1 = sec_completed;
              res1["emp_type"] = true;
              setSec_completed(res1);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  let submit = (event) => {
    event.preventDefault();
    var data1 = {};

    data1["pc_unique_key"] = pc_unique_key;
    data1["saved"] = res;
    data1["deleted"] = temp2;
    if (res.length != 0) {
      postdata(data1);
    } else {
      setError_emp_type("Select atleast one employee type.");
    }
  };

  return (
    <div>
      <form onSubmit={submit} className="border p-3 rounded-3 mt-1">
        <Title
          title={
            pc_view_type == "editpc"
              ? t("Edit employee type")
              : pc_view_type == "viewpc"
              ? t("Employee type")
              : ""
          }
          className="colorPrimary poppins-medium pcTitle"
        />
        <OverFlowWrapper className="ageWrapper">
          <div className="container-fluid">
          <div>
            <p className="text-danger">{t(error_emp_type)}</p>
            <div className="row">
              <div className="col-12 px-0">
                {data.map((val) => (
                  <div
                    className={"form-check mb-3 word-break " + sec_width}
                    key={val.id}
                  >
                    <CheckBoxField
                      name={val.name}
                      value={val.id}
                      isChecked={res && res.includes(val.id)}
                      onChangeHandler={(e) => {
                        updateRes(e);
                      }}
                      disable={disableForm}
                      label={val.name}
                      checkBoxWrapper="ps-0"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {pc_view_type == "editpc" ? (
            <div className="row ">
              <div className="col-6 ms-auto text-end pe-0">
                <Button
                  type="submit"
                  title={t("Save")}
                  className="searchBtn px-3"
                />
              </div>
            </div>
          ) : pc_view_type == "addpc" ? (
            <div className="row mt-4 mb-2">
              <div className="text-start col-md-6 align-self-center pe-0">
                <button
                  type="button"
                  className={
                    pc_view_type == "addpc"
                      ? "bg-white border-0 poppins-regular-18px px-0 shadow-none text-decoration-underline"
                      : "bg-white border-0 poppins-regular-18px px-0 shadow-none text-decoration-underline"
                  }
                  onClick={() => {
                    setCurrent_sec(3);
                  }}
                >
                  {t("BACK")}
                </button>
              </div>
              <div className="text-end col-md-6 back-btn-mobile pe-0">
                <button
                  type="sumit"
                  className={
                    pc_view_type == "addpc"
                      ? "btn rounded-0  custom-btn px-3  btn-block float-end px-0 poppins-medium-18px-next-button  shadow-none"
                      : "btn rounded-0  custom-btn px-3  btn-block float-end px-0 poppins-medium-18px-next-button  shadow-none"
                  }
                >
                  {t("NEXT")}
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          </div>
        </OverFlowWrapper>
      </form>
    </div>
  );
};
export default translate(EmployeeType);
