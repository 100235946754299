import React, { useState, useEffect } from "react";
import { ApiCall } from "../../../services/ApiServices";
import { MANAGE_SALARY_BENIFITS } from "../../../routes/ApiEndpoints";
import Header from "../../common/layout/Header";
import Pagination from "../../common/atoms/Pagination";
// import styles from "./SalaryBenefits.module.css";
import { useNavigate } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import { t } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Table from "../../common/atoms/Table";

interface SalaryBenefit {
  paritaircommittee_id: string;
  bbright_id: number;
  name: string;
  code: string;
  sb_main_id: number;
  variable_type: number;
  salary_benifit_value: string;
  start_date: string;
  end_date: string;
  is_mandatory: boolean;
  is_sales_can_change: boolean;
  status: boolean;
  pc_salary_benifit_id: string;
  auto_code_type: string;
  is_skip_absence: boolean;
  amount: number;
  occurence: number;
}

const ManageSalaryBenefits: React.FC = () => {
  const [data, setData] = useState<SalaryBenefit[]>([]);
  const [filteredData, setFilteredData] = useState<SalaryBenefit[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const PER_PAGE = 5;
  const navigate = useNavigate();

  // Fetch Salary Benefits data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          MANAGE_SALARY_BENIFITS,
          "GET",
          "central-data-management",
          true
        );
        if (response.success) {
          setData(response.data);
          setFilteredData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Search functionality
  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      const filtered = data.filter((benefit) =>
        benefit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        benefit.code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset pagination
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredData(data);
    setCurrentPage(0);
  };

  // Pagination logic
  const pageCount = Math.ceil(filteredData.length / PER_PAGE);
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
  };

  const paginatedData = filteredData.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE);
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  const TableHeaderProps = [
    { label: 'S.no', width: 10 },
    { label: 'Name', width: 25 },
    { label: 'Code', width: 15 },
    { label: 'Benefit Value', width: 15 },
    { label: 'Start Date', width: 15 },
    { label: 'Status', width: 12 },
  ];

  const TableActionProps = [
    {
      label: t("Edit"),
      icon: <Icon name="EditIcon" {...IconProps} />,
      handleClick: (value: any) => navigate(`/manage-salary-benefits/edit/${value.bbright_id}`),
      iconClassName: 'secondaryColorHoverEffect me-2',
    }
  ];

  return (
    <div className="container-fluid p-0">
        <>
          <Header headerName="Manage Salary Benefits" headerClassName="myAccountCardTitle" />
          {/* Search bar */}
          <div className="search-bar pb-4">
            <div className="row mb-2">
              <BackLink backLink="/config/settings" />
            </div>
            <div className="row">
              <div className="col-8">
                <Search
                  value={searchTerm}
                  placeholder={t("Name/Code")}
                  handleChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>            
              <div className="col-4">
                <div className="d-flex align-items-center">
                  <ResetBtn handleResetClick={() => handleReset()} />
                  <SearchBtn handleSearchClick={() => handleSearch()} />
                </div>
              </div>
            </div>
          </div>

          {/* Salary Benefits Table */}
          <div className="manageSalaryBenefits">
              <OverFlowWrapper className="table-responsive manageSBTable">
                <Table
                  headers={TableHeaderProps}
                  content={paginatedData} // Updated to use paginated data
                  actionlabel={t('Actions')}
                  isSNo={true}
                  variables={['name', 'code', 'salary_benifit_value', 'start_date', 'status']}
                  action = {TableActionProps}
                />
              </OverFlowWrapper>

              {/* Pagination */}
              {pageCount > 1 && (
                <div className="pagination justify-content-center mt-4 px-4">
                  <Pagination
                    currentPage={currentPage + 1}
                    totalPages={pageCount}
                    onPageChange={handlePageClick}
                  />
                </div>
              )}
          </div>
        </>
    </div>
  );
};

export default ManageSalaryBenefits;
