import React from 'react'

interface props {
  headerLength?: any
}
const TableHeaderSpace: React.FC<props> = ({ headerLength }) => {
  return (
    <tr>
      <th
        className="emptyRow p-0"
        style={{ height: '0.7vw' }}
        colSpan={headerLength}
      ></th>
    </tr>
  )
}

export default TableHeaderSpace
