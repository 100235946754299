// src/components/QRCodeDisplay.tsx

import React, { useEffect, useState } from 'react';
import { CREATE_QR_CODE, QR_CODE, QR_CODE_REFRESH } from '../../routes/ApiEndpoints';
import { ApiCall } from '../../services/ApiServices';
import LabelField from '../common/atoms/LabelField';
import { t } from '../CentralDataMangement/translation/Translation';
import Header from '../common/layout/Header';
import Button from "../common/atoms/Button";
import Icon from '../../Icon';
import { useSelector } from 'react-redux';
import BackLink from '../common/atoms/BackLink';
import { useParams } from 'react-router-dom';
import PrintFile from '../common/molecules/PrintFile';
// import "./css/qrCode.css";
interface QRCodeResponse {
  statusCode: number;
  message: string;
  data: string;

}
interface Companies {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string | null;
  locations: Array<any>; // Adjust the type based on your location data structure
}

interface QRCodeDisplayProps {
  // companyId: string;
  // item: any;
}
const QRCodeDisplay: React.FC<QRCodeDisplayProps> = () => {

  const { companyId } = useParams();
  const authData = useSelector((state: any) => state?.auth);
  const userId = authData?.userId;
  const companies: Companies[] = authData?.companies;
  const company = companies.find(item => item.id === Number(companyId));

  const companyName = company ? company.name : 'Company not found';

  const [data, setData] = useState({
    qrCodeUrl: "",
    createdOn: "",
  });
  const fetchData = async () => {
    let url = `${QR_CODE}/${companyId}`
    try {
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        true
      );
      console.log(response);
      if (response.message == "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: (response?.data?.qr_code_path) ? (response?.data?.qr_code_path) : createQrCode(),
          createdOn: formattedDate(response?.data?.created_at)
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  const createQrCode = async () => {
    let url = `${CREATE_QR_CODE}`;
    let dataObj = {
      "company_id": companyId,
      "user_id": userId
    };
    try {
      const response = await ApiCall.service(url, "POST", dataObj, true, "central-data-management");
      if (response.message == "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.link,
          createdOn: formattedDate(response?.data?.qr_code?.created_at)
        }));
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const formattedDate = (date: string | number | Date | undefined): string => {
    if (!date) return '';  // Handle undefined or invalid input
    const created_date = new Date(date);
    return `${created_date?.getFullYear()}-${String(created_date?.getMonth() + 1).padStart(2, '0')}-${String(created_date?.getDate()).padStart(2, '0')} ${String(created_date?.getHours()).padStart(2, '0')}:${String(created_date?.getMinutes()).padStart(2, '0')}:${String(created_date?.getSeconds()).padStart(2, '0')}`;
  };

  const handleRefresh = async () => {

    const dataObj = {
      "company_id": companyId,
      "user_id": userId
    };
    let url = `${QR_CODE_REFRESH}`
    try {
      const response = await ApiCall.service(
        url,
        "POST",
        dataObj,
        true
      );
      if (response.message == "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.link,
          createdOn: formattedDate(response?.data?.qr_code?.created_at)
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <Header
        headerName={t("QR code")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <BackLink backLink={"/manage-salary-benefits"} />
      </div>
      <div className="createpagesBg py-5 ps-5">
        <div className='row justify-content-center'>
          <div className='col-lg-5'>
            <LabelField title={t("QR code") + ": " + t("(" + companyName + ")")} className="mb-2" /><br />
            <LabelField title={"1. " + t("Scan the QR code")} className="mb-2" /><br />
            <LabelField title={"2. " + t("Choose an action in the app that you want to perform")} className="mb-2" /><br />
            <LabelField title={"3. " + t("Confirm your choice")} className="mb-2" /><br />
            <div className='qr-code-img-wrapper'>
              {data.qrCodeUrl &&
                <img className='w-50 m-auto h-100' src={data.qrCodeUrl} alt="QR Code" title='QR code' />
              }
              <div className='d-flex align-items-center'>
                <LabelField title={t("QR code created on") + ' : ' + data.createdOn} className="mb-2" />
                <div
                  className="d-inline-flex align-items-center cursor-pointer secondaryColorHoverEffect sendText"
                  onClick={handleRefresh} // Call handleRefresh when clicked
                >
                  <span className="ms-1">
                    <Icon
                      name="refreshIcon"
                      isIcon={true}
                      width="0.8vw"
                      height="0.8vw"
                    />
                  </span>
                  {data.qrCodeUrl && (
                    <div className="print-container">
                      <PrintFile
                        fileUrl={data.qrCodeUrl.startsWith('http://')
                          ? data.qrCodeUrl.replace('http://', 'https://')
                          : data.qrCodeUrl}
                      />
                    </div>
                  )}
                </div>
              </div>
              <br />
            </div>
            <Button title={t("View history")} className="searchBtn float-start mt-3" />

          </div>
        </div>
      </div>

    </div>
  );
};

export default QRCodeDisplay;

