import { t } from '../components/CentralDataMangement/translation/Translation';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TimeStates } from '../components/common/utlis/PlanningUtility';
import { ADDRESS_HOUSE_NUMBER_FIELDS, ADDRESS_NUMBER_FIELDS, ADDRESS_STRING_FIELDS, NAME_FIELDS, NUMBER_FIELDS, STRING_FIELDS } from '../components/EmployeeRegistration/services/State';

interface ValidationErrors {
  [key: string]: string;
}

export interface Option {
  value: number
  label: string
}

interface pcProps {
  PC: Option | null,
  employeeType: any[],
}

export function validateRequired(value: string): string | null | undefined {
  if (!value || value.trim() === "") {
    return t("This field is required");
  }
  return null;
}

export function validateNumberField(value: number): number | null | undefined {
  if (!value) {
    return t("This field is required");
  }
  return null;
}

export function validateDate(value: string): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validateAlphaNumeric(value: string): string | null {
  // Regular expression to check for alphanumeric characters (letters and digits)
  const alphanumericPattern = /^[a-zA-Z0-9]+$/;
  if (!alphanumericPattern.test(value)) {
    return t("Only alphanumeric characters are allowed");
  }
  return null; // Return null if the value is valid
}

export function validateFileId(value: number): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validatePhoneNumber(value: string): string | null {
  if (value === "" || typeof value !== "string") {
    return t("This field is required");
  }

  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }

  return null;
}

export const validateSelectField = (selectedObject: { value: any }) => {
  if (!selectedObject || selectedObject.value === null || selectedObject.value === "") {
    return t("This field is required");
  }
  return null;
};

export const validateMultiSelectField = (value: object[]) => {
  if (!Array.isArray(value) || value.every(item => item === undefined)) {
    return t("This field is required");
  }
  return null;
};

export const validatePCEmployeeType = (value: pcProps[]) => {
  if (value.length > 0 && value.some((item) => item.employeeType.length === 0)) {
    return t("Please select the employee types for PC");
  }
  return null;
};



export const validateFunctionField = (value: object[]) => {
  if (!Array.isArray(value) || value.every(item => item === undefined)) {
    return t("Please select atleast one function");
  }
  return null;
};

export const scrollToTop = (top = 0) => {
  return window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
}

export const scrollToBottom = () => {
  return window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
}

export function validateTextFiled(value: string): string | null {
  if (!/^[A-Za-z]+$/.test(value)) {
    return t("Only alphabets are allowed");
  }

  return null;
}

export function validateTextFiledSpace(value: string): string | null {
  if (!/^[A-Za-z\s]+$/.test(value)) {
    return t("Only alphabets and spaces are allowed");
  }

  return null;
}

export function validateTextFieldAlpha(value: string): string | null {
  // Regular expression to check for alphanumeric characters, accented characters, spaces, apostrophes, and hyphens
  if (!/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF\s'-]+$/.test(value)) {
    return t("Only alphanumeric characters, accented characters, spaces, apostrophes, and hyphens are allowed");
  }

  return null;
}


export function validateUniqueKey(value: string): string | null {
  if (!/^[a-z_]+$/.test(value)) {
    return t("Unique Key can only contain lowercase letters and underscores");
  }

  return null;
}

export function validateCheckbox(value: boolean): string | null {
  if (value !== true) {
    return t("This field is required");
  }

  return null;
}

export const validateFileRequired = (file: File | null): string | null => {
  return !file ? t("This field is required") : null;
};

export function validateMinLength(
  value: string,
  minLength: number
): string | null {
  if (value.length < minLength) {
    return `${t("Value must be at least")} ${minLength} ${t("characters long")}`;
  }

  return null;
}

export function validateMaxLength(
  value: string,
  maxLength: number
): string | null {
  if (value.length > maxLength) {
    return `${t("Value must be at most")} ${maxLength} ${t("characters long")}`;
  }

  return null;
}

export function validateCheckboxes(
  internal: boolean,
  external: boolean
): string | null {
  if (!internal && !external) {
    return t("This field is required");
  }

  return null;
}

export function validateWage(
  starterWage: string | undefined,
  experiencedWage: string | undefined,
  name: string
): string | null {

  if ((starterWage === undefined || starterWage === '') ||
    (experiencedWage === undefined || experiencedWage === '')) {
    return null;
  }

  // replace commas with dots
  let newStarterWage = parseFloat((starterWage || '').replace(/,/g, "."));
  let newExperiencedWage = parseFloat((experiencedWage || '').replace(/,/g, "."));

  if (newStarterWage > newExperiencedWage) {
    if (name === 'starterWage') {
      return t("Starter wage cannot be greater than experienced wage.");
    } else if (name === "experiencedWage") {
      return t("Experienced wage should be greater than starter wage.");
    }
  }

  return null;
}

export function validatePay(
  payFrom: string | undefined,
  payTo: string | undefined,
  name: string
): string | null {
  if ((payFrom === undefined || payFrom === '') ||
    (payTo === undefined || payTo === '')) {
    return null;
  }

  // replace commas with dots
  let newpayFrom = parseFloat((payFrom || '').replace(/,/g, "."));
  let newpayTo = parseFloat((payTo || '').replace(/,/g, "."));

  if (newpayFrom > newpayTo) {
    if (name === 'payFrom') {
      return t("Pay from cannot be greater than pay to.");
    } else if (name === "payTo") {
      return t("Pay to should be greater than pay from.");
    }
  }

  return null;
}

export function validatePassword(value: string): string | null {
  if (value?.trim() === "") {
    return t("This field is required");
  }
  return validatePasswordComplexity(value);
}

export function validatePasswordComplexity(value: string): string | null {
  // Validate password length
  if (value.length < 8) {
    return t("Password must be at least 8 characters long");
  }

  if (value.length > 50) {
    return t("Password cannot exceed 50 characters");
  }

  // Validate password complexity requirements
  if (!/[A-Z]/.test(value)) {
    return t("Password must contain at least one uppercase letter");
  }

  if (!/[a-z]/.test(value)) {
    return t("Password must contain at least one lowercase letter");
  }

  if (!/\d/.test(value)) {
    return t("Password must contain at least one number");
  }

  if (!/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>?]/.test(value)) {
    return t("Password must contain at least one special character");
  }

  return null;
}

export function validatePasswordComplexityOneMessage(value: string): string | null {
  const errors = [];

  if (value.length < 8) {
    errors.push("at least 8 characters long");
  }

  if (value.length > 50) {
    errors.push("not exceed 50 characters");
  }

  if (!/[A-Z]/.test(value)) {
    errors.push("at least one uppercase letter");
  }

  if (!/[a-z]/.test(value)) {
    errors.push("at least one lowercase letter");
  }

  if (!/\d/.test(value)) {
    errors.push("at least one number");
  }

  if (!/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>?]/.test(value)) {
    errors.push("at least one special character");
  }

  return t(errors.length > 0 ? "Password must be " + errors.join(", ") : null); // Prepend common phrase
}


export const validateMatchPassword = (
  password: string,
  confirmPassword: string
): string | null => {
  if (password !== confirmPassword) {
    return t("Passwords do not match");
  }
  return null;
};

export const checkFieldMatch = (
  value1: string,       // First value (e.g., email)
  value2: string,       // Second value (e.g., confirm_email)
  fieldName: string     // The name of the field for the error message (e.g., "Emails")
): string | null => {
  if (value1 !== value2) {
    return t(`${fieldName} do not match`);  // Return error message if they don't match
  }
  return null;  // If they match, return null (no error)
};


export const validateTokens = (expectedTokens: string[]) => {
  return (value: string) => {
    const tokens = value.match(/\[[^\]]+\]/g) ?? [];
    const invalidTokens = tokens.filter(token => !expectedTokens.includes(token));
    if (invalidTokens.length > 0) {
      return t(`Tokens must match the list of expected tokens`);
    }
    return undefined;
  };
};

export function validateEmail(value: string): string | null {

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!value || value?.trim() === "") {
    return t("This field is required");
  }
  if (!emailRegex.test(value)) {
    return t("Invalid email address");
  }

  return null;
}

export function validateIBAN(value: string): string | null {
  const belgiumIBANRegex = /^BE\d{14}$/;
  if (value === null || value === "") {
    return null;
  }
  if (!belgiumIBANRegex.test(value)) {
    return t("IBAN is not valid");
  }
  return null;
}

// Fetch BIC for IBAN
export const fetchBicForIban = async (iban: string) => {
  const url = `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    throw new Error("Error fetching BIC data");
  }
};

export function validatePhoneNumberCanBeNull(value: string): string | null {

  if (value === "" || typeof value !== "string" || value === '+null' || value === '+') {
    return null;
  }

  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }

  return null;
}

export function validateTextFieldAlphaCanBeNull(value: string): string | null {
  // Regular expression to check for alphanumeric characters, accented characters, and spaces
  if (value === null || value === "") {
    return null;
  } else if (!/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF\s']+$/.test(value)) {
    return t("Only alphanumeric characters, accented characters, and spaces are allowed");
  }

  return null;
}

export function validateEmailCanBeNull(value: string): string | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (value === null || value === "") {
    return null;
  }

  if (!emailRegex.test(value)) {
    return t("Invalid email address");
  }

  if (/[A-Z]/.test(value)) {
    return t("Email adress should not contain uppercase letters");
  }

  return null;
}

export function validateSSN(ssn: string): string | null {
  const ssnNumber = ssn.replace(/[-. ]/g, ""); // remove -, ., and space from ssn
  if (ssn === null || ssn === "") {
    return null;
  }
  if (ssnNumber.length !== 11) {
    return t("This field is invalid");
  }
  const ssnLastTwoDigits = parseInt(ssnNumber.substring(9, 11)); //get the last two digits
  const yyMMdd = ssnNumber.substring(0, 6); // get first 6 digits
  const XXX = ssnNumber.substring(6, 9); // get 3 digits after 6th index

  // Combine the portions into a single numeric value
  const combinedValue = parseInt(yyMMdd + XXX);
  const secondNo = 2000000000 + combinedValue;

  if (checkSum(combinedValue) === ssnLastTwoDigits) {
    return null;
  }
  if (checkSum(secondNo) === ssnLastTwoDigits) {
    return null;
  } else {
    return t(`Invalid SSN. Please enter valid SSN`);
  }
}

function checkSum(number: any, modulusValue = 97) {
  const remainder = number % modulusValue;
  return modulusValue - remainder;
}

// SSN number validation
export const validateSSNNumber = (ssnNumber: string): string | null => {
  // SSN pattern including dots and hyphens
  const ssnPattern = /^[0-9]{2}\.?[0-9]{2}\.?[0-9]{2}-[0-9]{3}\.?[0-9]{2}$/;

  if (!ssnNumber) {
    return "This field is required";
  }

  if (!ssnPattern.test(ssnNumber)) {
    return "This field is invalid";
  }

  return null;
};

export const validateStringFields = (state: any, errors: any) => {
  STRING_FIELDS.forEach((field) => {
    const value = state[field];
    if (!value) {
      errors[field] = t(`This field is required`);
    } else if (field === "phone_number") {
      const trimmedValue = value?.trim();
      if (!/^[\+0-9\s\/\-\(\)]{6,50}$/.test(trimmedValue)) {
        errors[field] = t(`Please enter a valid input`);
      }
    }
  });
};

export const validateNumberFields = (state: any, errors: any) => {
  NUMBER_FIELDS.forEach((field) => {
    const value = state[field];
    if (!value) {
      errors[field] = t(`This field is required`);
    } else if (isNaN(Number(value))) {
      errors[field] = t(`Enter a valid number`);
    }
  });
};

export const validateAddress = ( errors: any,
  address: Record<string, any>,
  prefix: string,
  requiredFields: string[]
) => {
  requiredFields.forEach((field) => {
    if (!address[field]) {
      errors[`${prefix}.${field}`] = t(`This field is required`);
    }
  });
};

export function validateRadioField(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return t("This field is required")
  }
  return null;
}

export function validateMultiChoiceAnswer(value: number[]) {
  if (value.length === 0) {
    return t("This field is required");
  }
  return undefined;
}

export function validateMultiChoice(value: string[]) {
  if (value.length < 4) {
    return t('Add 4 options for multiple choice question');
  }
  return undefined;
}

export function validateDichtmsOptionsAnswer(value: number[]) {
  if (value.length === 0) {
    return t("Check any one option as answer");
  }
  return undefined;
}

export function validateDichtmsOptions(value: string[]) {
  if (value.length < 2) {
    return t('Add 2 options for dichotomous question');
  }
  return undefined;
}

const getValidationRules = (
  validationRules: { [key: string]: Function[] },
  fieldName?: string
) => {
  if (fieldName && validationRules.hasOwnProperty(fieldName)) {
    return {
      [fieldName]: validationRules[fieldName],
    };
  }

  return validationRules;
};

export function validateForm(
  formData: { [key: string]: string | boolean | number | Date | File | null | object[] | Option | undefined | number[] | string[] | TimeStates },
  validationRules: { [key: string]: Function[] },
  fieldName?: string
): ValidationErrors {
  const errors: ValidationErrors = {};
  const vRules: any = getValidationRules(validationRules, fieldName);
  if (fieldName && !vRules[fieldName]) {
    return errors;
  }
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const value = formData[key];
      const rules = vRules[key];

      if (rules) {
        for (const rule of rules) {
          const error = rule(value);

          if (error) {
            errors[key] = error;
            break;
          }
        }
      }
    }
  }

  return errors;
}

export function validateTimeString(value: string) {
  if (!value || value === "00:00") {
    return "This field is required";
  }
}

export function validateTimeStringPattern(value: string) {
  const timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

  if (value === null || !value) {
    return;
  }

  if (!timeRegex.test(value) || value === ":") {
    return t("Please select both hour and minutes");
  }
}

export function validateLinkedinUrl(value: string | null) {
  let linkedinRegex = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  if (value) {
    return !linkedinRegex.test(value) ? t("Incorrect format") : null;
  }
  return null;
}

export function validateTimeRange(from: string, to: string, name: string) {
  const [fromHours, fromMinutes] = from.split(":");
  const [toHours, toMinutes] = to.split(":");

  const fromTotalMinutes = parseInt(fromHours) * 60 + parseInt(fromMinutes);
  const toTotalMinutes = parseInt(toHours) * 60 + parseInt(toMinutes);

  if (name === "to") {
    if (fromTotalMinutes >= toTotalMinutes) {
      return t('"To" time must be greater than "From" time');
    }
  } else if (name === "from") {
    if (fromTotalMinutes >= toTotalMinutes) {
      return t('"From" time must be lesser than "To" time');
    }
  }
};

export function validateVatNumber(value: string): string | null {
  if (!value || value.trim() === "") {
    return t("VAT number is required");
  }

  if (!/^\d+$/.test(value)) {
    return t("VAT number must contain only numbers");
  }

  if (value.length < 8) {
    return `${t("VAT number must be at least")} 8 ${t("characters long")}`;
  }

  if (value.length > 15) {
    return `${t("VAT number must be at most")} 15 ${t("characters long")}`;
  }

  return null;
}

// Validation function to allow only alphabets, spaces, and dots
export function validateNames(value: string): string | null {
  const namePattern = /^[A-Za-z\s.]+$/;
  if (!namePattern.test(value)) {
    return t("Only alphabets, spaces, and dots are allowed");
  }
  return null;
}

// Validation function to allow alphanumeric characters, spaces, and the characters &()./- 
export function validateStreet(value: string): string | null {
  const streetPattern = /^[A-Za-z0-9\s&().\/-]+$/;
  if (!streetPattern.test(value)) {
    return t("Only alphanumeric characters, spaces and special characters &()./- are allowed");
  }
  return null;
}

// Validation function to allow alphanumeric characters, dots, slashes, and hyphens
export function validateHouseNumber(value: string): string | null {
  const houseNumberPattern = /^[A-Za-z0-9./-]+$/;
  if (!houseNumberPattern.test(value)) {
    return t("Only alphanumeric characters, dots, slashes, and hyphens are allowed");
  }
  return null;
}

export function getNestedValues(obj: any, path: string): any {
  return path.split('.').reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : null;
  }, obj);
}


export const validateAddressStringFields = (state: any, errors: any) => {
  ADDRESS_STRING_FIELDS.forEach((field) => {
    const value = getNestedValues(state, field);
    if(value)
      errors[field] = validateStreet(value);
  });
};

export const validateAddressNumberFields = (state: any, errors: any) => {
  ADDRESS_NUMBER_FIELDS.forEach((field) => {
    const value = getNestedValues(state, field);
    if(value)
      errors[field] = validateAlphaNumeric(value);
  });
};

export const validateAddressHouseNumberFields = (state: any, errors: any) => {
  ADDRESS_HOUSE_NUMBER_FIELDS.forEach((field) => {
    const value = getNestedValues(state, field);
    if(!errors[field])
      errors[field] = validateHouseNumber(value);
  });
};

export const validateNameFields = (state: any, errors: any) => {
  NAME_FIELDS.forEach((field) => {
    const value = getNestedValue(state, field);
    if(value)
      errors[field] = validateNames(value);
  });
};

// Helper function to get the value of nested fields using dot notation
function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

function calculateBicChecksum(bic: string): boolean {
  const bicCode = bic.toUpperCase().replace(' ', '');
  const checksumTable = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36',
    '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99'
  ];

  let checksum = 0;
  for (let i = 0; i < bicCode.length; i++) {
    const char = bicCode.charAt(i);
    const index = checksumTable.indexOf(char);
    checksum += index >= 0 ? index + 1 : char.charCodeAt(0) - 55;
  }

  return checksum % 97 === 1;
}
