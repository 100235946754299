import React, { ReactNode, useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import TimePicker from "../common/atoms/TimePicker";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Icon from "../../Icon";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { formatDateTime } from "../common/utlis/dateHelper";
import { ApiCall } from "../../services/ApiServices";
import { PLANNING_LAYOUT, PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, PLANNING_MICROSERVICE } from "../../Constants";
import EmployeeLayoutRow from "./EmployeeLayoutRow";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
interface Timing {
  ptId: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  map(arg0: (employee: EventData) => React.JSX.Element): React.ReactNode;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_break: number;
  total_hrs: number;
  timings: Timing[];
}
interface PlanningTableProps {
  selectedDate?: Date;
  page?: string;
  companyId: number;
  selectedLocation?: { // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  selectedEmployees?: EventData[]
}
interface Shift {
  start_time: string;
  end_time: string;
  sequence: number;
  // Add other shift fields if necessary
}


interface Schedule {
  shifts: Shift[];
  project_id: number | null;
  cost_center_id: number | null;
}

interface PlanningLayoutData {
  planning_id?: number;
  planning_employee_detail_id?: number;
  employee_id: number;
  employee_name?: string;
  employee_type_id?: number;
  employee_type?: string;
  company_id?: number,
  location_id?: number;
  pc_id?: number;
  pc?: string;
  function_id?: number;
  function?: string;
  salary?: string;
  start_date?: string;
  end_date?: string;
  schedule: {
    [date: string]: Schedule;
  };
}
const PlanningTable: React.FC<PlanningTableProps> = ({
  page = "default",
  selectedDate,
  companyId,
  selectedLocation,
  selectedEmployees = undefined,
}) => {
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  const [data, setData] = useState<EventData[]>([]);
  const [layoutData, setLayoutData] = useState<PlanningLayoutData[]>([]);
  const show = useSelector(selectPlanningShow); // Access the `show` state


  useEffect(() => {
    if (selectedDate != undefined) {
      const fetchData = async () => {
        let currentDate = formatDateTime(selectedDate);
        try {
          let dataObj = {
            company_id: companyId,
            location_id: selectedLocation?.id,
            start_date: currentDate,
            end_date: currentDate,
          };
          let result = await ApiCall.service(
            PLANNING_OVERVIEW,
            "POST",
            dataObj,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
          );
          if (result.status === 200) {
            console.log(result.data[currentDate]);
            setData(result.data[currentDate]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [selectedDate]);
  useEffect(() => {
    if (selectedDate !== undefined && selectedEmployees !== undefined) {
      const currentLayoutEmployeeIds = layoutData.map(emp => emp.employee_id);
      const selectedEmployeeIds = selectedEmployees.map(emp => emp.id);

      // Find employees to add (in selectedEmployees but not in layoutData)
      const employeesToAdd = selectedEmployees.filter(emp => !currentLayoutEmployeeIds.includes(emp.id));

      // Find employees to remove (in layoutData but not in selectedEmployees)
      const employeesToRemove = layoutData.filter(emp => !selectedEmployeeIds.includes(emp.employee_id));

      // Remove deselected employees from layoutData
      if (employeesToRemove.length > 0) {
        setLayoutData(prevLayoutData =>
          prevLayoutData.filter(emp => !employeesToRemove.some(removeEmp => removeEmp.employee_id === emp.employee_id))
        );
      }

      const fetchLayoutData = async () => {
        try {
          if (employeesToAdd.length > 0) {
            const dataObj = {
              company_id: companyId,
              location_id: selectedLocation?.id,
              dates: [formatDateTime(selectedDate)],
              employee_ids: employeesToAdd.map(emp => emp.id),
            };
            const result = await ApiCall.service(
              PLANNING_LAYOUT,
              "POST",
              dataObj,
              true,
              CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            );

            if (Array.isArray(result.data)) {
              setLayoutData((prev) => ([
                ...prev, // Spread the previous state
                ...result.data // Spread the new data
              ]));
            } else {
              console.error("Error fetching data:", result.message);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      fetchLayoutData();
    } else if (selectedEmployees == null) {
      setLayoutData([]);
    }
  }, [selectedEmployees]); // Added other dependencies
  return (
    <>
      <table className="table planningTableWrapper table-borderless">
        <thead className="positionSticky">
          <tr className="align-middle">
            <th></th>
            <th>{t("Employee")}</th>
            <th>{t("Status")}</th>
            <th>{t("Function")}</th>
            <th>{t("Paritair committee")}</th>
            <th>{t("Hourly wage")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Break Total")}</th>
            <th>{t("Total work done")}</th>
            <th>{t("Action")}</th>
          </tr>
          <tr className="spacing-row bg-transparent">
            <td
              colSpan={20}
              className="bg-transparent rounded-0 p-0"
              style={{ height: "1vw" }}
            ></td>
          </tr>
        </thead>
        <tbody>
          {(selectedEmployees != undefined && selectedEmployees?.length == 0) && data != undefined &&
            (
              data.length > 0 ?
                data.map((emp: EventData) => (
                  <>
                    {console.log(data)}
                    <tr key={emp.id}>
                      <td>
                        <Image
                          src={LoadImage}
                          imageWidth="3vw"
                          imageHeight="3vw"
                          imageBorderRadius="50%"
                        />
                      </td>
                      <td>{emp.name}</td>
                      <td>{emp.employee_type}</td>
                      <td>{emp.function_name}</td>
                      <td>{emp.pc_name}</td>
                      <td>€{emp.salary}</td>
                      <td>
                        {page === "dashboard" ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.timings[0].shift_starttime}
                            hour={emp.timings[0].shift_starttime.split(":")[0]}
                            minute={emp.timings[0].shift_starttime.split(":")[1]}
                          />
                        ) : (
                          emp.timings[0].shift_starttime
                        )}
                      </td>
                      <td>
                        {page === "dashboard" ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.timings[0].shift_endtime}
                            hour={emp.timings[0].shift_endtime.split(":")[0]}
                            minute={emp.timings[0].shift_endtime.split(":")[1]}
                          />
                        ) : (
                          emp.timings[0].shift_endtime
                        )}
                      </td>
                      <td>
                        {page === "dashboard" && emp.timings[1] != undefined ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.timings[0].shift_starttime}
                            hour={emp.timings[0].shift_starttime.split(":")[0]}
                            minute={emp.timings[0].shift_starttime.split(":")[1]}
                          />
                        ) : (
                          emp.timings[1] != undefined ? emp.timings[1].shift_starttime : "-"
                        )}
                      </td>
                      <td>
                        {page === "dashboard" && emp.timings[1] != undefined ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.timings[0].shift_endtime}
                            hour={emp.timings[0].shift_endtime.split(":")[0]}
                            minute={emp.timings[0].shift_endtime.split(":")[1]}
                          />
                        ) : (
                          emp.timings[1] != undefined ? emp.timings[1].shift_endtime : "-"
                        )}
                      </td>
                      <td>
                        <div className="totalHours text-white poppins-medium">
                          {emp.total_break ?? "00:00"}
                        </div>
                      </td>
                      <td>
                        <div className="totalHours text-white poppins-medium">
                          {emp.total_hrs ?? "00:00"}
                        </div>
                      </td>
                      <td className="actionWrapper">
                        <div>
                          {page == "dashboard" && (
                            <AccessControl
                              renderNoAccess={false}
                              requiredPermissions={[
                                {
                                  permission: "Planning time registration",
                                  read: true,
                                  create: true,
                                  update: true,
                                  delete: true,
                                },
                              ]}
                            >
                              <>
                                <AccessControl
                                  renderNoAccess={false}
                                  requiredPermissions={[
                                    { permission: "Check in", read: true },
                                  ]}
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    title={t("Start")}
                                    onClick={() => { }}
                                    className="me-2"
                                  >
                                    <Icon name="startIcon" {...IconProps} />
                                  </span>
                                </AccessControl>
                                <AccessControl
                                  renderNoAccess={false}
                                  requiredPermissions={[
                                    { permission: "Break in", read: true },
                                  ]}
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    title={t("Pause")}
                                    onClick={() => { }}
                                    className="me-2"
                                  >
                                    <Icon name="pauseIcon" {...IconProps} />
                                  </span>
                                </AccessControl>
                                <AccessControl
                                  renderNoAccess={false}
                                  requiredPermissions={[
                                    { permission: "Check out", read: true },
                                  ]}
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    title={t("Stop")}
                                    onClick={() => { }}
                                    className="me-2"
                                  >
                                    <Icon name="stopIcon" {...IconProps} />
                                  </span>
                                </AccessControl>
                              </>
                            </AccessControl>
                          )}
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Clone")}
                            onClick={() => { }}
                            className="me-2"
                          >
                            <Icon name="CloneIcon" {...IconProps} />
                          </span>
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Edit")}
                            onClick={() => { }}
                            className="me-2"
                          >
                            <Icon name="EditIcon" {...IconProps} />
                          </span>
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Remove")}
                            onClick={() => { }}
                          >
                            <Icon name="RemoveIcon" {...IconProps} />
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={15}
                        style={{ height: "0.7vw" }}
                        className="emptyRow p-0 bg-transparent"
                      ></td>
                    </tr>
                  </>
                )) :
                <tr>
                  <td>
                    <div>
                      <p>{t("There are no schedules for this day")}</p>
                    </div>
                  </td>
                </tr>
            )
          }
          {(selectedEmployees != undefined && layoutData != undefined && page !== "dashboard") &&
            (
              layoutData.length > 0 && (
                layoutData.map((emp) => (
                  <EmployeeLayoutRow key={emp.employee_id} emp={emp} />
                ))
              )
            )
          }
        </tbody>
      </table>
    </>
  );
};

export default translate(PlanningTable);  
