import React, { useState } from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import userIcon from "../../static/images/User.svg";
import AgeIcon from "../common/atoms/AgeIcon";

interface Employee {
  company_id: number;
  cost_center_id: number;
  employee_id: number;
  employee_name: string;
  employee_type: string;
  employee_type_id: number;
  profile_picture_url?: string; // New optional field for profile picture
  function: string;
  function_id: number;
  location: string;
  location_id: number;
  pc_id: number;
  age: number;
  pc_max_age: number;
  pc: string;
  project_id: number;
  salary: string;
  newSalary: string;
  error?:string;
}

interface SelectedEmployeesDetailsTableProps {
  onEmployeeSelected: Employee;
  onNextEmployee: () => void;
  onPreviousEmployee: () => void;
  onEditPc: () => boolean;
}

const SelectedEmployeesDetailsTable: React.FC<
  SelectedEmployeesDetailsTableProps
> = ({ onEmployeeSelected, onNextEmployee, onPreviousEmployee, onEditPc }) => {
  const IconProp = {
    width: "1vw",
    isIcon: true,
    height: "1vw",
    fillColor: "#8571b9",
    outline: "0.2vw solid #8571b9",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };

  return (
    <div className="row">
      <div className="employeeSchedulingTable col-12 px-0">
        <table className="table mb-0">
          <tbody>
            <tr>
              <td className="align-middle text-start backIconWrapper ps-3">
                <Icon
                  name="backIcon"
                  {...IconProp}
                  onClick={onPreviousEmployee}
                />
              </td>
              <td>
                <div className="d-inline-flex align-items-center">
                  <IconWithTitle
                    src={onEmployeeSelected.profile_picture_url ?? userIcon}
                    isImage={true}
                    imageWidth="2vw"
                    imageHeight="2vw"
                    imageBorderRadius="50%"
                    title={onEmployeeSelected.employee_name}
                    isTitle={true}
                    labelClassName="colorPrimary poppins-medium ms-2 employeeName"
                  />
                  <span className="ms-2">
                    <AgeIcon
                      age={onEmployeeSelected.age}
                      pc_max_age={onEmployeeSelected.pc_max_age}
                      type={1}
                    />
                  </span>
                </div>
              </td>
              <td>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.pc}
                </div>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.employee_type}
                </div>
              </td>
              <td>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.function}
                </div>
                <div className="colorPrimary poppins-medium ms-2 employeeName">
                  {onEmployeeSelected.newSalary > onEmployeeSelected.salary
                    ? onEmployeeSelected.newSalary
                    : onEmployeeSelected.salary}
                </div>
              </td>
              <td
                className="align-end cursor-pointer secondaryColorHoverEffect"
                onClick={onEditPc}
              >
                <Icon
                  name="EditIcon"
                  width="1vw"
                  height="1vw"
                  fillColor="#8571b9"
                  isIcon={true}
                />
              </td>
              <td className="align-middle text-end nextIconWrapper pe-3">
                <Icon name="nextIcon" {...IconProp} onClick={onNextEmployee} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {onEmployeeSelected.error !="" && <span className="text-danger text-center">{t(onEmployeeSelected.error)}</span>}
    </div>
  );
};

export default translate(SelectedEmployeesDetailsTable);
