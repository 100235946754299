import React from "react";
import LabelField from "../../common/atoms/LabelField";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import Button from "../../common/atoms/Button";
import InputTextfield from "../../common/atoms/InputTextField";
import { REGISTER_VAT_DETAILS, VAT_DETAILS } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { AuthState, DefaultFormState, DropdownOptions } from "../services/CompanyInterface";
import { initialFormValues } from "../State";


// Define the props for the VatNumberField component
interface VatNumberFieldProps {
    t: (key: string) => string; // Translation function
    formState: DefaultFormState;
    companyid: number | null | undefined;
    clearFieldError: (fieldName: string) => void;
    handleSelectChange: (selectedOptions: { label: string; value: string }[], fieldName: string) => void; // Handler for select changes
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Handler for input changes
    user: AuthState; // Add user prop
    setFormState: React.Dispatch<React.SetStateAction<any>>;
    Editing: boolean// Add setFormState prop
}
// VatNumberField component definition
const VatNumberField: React.FC<VatNumberFieldProps> = ({
    t,
    formState,
    user,
    clearFieldError,
    setFormState,
    handleSelectChange,
    handleChange,
    Editing
}) => {
    // Destructure values for clarity
    const { iban_code } = formState?.formValues;
    const inputFieldSharedProps = {
        isTop: false,
        labelClassName: "label colorPrimary",
        colClassName: "col-6",
        className: "inputFieldColor",
    };

    const validateVAT_number = (value: string): string | null => {
        if (!value || value?.trim() === "") {
            return t("VAT number is required");
        }
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
            return t("VAT number must contain only alphanumeric characters");
        }
        if (value?.length < 8) {
            return `${t("VAT number must be at least")} 8 ${t("characters long")}`;
        }
        if (value?.length > 15) {
            return `${t("VAT number must be at most")} 15 ${t("characters long")}`;
        }
        return null;
    };



    const handleVerifyVat = async (formState: DefaultFormState, user: AuthState, setFormState: React.Dispatch<React.SetStateAction<any>>,
    ) => {
        const vatError = validateVAT_number(formState?.formValues?.vat_number);

        if (vatError) {
            setFormState((prev: DefaultFormState) => ({
                ...prev,
                errors: { ...prev.errors, vat_number: vatError },
                formValues: {
                    ...prev.formValues,
                    vat_number: prev?.formValues?.vat_number,
                },
            }));
            return;
        } else {
            setFormState((prev: DefaultFormState) => ({
                ...prev,
                errors: { ...prev.errors, vat_number: "" },
            }));
        }

        // VAT verification API call
        try {
            let url;

            const vat_number: string = `${formState?.formValues?.iban_code}${formState?.formValues?.vat_number}`;
            if (user) {
                url = `${REGISTER_VAT_DETAILS}/${vat_number}`;
            } else {
                url = `${VAT_DETAILS}/${vat_number}`;
            }

            const response = await ApiCall.getService(url, "GET", "central-data-management", false);

            // Check if the response is valid
            if (response?.status === 200) {
                setFormState((prev: DefaultFormState) => {
                    // Find the matching country value based on the country name
                    const matchedCountry = prev?.countryOptions?.find((country: DropdownOptions) => country?.label === response?.data?.['enterprise']?.country);
                    const matchediban = prev?.ibancountries?.find((iban: DropdownOptions) => iban?.value === response?.data?.['enterprise']?.vatcountry_iso);
                    return {
                        ...prev,
                        verfiedVatnumber: `${formState?.formValues?.iban_code}${response?.data?.vatnumber}`,
                        formValues: {
                            ...prev.formValues,
                            vat_number: response?.data?.['enterprise']?.vatnumber || "",
                            official_name: response?.data?.['enterprise']?.official_name || "",
                            search_name: response?.data?.['enterprise']?.name || "",
                            first_name: user?.token ? user?.first_name : "",
                            last_name: user?.token ? user?.last_name : "",
                            email: user?.token ? user?.email : "",
                            phone_number: user?.token ? user?.phone_number : "",

                            // Update address fields inside the official object
                            address: {
                                ...prev.formValues?.address,
                                official: {
                                    ...prev?.formValues?.address?.official,
                                    name: response?.data?.['enterprise']?.name || "",
                                    street: response?.data?.['enterprise']?.street || "",
                                    number: response?.data?.['enterprise']?.street_nr || "",
                                    box: response?.data?.['enterprise']?.bus || "",
                                    postal_code: response?.data?.['enterprise']?.postal_code || "",
                                    city: response?.data?.['enterprise']?.city || "",
                                    country: matchedCountry ? matchedCountry?.value : null,
                                    addition: prev?.formValues?.address?.official?.addition,
                                },
                                invoice: {
                                    ...prev.formValues?.address?.invoice,
                                    name: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.name : "",
                                    street: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.street : "",
                                    number: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.street_nr : "",
                                    box: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.bus : "",
                                    postal_code: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.postal_code : "",
                                    city: prev?.formValues?.is_invoice_address ? response?.data?.['enterprise']?.city : "",
                                    country: prev?.formValues?.is_invoice_address && matchedCountry ? matchedCountry?.value : null,
                                    addition: prev?.formValues?.address?.official?.addition,
                                },
                            },
                            iban_code: matchediban ? matchediban?.value : null,
                            profile_picture_url: prev?.formValues?.profile_picture_url,
                            profile_id: prev?.formValues?.profile_id,
                            profile_path: prev?.formValues?.profile_path,
                            is_invoice_address: prev?.formValues?.is_invoice_address,
                            is_active: prev?.formValues?.is_active,
                            role: prev?.formValues?.role,
                            pc: response?.data?.['pc_ids'],
                            source: prev?.formValues?.source,
                            extrar_ref: prev?.formValues?.extrar_ref,
                        },
                    };
                });
            } else {
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev.errors, vat_number: response?.message || "VAT verification failed." },
                }));
            }
        } catch (error: unknown | any) {
            console.error(error);
            if (error.response?.status === 422) {
                // Handle validation errors for status code 422
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev.errors, vat_number: error.response?.message || "Validation error." },
                    formValues: {
                        ...prev.formValues,
                        vat_number: prev?.formValues?.vat_number,
                    },
                }));
            } else {
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev.errors, vat_number: "Failed to verify VAT number." },
                    formValues: {
                        ...initialFormValues?.formValues,
                        vat_number: prev?.formValues?.vat_number,
                    },
                }));
            }
        }
    };

    return (
        <div className="row mb-2">
            <div className="col-3 leftlabelClass">
                <LabelField
                    title={t("VAT number") + ":"}
                    className="label colorPrimary"
                    isMandatory
                />
            </div>
            <div className="col-6">
                <div className="row d-flex">
                    <div className="col-4 multiSelectWrapper vatNumber">
                        <SelectWithSearch
                            search
                            options={formState?.ibancountries}
                            value={
                                iban_code
                                    ? [{ label: iban_code, value: iban_code }]
                                    : [{ label: "BE", value: "BE" }]
                            }
                            onChange={(selectedOptions) =>
                                handleSelectChange(selectedOptions, "iban_code")
                            }
                            isMulti={false}
                            id="selectField"
                            name="selectField"
                            selectPrefix="vatNumber"
                            isDisabled={Editing}
                        />
                    </div>
                    <div className="col-8 ps-0" onClick={() => clearFieldError('vat_number')}>
                        <InputTextfield
                            type="text"
                            value={formState?.formValues?.vat_number}
                            handleChange={handleChange}
                            name="vat_number"
                            error={formState?.errors?.vat_number}
                            id="vat_number"
                            autoComplete="off"
                            isDisabled={Editing}
                            {...inputFieldSharedProps}
                        />
                    </div>
                </div>
            </div>
            <div className="col-3">
                <Button
                    handleClick={() => handleVerifyVat(formState, user, setFormState)}
                    title={t("Verify")}
                    className="modalSaveBtn"
                />
            </div>
        </div>
    );
};

export default VatNumberField;
