import React, { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";
import projectsData from './Projects.json';
import { Calendar, DateObject } from "react-multi-date-picker";
import { formatTimeInput } from "../common/utlis/PlanningTimingHelper";
import { formatDateTime } from "../common/utlis/dateHelper";
import LabelField from "../common/atoms/LabelField";


interface OverlayTooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  selectedProject?: (projectId: number, type: number) => void;
  selectedCloneDates?: (dates: string[]) => void;
  placement?: "top" | "right" | "bottom" | "left";
  selectedId?: number
  cloneDates: string[],

}

const OverlayTooltip: React.FC<OverlayTooltipProps> = ({
  children,
  tooltipText,
  selectedProject,
  selectedCloneDates,
  placement = "right",
  selectedId,
  cloneDates,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [type, setType] = useState<string | null>(tooltipText);
  const target = useRef<HTMLSpanElement | null>(null);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const handleDateChange = (dates: any) => {
    setSelectedDates(dates); // Directly set the dates array
    if (selectedCloneDates) {
      selectedCloneDates(dates.map((date: DateObject) => formatDateTime(date.toDate()))); // Format dates as yyyy-mm-dd
    }
  };
  const handleOnClick = (project: any, type: number) => {
    if (selectedProject != undefined) {
      selectedProject(project.id, type); // Pass project ID and type to the parent
    }
  };
  const handleDateSelect = (date: string) => {
    setSelectedDates((prevDates) => {
      const newDates = prevDates.includes(date)
        ? prevDates.filter(d => d !== date)
        : [...prevDates, date];

      if (selectedCloneDates) {
        selectedCloneDates(newDates);
      }
      return newDates;
    });
  };

  const handleSelectAll = () => {
    const allDates = cloneDates.length === selectedDates.length ? [] : cloneDates;
    setSelectedDates(allDates);
    if (selectedCloneDates) {
      selectedCloneDates(allDates);
    }
  };



  const renderTooltipContent = () => {
    switch (type) {
      case "Projects":
        return (
          <>
            <>
              {projectsData.map((project) => (
                <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedId === project.id ? "employee-selected" : ""}`}>
                  <div className="row">
                    <div className="col-8 text-start text-break">
                      <span className="locationName">{project.name}</span>
                    </div>
                    <div className="col-4">
                      <div className="d-inline-flex align-items-center">
                        <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(project, 1)} >1</button>
                        <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2)}>{t('All')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <span
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "Cost center":
        return (
          <>
            <div className="bg-white rounded-3 p-1 ps-3 mb-2">
              <div className="row">
                <div className="col-8 text-start text-break">
                  <span className="locationName">{t('Cost center')}</span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">{t('All')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <span
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                </div>
                <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">{t('All')}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case "Clone":
        return (
          renderCloneContent()
        );
      case "Shifts":
        return (
          <>
            {
              projectsData.map((project) => (
                <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedId === project.id ? "employee-selected" : ""}`}>
                  <div className="row">
                    <div className="col-8 text-start text-break">
                      <span className="locationName">{project.name}</span>
                    </div>
                    <div className="col-4">
                      <div className="d-inline-flex align-items-center">
                        <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(project, 1)} >1</button>
                        <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2)}>{t('All')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        );

      default:
        return <div className="text-danger">{t("No data available")}</div>;
    }
  };
  const renderCloneContent = () => (
    <>
      <div className="d-flex justify-content-between mb-2 align-items-center">
        <LabelField title={t("Select dates to clone:")} />
        <button
          className="searchBtn ms-2 px-1"
          onClick={handleSelectAll}
        >
          {t("Select All")}
        </button>
      </div>
      {cloneDates.map((date) => (
        <div key={date} className={`bg-white rounded-3 p-2 mb-2 ${selectedDates.includes(date) ? "employee-selected" : ""}`}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="locationName">{formatDateTime(new Date(date))}</span>
            <button
              type="button"
              className={`${!selectedDates.includes(date) ? "resetBtn px-3" : "searchBtn"}`}
              onClick={() => handleDateSelect(date)}
            >
              {selectedDates.includes(date) ? t("Selected") : t("Select")}
            </button>
          </div>
        </div>
      ))}
    </>
  );
  return (
    <>
      {showTooltip && (
        <div
          className="overlay-backdrop"
          onClick={() => setShowTooltip(false)}
        />
      )}
      <span
        onClick={() => setShowTooltip(!showTooltip)}
        ref={target}
        className="me-2 cursor-pointer"
      >
        {children}
      </span>

      <Overlay target={target.current} show={showTooltip} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-tooltip" {...props}>
            {!['Clone',].includes(tooltipText) && (
              <div className="d-flex justify-content-between">
                {tooltipText == "Projects" && (
                  <>
                    <button
                      onClick={() => setType("Projects")}
                      className={`searchBtn me-2 ${type === "Projects" ? "active" : ""
                        }`}
                    >
                      {t("Projects")}
                    </button>
                    <button
                      onClick={() => setType("Cost center")}
                      className={`searchBtn me-2 ${type === "Cost center" ? "active" : ""
                        }`}
                    >
                      {t("Cost center")}
                    </button>
                    <span
                      className="secondaryColorHoverEffect me-2"
                      title={t("Edit")}
                      onClick={() => { }}
                    >
                      <Icon isIcon={true} name="EditIcon" width="1vw" height="1vw" />
                    </span>
                  </>
                )}
                <>
                  {/* <span
                    className="secondaryColorHoverEffect me-2"
                    title={t("Add")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} name="add" width="1vw" height="1vw" />
                  </span> */}
                  <span
                    className="secondaryColorHoverEffect me-2"
                    title={t("Settings")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} name="Settings" width="1vw" height="1vw" />
                  </span>
                </>
              </div>
            )}
            <OverFlowWrapper className="employeeSchedulingTooltip">
              <div className="mt-2">{renderTooltipContent()}</div>
            </OverFlowWrapper>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default translate(OverlayTooltip);
