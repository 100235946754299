import React, { useEffect, useState } from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { t } from "../common/atoms/translation";
import { CREATE_EMPLOYER, GET_EMPLOYER, REGISTER_EMPLOYER } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import CustomNotify from "../common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { AddCompanyModalProps, DefaultFormState, DropdownOptions, FormValues, ReduxState } from "./services/CompanyInterface";
import { useDispatch } from "react-redux";
import { addUserDetails, updateOrAddCompany } from "../../features/auth/AuthSlice";
import { initialFormValues } from "./State";
import { formValidation, populateCompanyForm } from "./services/Companyutils";
import UserDetailsForm from "./EmployerRegistration/UserDetailsForm";
import VatNumberField from "./EmployerRegistration/VatNumberField";
import ProfilePictureUpload from "./EmployerRegistration/ProfilePictureUpload";
import AddressForm from "./EmployerRegistration/AddressForm";

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  show,
  handleClose,
  companyid,
  countries,
  pc,
  iban,
  timezones,
}) => {
  const user = useSelector((state: ReduxState) => state?.auth);

  const [formState, setFormState] = useState<DefaultFormState>(initialFormValues);

  const dispatch = useDispatch();

  const fetchCompany = async (companyid: number) => {
    try {
      const url = `${GET_EMPLOYER}/${companyid}`;
      const response = await ApiCall?.getService(url, "GET", 'central-data-management', true);
      if (response && response?.status === 200) {
        return response?.data;
      } else {
        console.error("Unexpected response status:", response?.status);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchAndPopulateCompany = async () => {
    if (typeof companyid === 'number' && show) {
      setFormState((prevValues: DefaultFormState) => ({
        ...prevValues,
        editing: true,
      }));
      try {
        const company: FormValues = await fetchCompany(companyid);
        if (company) {
          const newFormValues: FormValues | null = populateCompanyForm(company);
          if (newFormValues !== null) {
            setFormState((prevValues: DefaultFormState) => ({
              ...prevValues,
              formValues: newFormValues,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    } else {
      setFormState(initialFormValues);
    }
  };

  useEffect(() => {
    fetchAndPopulateCompany();
  }, [companyid, show]);


  useEffect(() => {
    setFormState((prevValues: DefaultFormState) => ({
      ...prevValues,
      formValues: {
        ...prevValues.formValues,
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        phone_number: user?.phone_number || ''
      },
      countryOptions: countries as DropdownOptions[],
      pcOptions: pc as DropdownOptions[],
      ibancountries: iban as DropdownOptions[],
      timezoneOptions: timezones as DropdownOptions[],
    }));
  }, [countries, pc, iban, timezones]);

  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "label colorPrimary",
    colClassName: "col-6",
    className: "inputFieldColor ps-2",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.target;
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: {
        ...prev?.formValues,
        [name]: value,
      }
    }));
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>, type: "official" | "invoice") => {
    const { name, value } = e?.target;

    setFormState((prev: DefaultFormState) => {
      let updatedAddress = {
        ...prev.formValues?.address,
        [type]: {
          ...prev.formValues?.address[type],
          [name]: value,
        },
      };

      if (prev?.formValues?.is_invoice_address && type === 'official') {
        updatedAddress = {
          ...updatedAddress,
          invoice: {
            ...updatedAddress?.invoice,
            [name]: value,
          },
        };
      }

      return {
        ...prev,
        formValues: {
          ...prev.formValues,
          address: updatedAddress,
          is_invoice_address: type === 'invoice' ? false : prev?.formValues?.is_invoice_address
        },
      };
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Update the state based on the input name
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: {
        ...prev.formValues,
        security: {
          ...prev.formValues.security,
          [name]: value,
        },
      },
    }));
  };




  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e?.target;
    setFormState((prev: any) => {
      const officialAddress = prev?.formValues?.address?.official;
      return {
        ...prev,
        formValues: {
          ...prev?.formValues,
          is_invoice_address: checked,
          address: {
            ...prev?.formValues?.address,
            invoice: checked ? {
              ...prev?.formValues?.address?.invoice,
              street: officialAddress?.street,
              number: officialAddress?.number,
              postal_code: officialAddress?.postal_code,
              city: officialAddress?.city,
              country: officialAddress?.country
            } : {
              ...prev?.formValues?.address?.invoice,
              street: "",
              number: "",
              postal_code: "",
              city: "",
              country: null
            }
          }
        }
      };
    });
  };

  const handleSelectChange = (
    selectedOption: DropdownOptions | DropdownOptions[] | null,
    fieldPath: string,
    isMulti = false,
  ) => {
    const value = isMulti
      ? selectedOption ? (selectedOption as DropdownOptions[]).map((option) => option.value) : []
      : selectedOption ? (selectedOption as DropdownOptions).value : null;

    setFormState((prev: DefaultFormState) => {
      const updatedFormValues = JSON.parse(JSON.stringify(prev.formValues));
      const fieldNames = fieldPath.split(".");
      const lastField: string = fieldNames.pop() as string;

      const currentLevel = fieldNames.reduce((acc: Record<string, any>, field) => {
        acc[field] = acc[field] || {};
        return acc[field];
      }, updatedFormValues);

      currentLevel[lastField] = value;

      return {
        ...prev,
        formValues: updatedFormValues
      };
    });
  };


  const handleSubmit = async () => {
    const previousPcValues = formState?.formValues?.pcvalues;
    const errors = formValidation(
      formState,
      user,
      formState?.editing,
      previousPcValues
    );
    if (Object.keys(errors).length > 0) {
      setFormState((prev: DefaultFormState) => ({
        ...prev,
        errors,
      }));
      return;
    }
    try {
      let response;
      if (user?.token) {
        response = companyid
          ? await ApiCall?.service(
            `${CREATE_EMPLOYER}/${companyid}`,
            "PUT",
            formState?.formValues,
            true,
            'central-data-management'
          )
          : await ApiCall?.service(
            CREATE_EMPLOYER,
            "POST",
            formState?.formValues,
            true,
            'central-data-management'
          );
      } else {
        response = await ApiCall?.service(
          REGISTER_EMPLOYER,
          "POST",
          formState?.formValues,
          true,
          'central-data-management'
        );
      }

      // Handle response based on status
      if (response?.status === 200) {
        const newCompany = {
          id: response?.data?.id,
          name: response?.data?.search_name,
          official_name: response?.data?.official_name,
          profile_picture_url: response?.data?.profile_picture_url,
          locations: [],
        };

        if (response?.data?.id === user?.currentCompany?.id) {
          dispatch(
            addUserDetails({
              currentCompany: {
                id: response?.data?.id,
                name: response?.data?.search_name,
                profile_picture_url: response?.data?.profile_picture_url,
                locations: response?.data?.locations,
              },
            })
          );
          dispatch(updateOrAddCompany(newCompany));
        } else {
          dispatch(updateOrAddCompany(newCompany));
        }


        // Update form state with default values
        setFormState(initialFormValues); // initial state
        CustomNotify({ type: "success", message: response?.message });
        handleClose();
      } else if (response?.statusCode === 422) {
        const errorMessages = response?.data;
        const formattedErrorMessages = Object.values(errorMessages)
          .flat()
          .join("\n");
        CustomNotify({
          type: "error",
          message: formattedErrorMessages,
        });
      }
      else {
        CustomNotify({ type: "error", message: response?.message });
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const close = () => {
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: initialFormValues?.formValues
    }));
    handleClose();
  };

  const clearFieldError = (fieldPath: string) => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldPath]: null,
      },
    }));
  };


  return (
    <ModalPopup
      show={show}
      handleClose={close}
      modalTitle={formState?.editing ? t("Edit company") : t("Register company")}
      modalBackgroundColor="#8571b9"
      className="addCompanyModal"
      buttonText={companyid ? "Update" : "Add"}
      onNextClick={handleSubmit}
      modalBodyClassName="scrollBar"
    >
      <div className="companyData">
        <div className="row">
          <ProfilePictureUpload
            t={(key) => key}
            user={user}
            formState={formState}
            setFormState={setFormState}
          />
          <div className="row">
            <div className="col-12">
              <VatNumberField
                t={t}
                formState={formState}
                user={user}
                companyid={companyid}
                setFormState={setFormState}
                handleSelectChange={handleSelectChange}
                handleChange={handleChange}
                Editing={formState?.editing}
                clearFieldError={clearFieldError}
              />
              <UserDetailsForm
                formState={formState}
                handleChange={handleChange}
                setFormState={setFormState}
                handlePasswordChange={handlePasswordChange}
                inputFieldSharedProps={inputFieldSharedProps}
                t={t}
                clearFieldError={clearFieldError}
                user={user}
              />
            </div>
            <AddressForm
              formState={formState}
              setFormState={setFormState}
              handleAddressChange={handleAddressChange}
              clearFieldError={clearFieldError}
              isInvoiceAddress={formState?.formValues?.is_invoice_address}
              handleSelectChange={handleSelectChange}
              handleCheckboxChange={handleCheckboxChange}
              inputFieldSharedProps={inputFieldSharedProps}
              t={t}
            />
          </div>
          <div className="row">
            <LabelField
              title={`${t("Sector")}:`}
              className="bodyTitle colorPrimary my-3"
            />
          </div>
          <div className="row multiSelectWrapper my-2" onClick={() => clearFieldError('pc')}>
            <SelectWithSearch
              fieldLabel={`${t("Paritair committee")}:`}
              title={`${t("Paritair committee")}:`}
              name="pc"
              placeHolder={t("Select")}
              search
              options={formState?.pcOptions}
              value={formState?.formValues?.pc?.map((pcValue: string) =>
                formState?.pcOptions?.find((option: DropdownOptions) => option?.value === pcValue)
              )}
              onChange={(selectedOptions) =>
                handleSelectChange(selectedOptions, "pc", true)
              }
              isMulti={true}
              className="select-field"
              error={formState?.errors?.pc}
              isMandatory
              isMenuPlacement
              isTranslate
              labelWrapperClassName="col-3 leftlabelClass"
              colClassName="col-6"
            />
          </div>
          <div className="row multiSelectWrapper" onClick={() => clearFieldError('timezone')}>
            <SelectWithSearch
              fieldLabel={`${t("Time zone")}:`}
              title={`${t("Time zone")}:`}
              name="timezone"
              placeHolder={t("Select")}
              search
              options={formState?.timezoneOptions}
              value={formState?.timezoneOptions?.find(
                (option: DropdownOptions) => option?.value === formState?.formValues?.timezone
              ) || null}
              onChange={(selectedOptions) =>
                handleSelectChange(selectedOptions, "timezone", false)
              }
              isMenuPlacement
              className="select-field"
              error={formState?.errors?.timezone}
              isMandatory
              isTranslate
              labelWrapperClassName="col-3 leftlabelClass"
              colClassName="col-6"
            />
          </div>

        </div>
      </div>
    </ModalPopup >
  );
}
export default AddCompanyModal;
