import React, { useEffect, useState } from "react";
import Icon from "../../Icon";
import IconWithTitle from "../common/molecules/IconWithTitle";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import userIcon from "../../static/images/User.svg";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import {lesserSalaryWarning, newSalaryWarning } from "./Validations/PlanningSalaryValidations";
import AgeIcon from "../common/atoms/AgeIcon";
import { fetchSalary, formatFloatSalary } from "./Services/PlanningSalaryHelper";

interface Employee {
  company_id: number;
  cost_center_id: number;
  employee_id: number;
  employee_name: string;
  employee_type: string;
  employee_type_id: number;
  profile_picture_url?: string;
  function: string;
  function_id: number;
  location: string;
  location_id: number;
  age: number;
  pc_id: number;
  pc_max_age: number;
  pc: string;
  project_id: number;
  salary: string;
  newSalary: string;
}

interface FunctionOptions {
  label: string;
  value: number;
  salary?: string;
}

interface PcAges {
  id: number;
  pc_id: number;
  age: number;
  min_sal_percent: string;
  status: boolean;
  max_hour: string;
}

interface CategoryOptions {
  category_name: string;
  category_id: number;
  min_salary: string;
  label: string;
  value: number;
  functions: FunctionOptions[];
}

interface PcOptions {
  id: number;
  pc_number: number;
  pc_name: string;
  pc_max_age: number;
  label: string;
  value: number;
  employee_types: FunctionOptions[];
  pc_ages: PcAges[];
  categories: CategoryOptions[];
}
interface EmployeeData {
  pc?: {
    value: string | number; // Adjust based on your data types
    label: string;
    pc_max_age?: number;
  };
  employee_type?: {
    value: string | number; // Adjust based on your data types
    label: string;
  };
  function_id?: {
    value: string | number; // Adjust based on your data types
    label: string;
  };
  salary?: number | string; // Depending on how you handle salary
  newSalary?: string; // Keeping it as string to handle formats
}

interface SelectedEmployeesDetailsEditProps {
  onEmployeeSelected: Employee;
  onNextEmployee: () => void;
  onPreviousEmployee: () => void;
  pcs: PcOptions[];
  onUpdateEmployee: (updatedEmployee: EmployeeData) => void;
  handleSaveChanges: () => void;
  onChangeData: any
}

const SelectedEmployeesDetailsEdit: React.FC<SelectedEmployeesDetailsEditProps> = ({
  onEmployeeSelected,
  onNextEmployee,
  onPreviousEmployee,
  pcs,
  onUpdateEmployee,
  handleSaveChanges,
  onChangeData,
}) => {
  const [selectedPc, setSelectedPc] = useState<PcOptions | null>(null);
  const [newSalary, setNewSalary] = useState(onChangeData.newSalary ?? "");
  const [data, setData] = useState({
    error: "",
    warning: "",
    pcError: "",
    employeeTypeError: "",
    functionError: "",
  });

  useEffect(() => {
    if (onEmployeeSelected) {
      const defaultPc = pcs.find((pc: PcOptions) => pc.id === onChangeData.pc.value);
      setSelectedPc(defaultPc || null);
    }
  }, [onEmployeeSelected]);

  const IconProp = {
    width: "1vw",
    isIcon: true,
    height: "1vw",
    fillColor: "#8571b9",
    outline: "0.2vw solid #8571b9",
    outlineOffset: "0vw",
    borderRadius: "50%",
  };
  // Handle PC selection changes
  const handlePcChange = (value: any) => {
    const selected = pcs.find((pc) => pc.id === value.value);
    setSelectedPc(selected || null);
    if (selected != undefined) {
      onUpdateEmployee({ pc: { label: `${selected.pc_name} ${selected.pc_number}`, value: selected.id, pc_max_age: selected.pc_max_age } });
    }
    // Clear PC error when user selects a PC
    setData((prev) => ({ ...prev, pcError: "" }));
  };

  // Handle general field changes
  const handleChange = (field: keyof EmployeeData, value: { value: string | number; label: string }) => {
    onUpdateEmployee({
      [field]: { value: value.value, label: value.label }
    });
    if (field === "employee_type") {
      setData((prev) => ({ ...prev, employeeTypeError: "" })); // Clear employee type error
    } else if (field === "function_id") {
      setData((prev) => ({ ...prev, functionError: "" })); // Clear function error
    }
  };

  // Handle new salary changes

  const validateSalary = (value: string) => {
    const selectedFunctionSalary = selectedPc?.categories
      .flatMap((category) => category.functions)
      .find((func) => func.value === onChangeData.function_id)?.salary || "0";

    // Parse the selected function salary and the employee's current salary
    const parsedSelectedSalary = formatFloatSalary(selectedFunctionSalary);
    const parsedCurrentSalary = formatFloatSalary(onEmployeeSelected.salary);

    // Compare with the maximum of function's salary and employee's current salary
    const maxSalary = Math.max(parsedSelectedSalary, parsedCurrentSalary);
    const newSalaryValue = formatFloatSalary(value);

    // Validation checks
    if (newSalaryValue > maxSalary) {
      return {
        error: "",
        warning: newSalaryWarning(),
      };
    } else if (newSalaryValue < parsedCurrentSalary) {
      return {
        error: lesserSalaryWarning(maxSalary),
        warning: "",
      };
    } else {
      return {
        error: "",
        warning: "",
      };
    }
  };

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewSalary(value);

    // Validate salary and update state accordingly
    const { error, warning } = validateSalary(value);
    setData({
      ...data,
      error,
      warning,
    });
    onUpdateEmployee({ newSalary: value });
  };

  const handleSave = () => {
    let validationPassed = true;
    if (!selectedPc) {
      setData((prev) => ({ ...prev, pcError: t("PC is required") }));
      validationPassed = false;
    }
    if (!onChangeData.employee_type || !onChangeData.employee_type.value) {
      setData((prev) => ({ ...prev, employeeTypeError: t("Employee type is required") }));
      validationPassed = false;
    }
    if (!onChangeData.function_id || !onChangeData.function_id.value) {
      setData((prev) => ({ ...prev, functionError: t("Function is required") }));
      validationPassed = false;
    }
    if (validationPassed && !data.error) {
      handleSaveChanges();
    }
  };

  return (
    <div className="row">
      <div className="employeeSchedulingTable employeeSchedulingTableEdit col-12 px-0">
        <table className="table mb-0">
          <tbody>
            <tr>
              <td className="align-middle text-start backIconWrapper">
                <Icon name="backIcon" {...IconProp} onClick={onPreviousEmployee} />
              </td>
              <td>
                <div className="d-inline-flex">
                  <IconWithTitle
                    src={onEmployeeSelected.profile_picture_url ?? userIcon}
                    isImage={true}
                    imageWidth="2vw"
                    imageHeight="2vw"
                    imageBorderRadius="50%"
                    title={onEmployeeSelected.employee_name}
                    isTitle={true}
                    labelClassName="colorPrimary poppins-medium ms-2 employeeName"
                  />
                  <span className="ms-2">
                    <AgeIcon age={onEmployeeSelected.age} pc_max_age={onEmployeeSelected.pc_max_age} type={1} />
                  </span>
                </div>
              </td>
              <td className="multiSelectWrapper">
                <div className="row">
                  <div className="col-5">
                    {/* PC Selection */}
                    <SelectWithSearch
                      search={true}
                      options={pcs.map((pc) => ({
                        label: `${pc.pc_name} (${pc.pc_number})`,
                        value: pc.id,
                      }))}
                      value={
                        selectedPc
                          ? { label: `${selectedPc.pc_name} (${selectedPc.pc_number})`, value: selectedPc.id }
                          : null
                      }
                      onChange={handlePcChange}
                      placeHolder={t("Select PC")}
                      name="pc"
                      selectWrapperClassName="multiSelectBgWhite mb-2"
                    />
                    {data.pcError && <div className="text-danger pb-1">{data.pcError}</div>}

                  </div>
                  <div className="col-4">
                    {/* Employee Type Selection */}
                    {selectedPc && (
                      <SelectWithSearch
                        search={true}
                        options={selectedPc.employee_types || []}
                        value={selectedPc.employee_types.find(
                          (type) => type.value === onChangeData.employee_type.value
                        ) || {}} // Default to an empty object if not found
                        onChange={(value: any) => handleChange("employee_type", value)}
                        placeHolder={t("Select Employee Type")}
                        name="employee_type"
                        selectWrapperClassName="multiSelectBgWhite mb-2"
                      />
                    )}
                    {data.employeeTypeError && <div className="text-danger pb-1">{data.employeeTypeError}</div>}
                  </div>
                  <div className="col-3">
                    {selectedPc && (
                      <input
                        type="text"
                        className="form-control border mb-2 selectPcHeight"
                        value={fetchSalary(selectedPc, onChangeData.function_id.value, onEmployeeSelected.salary, onEmployeeSelected.employee_type_id)}
                        disabled
                      />
                    )}
                  </div>
                  <div className="col-9">
                    {/* Function Selection */}
                    {selectedPc && (
                      <SelectWithSearch
                        search={true}
                        options={selectedPc.categories?.flatMap((category) => category.functions) || []}
                        value={selectedPc.categories
                          ?.flatMap((category) => category.functions)
                          .find((func) => func.value === onChangeData.function_id.value) || {}} // Default to an empty object if not found
                        onChange={(value: any) => handleChange("function_id", value)}
                        placeHolder={t("Select Function")}
                        name="function"
                        selectWrapperClassName="multiSelectBgWhite mb-2"
                      />
                    )}
                    {data.functionError && <div className="text-danger pb-1">{data.functionError}</div>}

                  </div>

                  <div className="col-3">
                    <input
                      type="text"
                      className="form-control inputFieldColorWithBg"
                      value={newSalary}
                      onChange={handleSalaryChange}
                      placeholder={t("New Salary")}
                    />
                  </div>
                </div>
              </td>
              <td className="align-end" >
                <Icon name="sendIcon" width="0.8vw" height="0.8vw" fillColor="#8571b9" isIcon={true} onClick={handleSave} />
              </td>
              <td className="align-middle text-end nextIconWrapper">
                <Icon name="nextIcon" {...IconProp} onClick={onNextEmployee} />
              </td>
            </tr>
          </tbody>
        </table>
        {data.warning && (
          <div className="alert alert-warning mt-2 warningAndErrorAlert">{data.warning}</div>
        )}
        {data.error && (
          <div className="alert alert-danger mt-2 warningAndErrorAlert">{data.error}</div>
        )}
      </div>
    </div>
  );
};

export default translate(SelectedEmployeesDetailsEdit);
