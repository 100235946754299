const CommonServices = {
    downloadFile: async (fileUrl: string, invoiceNumber: string | null) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Invoice_${invoiceNumber}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading the document:', error);
        }
    },
    printFile: async (fileUrl: string) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            // Create a hidden iframe element
            const iframe = document.createElement('iframe');
            iframe.style.position = 'absolute';
            iframe.style.width = '0px';
            iframe.style.height = '0px';
            iframe.style.border = 'none';
            iframe.style.visibility = 'hidden';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);

            // Wait for the iframe to load and then trigger print
            iframe.onload = () => {
                iframe.contentWindow?.focus();
                iframe.contentWindow?.print();

                // Clean up the iframe after the print dialog interaction
                const handleAfterPrint = () => {
                    document.body.removeChild(iframe);
                    URL.revokeObjectURL(blobUrl);
                    window.removeEventListener('afterprint', handleAfterPrint);
                };

                window.addEventListener('afterprint', handleAfterPrint);
            };
        } catch (error) {
            console.error('Error fetching PDF for print:', error);
        }
    }
};
export default CommonServices;

export const formatRegistryNumber = (ssn: string) => {
    const originalNumber: string = ssn;
    const formattedNumber: string = originalNumber.replace(
      /(\d{2})(\d{2})(\d{2})(\d{3})(\d{2})/,
      "$1.$2.$3-$4.$5"
    );
    return formattedNumber;
  };