import React from "react";
import Image from "../common/atoms/Image";
import userIcon from "../../static/images/User.svg";
import { t } from "../common/atoms/translation";
import Button from "../common/atoms/Button";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import LabelField from "../common/atoms/LabelField";
import ToggleButton from "../common/atoms/ToggleButton";
import EmployeeCommunication from "./EmployeeCommunication";
import DocumentsUploadEmployee from "./DocumentsUploadEmployee";
import { FaTrash } from "react-icons/fa";
import LoadingIcon from "../common/utlis/LoadingIcon";
import InputWithLabelAndAddon from "../common/molecules/InputWithLabelAndAddon";
import {
  EmployeeFormData,
  EmployeeProfileProps,
  Option,
} from "./services/Interfaces";
import { euCountries } from "./services/State";
import { LabelWithMaskInputField } from "../common/molecules/LabelWithMaskInputField";

const EmployeeProfile: React.FC<EmployeeProfileProps> = ({
  state,
  setState,
  handleChange,
  handleToggle,
  optionsData,
  countryOptions,
  handleSelectChange,
  handleVerifySSN,
  handleFileUpload,
  validateIban,
}) => {
  const handleDateFormat = (dateValue: Date | null, field: string) => {
    console.log(dateValue);
    const formattedDate = dateValue
      ? new Date(
          new Date(dateValue).getTime() -
            new Date(dateValue).getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 10)
      : null;

    const fieldParts = field.split(".");
    const updateState = (state: any, value: any) => {
      let currentState = state;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        currentState[fieldParts[i]] = currentState[fieldParts[i]] || {};
        currentState = currentState[fieldParts[i]];
      }
      currentState[fieldParts[fieldParts.length - 1]] = value;
      return state;
    };

    setState((prevState: any) => updateState({ ...prevState }, formattedDate));
  };

  const handleProfilePictureChange = (event: any) => {
    const file = event.target.files?.[0];
    const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    let error = "";

    if (file) {
      // Validate file format
      if (!allowedFormats.includes(file.type)) {
        error =
          "Invalid file format, please upload only .png, .jpeg, and .jpg file formats.";
      }

      // Validate file size
      else if (file.size > maxSize) {
        error = "File size is greater than 2MB, please upload a smaller file.";
      }
    }

    setState((prevData) => ({
      ...prevData,
      profile_picture: file,
      errors: {
        ...prevData.errors,
        profile_picture: error,
      },
    }));

    if (!error) {
      handleFileUpload(
        file,
        "profile_picture",
        1,
        "profile_user",
        "profile_picture"
      );
    }
  };

  const handleDeleteImage = () => {
    setState((prevData: any) => ({
      ...prevData,
      profile_picture_url: "",
      profile_picture_id: null,
      errors: {
        ...prevData.errors,
        profile_picture_url: null,
        profile_picture_id: null,
      },
    }));
  };

  return (
    <>
      <div className="row mb-3">
        {/* Profile image */}
        <div className="col-2 align-self-center position-relative">
          <div
            className="addCompanyImage d-flex align-items-center justify-content-center cursor-pointer"
            onClick={() => document.getElementById("file-input")?.click()}
          >
            {state.loading ? (
              <LoadingIcon
                iconType="bars"
                color="#8571b9"
                className="fileUploadLoading"
              />
            ) : (
              <>
                <Image
                  src={state.profile_picture_url || userIcon}
                  imageWidth="100%"
                  imageHeight="100%"
                  imageBorderRadius="50%"
                />
                <input
                  id="file-input"
                  type="file"
                  name="profile_picture"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  onChange={(event) => handleProfilePictureChange(event)}
                  autoComplete="off"
                />
              </>
            )}
            <div>
              {state.profile_picture_id && (
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents click event from bubbling to the parent
                    handleDeleteImage();
                  }}
                  className="delete-icon position-absolute top-0 ms-2"
                  role="button"
                  data-bs-toggle="tooltip"
                  title={t("Delete Profile")}
                >
                  <FaTrash />
                </div>
              )}
            </div>
          </div>
          {state.errors.profile_picture && (
            <span className="text-danger">{state.errors.profile_picture}</span>
          )}
        </div>

        {/* User details */}
        <div className="col-10">
          <div className="row mb-3">
            <div className="col-6 multiSelectWrapper">
              <div className="row">
                <SelectWithSearch
                  fieldLabel={t("Nationality") + ":"}
                  title={t("Nationality")}
                  name="nationality"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={countryOptions.countries}
                  value={
                    countryOptions.countries.find(
                      (option) => option.value === state.nationality
                    ) || null
                  }
                  onChange={(selectedOption: Option) => {
                    const isNational = euCountries.includes(
                      selectedOption.value.toString()
                    );
                    if (isNational) {
                      setState((prevData: EmployeeFormData) => ({
                        ...prevData,
                        work_permit_file_url: "",
                        work_permit_file_id: null,
                        work_permit: {
                          ...prevData.work_permit,
                          end_date: "",
                          number: "",
                        },
                        errors: {
                          ...prevData.errors,
                          work_permit: null,
                        },
                      }));
                    }

                    setState((prevData: EmployeeFormData) => ({
                      ...prevData,
                      country_of_birth: Number(selectedOption.value),
                      address: {
                        ...prevData.address,
                        domicile: {
                          ...prevData.address.domicile,
                          country: Number(selectedOption.value),
                        },
                        residence: {
                          ...prevData.address.residence,
                          country: Number(selectedOption.value),
                        },
                      },
                      is_national: isNational, // Set is_national based on the check
                    }));

                    handleSelectChange(selectedOption, "nationality");
                  }}
                  isMulti={false}
                  className="select-field"
                  error={state?.errors?.nationality ?? undefined}
                  isTranslate={true}
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 d-flex align-items-center">
              <LabelField
                title={t("EU citizen") + ":"}
                className="mb-0 colorPrimary poppins-medium me-3"
              />
              <ToggleButton
                value={state?.is_national}
                disabled={true}
                onToggle={handleToggle("is_national")}
              />
            </div>
            {!state.is_national && (
              <div className="row">
                <div className="col-6 mt-3">
                  <div className="row">
                    <LabelWithInputField
                      label={t("Work Permit Number") + ":"}
                      className="inputFieldColor"
                      colClassName="col-8"
                      type="text"
                      value={state?.work_permit?.number}
                      handleChange={handleChange("work_permit", "number")}
                      name="work_permit_number"
                      id="work_permit_number"
                      isMandatory={true}
                      error={state?.errors?.["work_permit.number"] ?? undefined}
                      labelWrapperClassName="col-4 align-self-center"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="row mt-3">
                    <div className="col-4 align-self-center">
                      <LabelField
                        title={t("End date of work permit") + ":"}
                        isMandatory={true}
                      />
                    </div>
                    <div className="col-8 position-relative">
                      <Calender
                        onChange={(date) =>
                          handleDateFormat(date, "work_permit.end_date")
                        }
                        minDate={new Date()}
                        name="end_date"
                        selectedDate={
                          state?.work_permit?.end_date
                            ? new Date(state?.work_permit?.end_date)
                            : null
                        }
                        error={
                          state?.errors?.["work_permit.end_date"] ?? undefined
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <DocumentsUploadEmployee
                    documentTitle={t("Upload work permit") + ":"}
                    name="work_permit_file"
                    handleFileUpload={handleFileUpload}
                    fileType={5}
                    pathParam={"work_permit"}
                    isMandatory={true}
                    error={state?.errors?.work_permit_file_url ?? undefined}
                    state={state}
                    pic_name={state.work_permit_file_name}
                    setState={setState}
                  />
                </div>
              </div>
            )}

            <div className="col-6 pt-3 ">
              <div className="row">
                <LabelWithMaskInputField
                  label={t("National registry number") + ":"}
                  className="inputFieldColor"
                  colClassName="col-8 d-flex align-items-center"
                  type="text"
                  placeholder="XX.XX.XX-XXX.XX"
                  value={state?.registry_number || ""}
                  handleChange={handleChange("registry_number")}
                  name="registry_number"
                  id="registry_number"
                  labelWrapperClassName="col-4 align-self-center"
                  error={state?.errors?.registry_number ?? undefined}
                  isMandatory={state.nationality === 29 ? true : false}
                  autoComplete="off"
                  mask={[
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                  ]}
                >
                  <Button
                    title={t("Verify")}
                    className="saveBtn ms-3"
                    handleClick={() => handleVerifySSN(state.registry_number)}
                  />
                </LabelWithMaskInputField>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="row multiSelectWrapper mb-3">
            <SelectWithSearch
              fieldLabel={t("Title") + ":"}
              title={t("Title")}
              name="title"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.initial}
              value={
                optionsData.initial.find(
                  (option) => option.value === state.title
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "title")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.title ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="row mb-3">
            <LabelWithInputField
              label={t("First name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={state?.first_name}
              handleChange={handleChange("first_name")}
              name="first_name"
              isMandatory={true}
              error={state?.errors?.first_name ?? undefined}
              id="first_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="row mb-3">
            <LabelWithInputField
              label={t("Last name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={state?.last_name}
              handleChange={handleChange("last_name")}
              name="last_name"
              isMandatory={true}
              error={state?.errors?.last_name ?? undefined}
              id="last_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <EmployeeCommunication
        state={state}
        setState={setState}
        handleChange={handleChange}
      />
      <div className="row">
        <div className="col-4">
          <div className="row mb-3">
            <div className="col-4 align-self-center">
              <LabelField title={t("Date of birth") + ":"} isMandatory={true} />
            </div>
            <div className="col-8 position-relative calendarSectionWrapper">
              <Calender
                onChange={(date) =>
                  date && handleDateFormat(date, "date_of_birth")
                }
                maxDate={new Date()}
                selectedDate={
                  state?.date_of_birth ? new Date(state?.date_of_birth) : null
                }
                error={state?.errors?.date_of_birth ?? undefined}
                name="date_of_birth"
              />
            </div>
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Place of birth") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={state?.place_of_birth}
              handleChange={handleChange("place_of_birth")}
              name="place_of_birth"
              id="place_of_birth"
              error={state?.errors?.place_of_birth ?? undefined}
              labelWrapperClassName="col-4 align-self-center"
              isMandatory={true}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-4 mb-3  multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={`${t("Country of birth")}:`}
              title={t("Country of birth")}
              name="country_of_birth"
              placeHolder={t("Select")}
              error={state?.errors?.country_of_birth ?? undefined}
              isMandatory
              search
              options={countryOptions.countries}
              value={
                countryOptions.countries.find(
                  (option) => option.value === state.country_of_birth
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "country_of_birth")
              }
              isMulti={false}
              className="select-field"
              isTranslate
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Gender") + ":"}
              title={t("Gender")}
              name="gender"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.gender}
              value={
                optionsData.gender.find(
                  (option) => option.value === state.gender
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "gender")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.gender ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Desired employee type") + ":"}
              title={t("Desired employee type")}
              name="desired_employee_type"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={[...optionsData.employee_type_groups].sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              value={
                optionsData.employee_type_groups.find(
                  (option) => option.value === state.desired_employee_type
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "desired_employee_type")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.desired_employee_type ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Education") + ":"}
              title={t("Education")}
              name="education"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.education}
              value={
                optionsData.education.find(
                  (option) => option.value === state.education
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "education")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.education ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Marital status") + ":"}
              title={t("Marital status")}
              name="civil_status"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.civil_status}
              value={
                optionsData.civil_status.find(
                  (option) => option.value === state.civil_status
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "civil_status")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.civil_status ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Dependent spouse") + ":"}
              title={t("Dependent spouse")}
              name="dependent_spouce"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.dependent_spouce}
              value={
                optionsData.dependent_spouce.find(
                  (option) =>
                    option.value === state.dependencies.dependent_spouce
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(
                  selectedOption,
                  "dependencies.dependent_spouce"
                )
              }
              isMulti={false}
              className="select-field"
              error={
                state?.errors?.["dependencies.dependent_spouce"] ?? undefined
              }
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Dependent children") + ":"}
              type="number"
              value={
                state.id !== 0
                  ? state.dependencies.dependent_children
                  : state.dependencies.dependent_children === 0
                  ? null
                  : state.dependencies.dependent_children
              }
              handleChange={handleChange("dependencies", "dependent_children")}
              name={"dependent_children"}
              id="dependent_children"
              isMandatory={true}
              error={
                state?.errors?.["dependencies.dependent_children"] ?? undefined
              }
              className="inputFieldColor"
              colClassName="col-8"
              labelWrapperClassName="col-4 align-self-center"
              min={0}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={t("IBAN country") + ":"}
              title={t("IBAN country")}
              name="iban_country_code"
              placeHolder={t("Select")}
              isMandatory={true}
              error={
                state?.errors?.["bank_account.iban_country_code"] ?? undefined
              }
              search={true}
              options={optionsData.ibancountries}
              value={
                optionsData.ibancountries.find(
                  (option) =>
                    option.value === state.bank_account.iban_country_code
                ) || null
              }
              onChange={(selectedOption: Option) => {
                handleSelectChange(
                  selectedOption,
                  "bank_account.iban_country_code"
                );
              }}
              isMulti={false}
              className="select-field"
              // error={errors.iban}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <InputWithLabelAndAddon
              label={t("IBAN number") + ":"}
              className="inputFieldColor"
              colClassName="col-8 pe-0 ps-2"
              type="text"
              value={state?.bank_account?.iban}
              handleChange={(e) => {
                let value = e.target.value;
                let valueSend = value;
                // Check if the first two characters match the selected country code
                if (
                  valueSend
                    .toUpperCase()
                    .startsWith(state.bank_account.iban_country_code)
                ) {
                  // Slice off the first two characters if they match the country code
                  valueSend = valueSend.slice(2);
                }
                // Validate the IBAN with the country code prepended
                validateIban(state.bank_account.iban_country_code + valueSend);
                setState((prevState) => ({
                  ...prevState,
                  bank_account: {
                    ...prevState.bank_account,
                    iban: value,
                  },
                }));
              }}
              name="iban"
              id="iban"
              placeholder={
                state.bank_account.iban_country_code === ""
                  ? "Please select country"
                  : ""
              }
              labelWrapperClassName="col-4 align-self-center"
              error={state.errors?.["bank_account.iban"] ?? undefined}
              isMandatory={true}
              inputAddonText={state.bank_account.iban_country_code}
            />
          </div>
        </div>
        <div className="col-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("BIC") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={state?.bank_account?.bic}
              handleChange={handleChange("bank_account", "bic")}
              name="bic"
              id="bic"
              labelWrapperClassName="col-4 align-self-center"
              error={state.errors?.["bank_account.bic"] ?? undefined}
              autoComplete="off"
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="row mb-3 multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={t("Language") + ":"}
              title={t("Language")}
              name="language"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.languages}
              value={
                optionsData.languages.find(
                  (option) => option.value === state.language
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "language")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.language ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Driving licence") + ":"}
              title={t("Driving licence")}
              name="driving_license"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.driving_license}
              value={
                optionsData.driving_license.find(
                  (option) => option.value === state.driving_license
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "driving_license")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.driving_license ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-4 mb-3 multiSelectWrapper">
          <div className="row">
            <SelectWithSearch
              fieldLabel={t("Transport to work") + ":"}
              title={t("Transport to work")}
              name="transportToWork"
              placeHolder={t("Select")}
              search={true}
              options={optionsData.transporatations}
              value={
                optionsData.transporatations.find(
                  (option) => option.value === state.transport_type
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "transport_type")
              }
              isMandatory={true}
              className="select-field"
              error={state?.errors?.transport_type ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 align-self-center"
              colClassName="col-8"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeProfile;
