import React, { useEffect, useState, ChangeEvent } from "react";
import LabelField from "../common/atoms/LabelField";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import EmployeeAddress from "./EmployeeAddress";
import EmployeeOthers from "./EmployeeOthers";
import EmployeeProfile from "./EmployeeProfile";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../common/atoms/Button";
import EmergencyContact from "./EmergencyContact";
import DocumentsUploadEmployee from "./DocumentsUploadEmployee";
import CustomNotify, { NotifyType } from "../common/atoms/CustomNotify";
import BackLink from "../common/atoms/BackLink";
import * as ROUTE from "../../routes/RouteConstants";
import {
  COUNTRY_LIST,
  SETTINGS_LIST,
  SSNR_DETAILS,
  EMPLOYEE_REGISTRATION,
  UPDATE_EMPLOYEE,
  EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  SSNR_DETAILS_WITHOUT_LOGIN,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { isValidIBANNumber } from "./isValidIBANNumber";
import { useSelector } from "react-redux";
import OverFlowWrapper from "../common/OverFlowWrapper";
import EmployeeAgreements from "./EmployeeAgreements";
import {
  Address,
  AddressDetails,
  Countries,
  DefaultEmpLegal,
  EmployeeFormData,
  Option,
  OptionsData,
} from "./services/Interfaces";
import {
  defaultDomicileAddress,
  defaultOptionData,
  getDefaultFormValues,
} from "./services/State";
import {
  formValidation,
  setResponseData,
  updateField,
  uploadFile,
} from "./services/EmployeeUtils";
import {
  fetchBicForIban,
  validateSSNNumber,
} from "../../services/ValidationService";
import { formatRegistryNumber } from "../../services/common/CommonServices";

const Registration = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const classname = searchParams.get("type") === "login" ? "mx-5" : "";
  const id = searchParams.get("id");
  const pageTitle = id != null ? "Profile" : "Employee registration";

  const navigator = useNavigate();
  const user = useSelector((stateData: any) => stateData.auth);
  const [state, setState] = useState<EmployeeFormData>(getDefaultFormValues);
  const [countryOptions, setCountryOptions] = useState<Countries>({
    countries: [],
  });
  const [optionsData, setOptionsData] =
    useState<OptionsData>(defaultOptionData);

  const url = new URL(window.location.href);
  const updateId = url.pathname.split("/").pop()?.trim();

  // For Update Employee getting employee details from API
  const fetchEmployeeDetails = async () => {
    try {
      const response = await ApiCall.getService(
        `${UPDATE_EMPLOYEE}/${updateId}`,
        "GET"
      );
      const formattedNumber: string = formatRegistryNumber(
        response?.data?.registry_number
      );
      setState(setResponseData(response?.data, formattedNumber));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch options data from country-list, settings-list API
  const fetchCountryOptions = async () => {
    let result;
    try {
      if (user.token) {
        result = await ApiCall.getService(COUNTRY_LIST, "GET");
      } else {
        result = await ApiCall.getService(REGISTER_COUNTRY_LIST, "GET");
      }
      setCountryOptions({
        countries: result.data || [],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOptionsData = async () => {
    let result;
    try {
      if (user.token) {
        result = await ApiCall.getService(SETTINGS_LIST, "GET");
      } else {
        result = await ApiCall.getService(REGISTER_SETTINGS_LIST, "GET");
      }
      setOptionsData({
        initial: result?.data?.initial || [],
        gender: result?.data?.gender || [],
        education: result?.data?.education || [],
        civil_status: result?.data?.marital_status || [],
        dependent_spouce: result?.data?.dependent_spouse || [],
        driving_license: result?.data?.driving_license || [],
        languages: result?.data?.languages || [],
        employee_type_groups: result?.data?.employee_type_groups || [],
        transporatations: result?.data?.transporatations || [],
        ibancountries: result?.data?.iban_countries || [],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (updateId && !isNaN(Number(updateId))) {
      fetchEmployeeDetails();
    }
    fetchCountryOptions();
    fetchOptionsData();
  }, []);

  useEffect(() => {
    // Check if user.token is available and default_emp_legal is empty
    if (user.token && state.default_emp_legal.length === 0) {
      setState((prevState) => ({
        ...prevState,
        default_emp_legal: [
          {
            company_id: null,
            paritaire_commitee_id: null,
            employee_type_id: null,
            function_id: null,
            minWage: "",
            actual_salary: "",
            is_actual_added: 0,
          },
        ],
      }));
    }
  }, [user.token, state.default_emp_legal.length]);

  // Validate IBAN
  const validateIban = async (iban: string) => {
    const validationError = isValidIBANNumber(iban); // From ValidationService.ts
    if (validationError) {
      setFormErrors("bank_account.iban", validationError);
      setState((prevData) => ({
        ...prevData,
        bank_account: {
          ...prevData.bank_account,
          bic: "",
        },
      }));
      return;
    }
    setFormErrors("bank_account.iban", null);

    // Fetch BIC if IBAN is valid
    await handleIbanValidation(iban);
  };

  // Function to handle IBAN validation and fetching BIC
  const handleIbanValidation = async (iban: string) => {
    try {
      // Fetch BIC for IBAN
      const ibanData = await fetchBicForIban(iban);

      // Validate IBAN and update state based on the result
      if (ibanData.valid) {
        setState((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: ibanData.bankData.bic,
          },
        }));

        // Clear any IBAN/BIC errors
        setFormErrors("bank_account.iban", null);
        setFormErrors("bank_account.bic", null);
      } else {
        // Clear BIC in form state and set error
        setState((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: "",
          },
        }));
        setFormErrors(
          "bank_account.bic",
          "There is no BIC for the provided IBAN"
        );
      }
    } catch (error) {
      console.error("Error fetching BIC:", error);
      setFormErrors("bank_account.bic", "Error fetching BIC data");
    }
  };

  // Handle updates for non-default_emp_legal fields
  const handleSelectChange = (
    selectedOption: Option | null,
    fieldPath: string
  ) => {
    const value = selectedOption ? selectedOption.value : null;
    setState((prevFormData) => {
      return updateField(prevFormData, fieldPath, value);
    });
  };

  // Function to handle toggle
  const handleToggle =
    (field: keyof EmployeeFormData, subfield?: string) => (value: boolean) => {
      setState((prevData) => ({
        ...prevData,
        [field]: subfield
          ? {
              ...(prevData[field] as object),
              [subfield]: value, // Handle nested subfield
            }
          : value, // Handle top-level field
      }));
    };

  // Function to handle address toggle
  const handleAddressToggle = () => {
    setState((prevData) => ({
      ...prevData,
      sameAddress: !prevData.sameAddress,
      address: {
        ...prevData.address,
        domicile: !prevData.sameAddress
          ? { ...prevData.address.residence }
          : { ...defaultDomicileAddress },
      },
    }));
  };

  const handleChange =
    <
      T extends keyof EmployeeFormData,
      K extends keyof DefaultEmpLegal // Add generic K for DefaultEmpLegal keys
    >(
      field: T,
      subfield?: T extends "default_emp_legal" ? K : keyof EmployeeFormData[T], // Conditional type for subfield
      nestedSubfield?: keyof AddressDetails,
      index?: number
    ) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { type, value, checked } = event.target as HTMLInputElement;
      const inputValue = type === "checkbox" ? checked : value.trim();

      if (state.sameAddress && subfield === "domicile") {
        state.sameAddress = false;
      }

      setState((prevData) => {
        // Default structure for each object in the 'default_emp_legal' array
        const defaultEmpLegalDefaults: DefaultEmpLegal = {
          company_id: null,
          paritaire_commitee_id: 0,
          employee_type_id: 0,
          function_id: 0,
          minWage: "",
          actual_salary: "",
          is_actual_added: 0,
        };

        // Check if updating 'default_emp_legal' array
        if (field === "default_emp_legal" && index !== undefined) {
          const updatedArray = [...prevData.default_emp_legal]; // Shallow copy the array

          // Ensure the object at the specified index is initialized with default values
          const item = updatedArray[index] || defaultEmpLegalDefaults;
          updatedArray[index] = {
            ...item, // Merge with the existing or default object
            [subfield as keyof DefaultEmpLegal]: inputValue, // Update the specific subfield
          };

          return {
            ...prevData,
            default_emp_legal: updatedArray, // Set the updated array
          };
        }

        // Other cases (like 'address')
        if (field === "address" && subfield && nestedSubfield) {
          // Update the 'residence' or 'domicile' first
          const updatedAddress = {
            ...prevData.address,
            [subfield as keyof Address]: {
              ...(prevData.address[
                subfield as keyof Address
              ] as AddressDetails),
              [nestedSubfield]: inputValue,
            },
          };

          // If 'sameAddress' is true and we're not updating 'domicile', update 'domicile' to match 'residence'
          if (prevData.sameAddress && subfield !== "domicile") {
            updatedAddress.domicile = updatedAddress.residence;
          }

          return {
            ...prevData,
            address: updatedAddress,
          };
        }

        // Updating other fields with subfield
        if (subfield) {
          return {
            ...prevData,
            [field]: {
              ...(prevData[field] as object),
              [subfield]: inputValue,
            },
          };
        }

        // Updating fields without subfield
        return {
          ...prevData,
          [field]: inputValue,
        };
      });
    };

  //SSN Validation
  const handleVerifySSN = async () => {
    const ssnr = state.registry_number;
    // Validate the raw SSN number
    const ssnError = validateSSNNumber(ssnr);
    if (ssnError) {
      setFormErrors("registry_number", ssnError);
      return;
    }

    // API call with the cleaned SSN number
    try {
      let response: any;
      const url = `${SSNR_DETAILS}/${ssnr}`;
      const url_with_token = `${SSNR_DETAILS_WITHOUT_LOGIN}/${ssnr}`;
      if (user.token) {
        response = await ApiCall.getService(
          url,
          "GET",
          "central-data-management"
        );
      } else {
        response = await ApiCall.getService(
          url_with_token,
          "GET",
          "central-data-management"
        );
      }
      if (response.status === 200) {
        setFormErrors("registry_number", null);
        const formattedNumber: string = formatRegistryNumber(
          response?.data?.registry_number
        );
        setState(setResponseData(response?.data, formattedNumber));
      } else {
        setFormErrors("registry_number", response.message);
      }
    } catch (error) {
      setFormErrors("registry_number", "Failed to verify SSN number");
    }
  };

  //Validation Messages
  const setFormErrors = (name: string, error: string | null) => {
    setState((prevData) => ({
      ...prevData,
      errors: {
        ...prevData.errors,
        [name]: error ?? null, // Convert null to empty string
      },
    }));
  };

  const handleFileUpload = async (
    file: File,
    name: string,
    fileType: number,
    path: string,
    fieldName: string
  ) => {
    if (name === "profile_picture")
      setState((prev) => ({ ...prev, loading: true }));

    const data = await uploadFile(file, file.name, fileType, path);
    if (data) {
      const { fileId: id, fileUrl: url } = data.data;
      setState((prevData) => ({
        ...prevData,
        [fieldName + "_url"]: url,
        [fieldName + "_id"]: id,
        errors: {
          ...prevData.errors,
          [name]: "", // Clear the error if file upload succeeds
        },
      }));
    }

    if (name === "profile_picture")
      setState((prev) => ({ ...prev, loading: false }));
  };

  const handleSubmit = async () => {
    let errors = formValidation(state, user);
    if (errors.ssnDOBError) {
      setState((prev) => ({
        ...prev,
        date_of_birth: "",
      }));
    }
    // Remove keys with null or falsy values
    Object.keys(errors).forEach((key) => {
      if (errors[key] == null) {
        delete errors[key];
      }
    });

    if (Object.keys(errors).length > 0) {
      // Update state with validation errors
      setState((prev) => ({
        ...prev,
        errors,
      }));
      let notificationType: NotifyType = "error";
      CustomNotify({
        type: notificationType,
        message: t("Validation Error"),
      });
      console.log("state", state);
      return;
    } else {
      //API call for check registry number to there or not
      const updatedFormData = {
        ...state,
        email: state.email.toLowerCase(),
        confirm_email: state.confirm_email.toLowerCase(),
        bank_account: {
          ...state.bank_account,
          iban: state.bank_account.iban
            .toUpperCase() // Convert IBAN to uppercase for consistency
            .replace(
              new RegExp(`^${state.bank_account.iban_country_code}`, "i"), // Remove country code if present
              ""
            ),
        },
        default_emp_legal: state.default_emp_legal.map((item) => ({
          ...item,
          actual_salary: item.actual_salary ? item.actual_salary : item.minWage,
          is_actual_added:
            parseFloat(item.actual_salary) > parseFloat(item.minWage),
        })),
        address: {
          ...state.address,
          domicile: {
            ...state.address.domicile,
            id: state.sameAddress ? state.address.residence.id : null, // Set to null if sameAddress is true
          },
        },
      };

      if (state.id !== 0) {
        // Update employee API call
        try {
          const response = await ApiCall.service(
            `${UPDATE_EMPLOYEE}/${updateId}`,
            "PUT",
            updatedFormData
          );
          if (response.status === 200) {
            let notificationType: NotifyType = "success";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response.status === 422) {
            // Handle validation errors
            const errorMessages = response.errors;
            let notificationType: NotifyType = "error";
            let errorMessage = Object.values(errorMessages).join(", "); // Combine all error messages
            CustomNotify({
              type: notificationType,
              message: t(errorMessage),
            });
            return;
          } else {
            let notificationType: NotifyType = "error";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
          }
        } catch (error) {
          console.error("Error updating employee:", error);
        }
      } else {
        // Create employee API call
        let response;
        try {
          if (user.token) {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION,
              "POST",
              updatedFormData
            );
          } else {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
              "POST",
              updatedFormData
            );
          }

          if (response.status === 200) {
            let notificationType: NotifyType = "success";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response.status === 422) {
            const errorMessages = Object.values(response.errors);

            // Loop through each error message
            errorMessages.forEach((message) => {
              let notificationType: NotifyType = "error"; // Set the notification type as 'error'

              let errorMessage: string;

              if (typeof message === "object" && message !== null) {
                errorMessage = Object.values(message).join(", ");
              } else if (typeof message === "string") {
                errorMessage = message;
              } else {
                errorMessage = ""; // Fallback if message is null, undefined, or unhandled type
              }

              // Call the notification function if errorMessage is not empty
              if (errorMessage) {
                CustomNotify({
                  type: notificationType,
                  message: t(errorMessage), // Pass the translated message
                });
              }
            });
            return;
          } else {
            let notificationType: NotifyType = "error";
            CustomNotify({
              type: notificationType,
              message: t(response.message),
            });
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };
  const renderEmployeeOthers = user.token ? (
    <EmployeeOthers
      state={state}
      setState={setState}
      handleChange={handleChange}
      optionsData={optionsData}
      handleSelectChange={handleSelectChange}
    />
  ) : null;

  return (
    <div className={classname}>
      <Header headerName={t(pageTitle)} headerClassName="myAccountCardTitle" />
      <div className="search-bar pb-2">
        <BackLink backLink="/employee-overview" />
        <div className="row">
          <div className="mb-3">
            <LabelField
              title={t("Employee registration") + ":"}
              customStyle={{ fontSize: "1.2vw" }}
              className="pageTitle"
            />
          </div>
        </div>
      </div>
      <div className="managepagesBg mb-4">
        <OverFlowWrapper className="employeeRegistrationHeight container-fluid ps-0">
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <LabelField title={t("Profile")} className="tab-title" />
              </div>
            </div>
          </div>
          <EmployeeProfile
            state={state}
            setState={setState}
            handleChange={handleChange}
            handleToggle={handleToggle}
            optionsData={optionsData}
            countryOptions={countryOptions}
            handleSelectChange={handleSelectChange}
            handleVerifySSN={handleVerifySSN}
            handleFileUpload={handleFileUpload}
            validateIban={validateIban}
          />

          <div className="row pb-3 py-4">
            <div className="col-4">
              <DocumentsUploadEmployee
                documentTitle="Front ID:"
                name="front_pic"
                handleFileUpload={handleFileUpload}
                fileType={2}
                pathParam={"front"}
                isMandatory={true}
                state={state}
                pic_name={state.front_pic_name}
                setState={setState}
                error={state?.errors?.front_pic_url ?? undefined}
              />
            </div>
            <div className="col-4">
              <DocumentsUploadEmployee
                documentTitle="Back ID:"
                name="back_pic"
                handleFileUpload={handleFileUpload}
                fileType={3}
                pathParam={"back"}
                isMandatory={true}
                state={state}
                setState={setState}
                pic_name={state.back_pic_name}
                error={state?.errors?.back_pic_url ?? undefined}
              />
            </div>
          </div>
          <EmployeeAddress
            state={state}
            handleChange={handleChange}
            handleToggle={handleAddressToggle}
            countryOptions={countryOptions}
            handleSelectChange={handleSelectChange}
          />
          <EmergencyContact state={state} handleChange={handleChange} />
          {renderEmployeeOthers}
          <EmployeeAgreements state={state} handleToggle={handleToggle} />
          <div className="pb-5 text-center">
            {state.id !== 0 ? (
              <Button
                handleClick={handleSubmit}
                title={t("Update")}
                className="modalSaveBtn"
              />
            ) : (
              <Button
                handleClick={handleSubmit}
                title={t("Register")}
                className="modalSaveBtn"
              />
            )}
          </div>
        </OverFlowWrapper>
      </div>
    </div>
  );
};
export default Registration;
