import React, { ReactNode, CSSProperties } from "react";

const defaultStyle: CSSProperties = {
  fontSize: "0.8vw", // Default font size
  marginBottom: "0vw"
};

interface Props {
  title: ReactNode;
  customStyle?: CSSProperties;
  isMandatory?: boolean;
  className?: string;
  htmlfor?: string;
  subtitle?: string;
}

const LabelField: React.FC<Props> = ({
  title,
  customStyle = {},
  isMandatory = false,
  className,
  htmlfor,
  subtitle,
}) => {
  const combinedStyle = { ...defaultStyle, ...customStyle }; // Combine default and custom styles

  return (
    <>
      <label
        htmlFor={htmlfor}
        style={combinedStyle}
        className={`${className} form-label field-label colorPrimary poppins-medium`}
      >
        {title}
        {subtitle && (
          <>
            <span className="subTitleWrapper">{subtitle}</span>
          </>
        )}
        {isMandatory && <span className="text-danger">*</span>}
      </label>
    </>
  );
};

export default LabelField;
