import React, { useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { formatDate, formatDateTime } from "../utlis/dateHelper";

interface CalendarProps {
  dates: string[];
  onDateChange: (date: string[]) => void;
  minDate: Date;
  calendarType: "week" | "month"; // New prop to specify week or month view
}

const Calendar: React.FC<CalendarProps> = ({
  dates,
  onDateChange,
  minDate,
  calendarType,
}) => {
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  // Get today's date
  const today = new Date();

  useEffect(() => {
    setSelectedDates(dates);
  }, [dates]); // Ensure only 'dates' are tracked here

  // Helper function to get the start of the week
  const getStartOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay();
    const start = new Date(date);
    start.setDate(date.getDate() - dayOfWeek);
    return start;
  };

  // Helper function to get the end of the week
  const getEndOfWeek = (date: Date) => {
    const dayOfWeek = date.getDay();
    const end = new Date(date);
    end.setDate(date.getDate() + (6 - dayOfWeek));
    return end;
  };

  // Handle multiple date selection
  const handleDateChange = (date: Date | Date[] | null) => {
    if (date instanceof Date) {
      const newSelectedDates = [...selectedDates];
      const formattedDate = formatDateTime(date);

      if (newSelectedDates.some((selectedDate) => selectedDate === formattedDate)) {
        const updatedDates = newSelectedDates.filter(
          (selectedDate) => selectedDate !== formattedDate
        );
        setSelectedDates(updatedDates);
        onDateChange(updatedDates);
      } else {
        const updatedDates = [...newSelectedDates, formattedDate];
        setSelectedDates(updatedDates);
        onDateChange(updatedDates);
      }
    } else if (date === null) {
      setSelectedDates([]);
      onDateChange([]);
    }
  };

  // Conditionally disable dates outside the current week if the calendarType is "week"
  const tileDisabled = ({ date }: { date: Date }) => {
    if (calendarType === "week") {
      const startOfWeek = getStartOfWeek(new Date());
      const endOfWeek = getEndOfWeek(new Date());
      return date < startOfWeek || date > endOfWeek; // Disable dates outside the current week
    }
    if (calendarType === "month") {
      return date.toDateString() > today.toDateString(); // Disable dates outside the current week
    }
    return false;
  };

  // Helper to apply CSS class to the current date tile
  const getTileClassName = ({ date }: { date: Date }) => {
    const formattedDate = formatDateTime(date);

    // For selected dates
    if (selectedDates.includes(formattedDate)) {
      return "date-selected";
    }
    if (calendarType === "month") {
      if(date < today){
        return "date-disabled";
      }; // Disable dates outside the current week
    }
    // Add 'current-date' class for today's date
    if (date.toDateString() === today.toDateString() && !selectedDates.includes(formattedDate)) {
      return "current-date";
    }
    return "date-deselected"; // Default class
  };

  return (
    <div className="calendarWrapper">
      <ReactCalendar
        onClickDay={handleDateChange}
        className="mx-auto"
        minDate={minDate ?? ""}
        tileDisabled={tileDisabled}
        tileClassName={getTileClassName} // Tile class name function
        showWeekNumbers={true}
      />
    </div>
  );
};

export default Calendar;
