import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
import store, { RootState } from "../store";
import secureLocalStorage from "react-secure-storage";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
	"Accept"?: string;
	"Content-Type"?: string;
	Authorization?: any;
	AuthToken?: string;
	//   Access-Control-Allow-Origin": string
};

const getCurrentUserId = () => {
	const currentState: RootState = store.getState(); // Get the current state
	const userId = currentState.auth.userId;
	const role = currentState.auth.role;
	return { userId, role };
};

async function getService(endpoint: string = "", method: string = "GET", microService = 'central-data-management', loadingParam: boolean = false, Obj = {}) {
	const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
	if (externalElement && loadingParam) {
		externalElement.style.display = "flex";
	}

	const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
	const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

	const refreshToken = secureLocalStorage.getItem('refresh_token');
	let token;
	if (refreshToken === '' || refreshToken === null) {
		token = GenerateRefreshToken();
	} else {
		const refreshTokenObj = JSON.parse(refreshToken as string);
		token = refreshTokenObj.token;
	}

	const headers: Headers = {
		"Content-Type": "application/json",
		// "Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${authToken}`
	};
	try {
		const { userId, role } = getCurrentUserId();
		const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en';
		const dataObj = { userId: userId, userRole: role, appLangKey: appLangKey, ...Obj }
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
		const response = await fetch(url, {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				method: "GET",
				// data: EncryptDecryptService.encryptData(JSON.stringify(dataObj)),
				data: dataObj
			}),
		});
		const data = await response.json();
		if (externalElement && loadingParam) {
			externalElement.style.display = "none";
		}

		return data;


		// let decrypteddata = await JSON.parse(
		//   EncryptDecryptService.decryptData(data)
		// );
		// if (externalElement && loadingParam) {
		//   externalElement.style.display = "none";
		// }
		// return decrypteddata;
		return data;

	} catch (error) {

	}
}

async function service(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = "central-data-management",

) {
	try {
		const { userId, role } = getCurrentUserId();
		const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
		const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}

		const refreshToken = secureLocalStorage.getItem('refresh_token');
		const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en';

		let token;

		if (refreshToken === '' || refreshToken === null) {
			token = GenerateRefreshToken();
		} else {
			const refreshTokenObj = JSON.parse(refreshToken as string);
			token = refreshTokenObj.token;
		}

		const headers: Headers = {
			"Content-Type": "application/json",
			// "Access-Control-Allow-Origin": "*",
			Authorization: `Bearer ${authToken}`,
		};
		// const dataObj = {userId: userId, role: role};
		dataObj = { ...dataObj, userId: userId, userRole: role, appLangKey: appLangKey }
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
		const response = await fetch(url, {
			method: method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			redirect: "follow",
			body: JSON.stringify({
				method: method,
				data: dataObj,
			}),
			headers: headers,
		});
		const data = await response.json();

		// let decrypteddata = await JSON.parse(
		//   EncryptDecryptService.decryptData(data)
		// );

		if (externalElement && loadingParam) {
			externalElement.style.display = "none";
		}
		// return decrypteddata;
		return data;
	} catch (error) {
		console.log(error);
	}
}

async function fileSendService(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = "central-data-management",

) {
	try {
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');

		const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}

		const refreshToken = secureLocalStorage.getItem('refresh_token');
		let token;

		if (refreshToken === '' || refreshToken === null) {
			token = GenerateRefreshToken();
		} else {
			const refreshTokenObj = JSON.parse(refreshToken as string);
			token = refreshTokenObj.token;
		}

		const headers: Headers = {
			"Content-Type": "multipart/form-data",
			// "Access-Control-Allow-Origin": "*",
			// Authorization: token,
			Authorization: `Bearer ${authToken}`
		};
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
	
		const response = await fetch(url, {
			method: method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			redirect: "follow",
			body: JSON.stringify({
				method: "POST",

				data: dataObj,

				// data: EncryptDecryptService.encryptData(JSON.stringify(dataObj)),

			}),
			headers: headers,
		});
		const data = await response.json();
		if (externalElement && loadingParam) {
		  externalElement.style.display = "none";
		}
		return data;

		// let decrypteddata = await JSON.parse(
		//   EncryptDecryptService.decryptData(data)
		// );
		// if (externalElement && loadingParam) {
		//   externalElement.style.display = "none";
		// }
		// return decrypteddata;
	} catch (error) {
		console.log(error);
	}
}

export const ApiCall = {
	service,
	getService,
	fileSendService
};
