// salaryHelper.ts
interface FunctionOptions {
    label: string;
    value: number;
    salary?: string;
}

interface PcAges {
    id: number;
    pc_id: number;
    age: number;
    min_sal_percent: string;
    status: boolean;
    max_hour: string;
}

interface CategoryOptions {
    category_name: string;
    category_id: number;
    min_salary: string;
    label: string;
    value: number;
    functions: FunctionOptions[];
}

interface PcOptions {
    id: number;
    pc_number: number;
    pc_name: string;
    pc_max_age: number;
    label: string;
    value: number;
    employee_types: FunctionOptions[];
    pc_ages: PcAges[];
    categories: CategoryOptions[];
}


/**
 * Fetches the salary based on the selected PC, function ID, current salary, and employee type.
 * @param selectedPc The selected PC options.
 * @param functionId The function ID.
 * @param employeeSalary The current salary of the employee.
 * @param employeeType The type of the employee.
 * @returns The updated salary format.
 */
export const fetchSalary = (
    selectedPc: PcOptions,
    functionId: number,
    employeeSalary: string,
    employeeType: number
): string => {
    if (!selectedPc) {
        return updatesalaryFormat(employeeSalary); // Return current salary if no PC is selected
    }

    // Find the salary associated with the selected function ID and employee type
    const selectedFunctionSalary = selectedPc.categories
        ?.flatMap((category) => category.functions)
        .find((func) => func.value === functionId)?.salary || "0"; // Check for employeeType

    // Get the maximum of the selected function's salary or the employee's current salary
    let maxSalary = formatFloatSalary(selectedFunctionSalary);

    if (employeeSalary != "") {
        maxSalary = Math.max(
            maxSalary,
            formatFloatSalary(employeeSalary)
        );
    }
    return updatesalaryFormat(maxSalary);
};
export const updatesalaryFormat = (salary: number | string): string => {
    if (salary !== undefined && salary !== null) {
        let stringValue = salary.toString();

        // Check if the number has a decimal part
        if (stringValue.includes(".") || stringValue.includes(",")) {
            // Split the string by "." or ","
            let parts = stringValue.split(/\.|,/);

            // Ensure four digits after the decimal point or comma
            if (parts[1] === undefined || parts[1].length < 4) {
                let leng = parts[1] ? parts[1].length : 0;
                // Add zeros to make it 4 characters long
                while (leng < 4) {
                    parts[1] = parts[1] ? parts[1] + "0" : "0";
                    leng++;
                }
            } else if (parts[1].length > 4) {
                parts[1] = parts[1].substring(0, 4);
            }

            // Concatenate the parts back into a string
            return parts.join(",");
        } else {
            // No decimal part, convert to float with four zeros
            return stringValue + ",0000";
        }
    } else {
        return "0";
    }
};
export const formatFloatSalary = (salary: string): number => {
    return parseFloat(salary.replace(",", ".").replace(/\s/g, ""));
};
export const formatEuroSalary = (salary: string): number => {
    return parseFloat(salary.replace(",", ".").replace(/\s/g, ""));
};