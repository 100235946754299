import React, { useRef, useState } from "react";
import Image from "../common/atoms/Image";
import LabelField from "../common/atoms/LabelField";
import uploadIcon from "../../../src/static/images/Upload.svg";
import Icon from "../../Icon";
import { t } from "../common/atoms/translation";
import { DocumentsUploadProps } from "./services/Interfaces";

const DocumentsUploadEmployee: React.FC<DocumentsUploadProps> = ({
  documentTitle,
  infoIconWrapper = false,
  helpText,
  name,
  handleFileUpload,
  fileType,
  pathParam,
  isMandatory = false,
  state,
  pic_name,
  error,
  setState,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFileURL, setUploadedFileURL] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  
  const handleImageClick = () => {
    fileInputRef.current?.click();
  };
  
  const MAX_FILE_SIZE_MB =  8 ; // Maximum file size in MB
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      if (!allowedTypes.includes(file?.type.split("/")[1])) {
       
        setState((prevData) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            [name + "_url"]: `Only .pdf, .jpg, .jpeg, .png formats are allowed`,
          },
        }));
        return;
      }

      const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        setUploadedFileName(null);
        setUploadedFileURL(null);
        setState((prevData) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            [name + "_url"]: `File size exceeds ${MAX_FILE_SIZE_MB}MB.`,
          },
        }));
        return;
      } else {
        // setFileError(null); // Clear any previous error
        setState((prevData) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            [name + "_url"]: null,
          },
        }));
      }

      handleFileUpload(file, name, fileType, pathParam || "", name);
      setUploadedFileName(pic_name || file.name);
      const fileURL = URL.createObjectURL(file);
      setUploadedFileURL(state[name + "_url"] || fileURL);

      // Reset errors for this field when a new file is selected
      setState((prevData) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          [name + "_url"]: null,
        },
      }));
    }
  };

  const handleRemoveFile = () => {
    alert("Are you sure you want to remove this file?");
    if (state[name + "_url"] || uploadedFileURL) {
      URL.revokeObjectURL(state[name + "_url"] || uploadedFileURL);
    }
    setUploadedFileName(null);
    setUploadedFileURL(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setState((prevData) => ({
      ...prevData,
      [name + "_url"]: null,
      [name + "_id"]: null, 
      [name + "_name"]: null,
    }));

    setState((prevData) => ({
      ...prevData,
      errors: {
        ...prevData.errors,
        [name]: "",
      },
    }));
  };

  return (
    <>
      <LabelField title={documentTitle} isMandatory={isMandatory} />
      {state[name + "_url"] ||
      (uploadedFileURL && uploadedFileName) ||
      pic_name ? (
        <span className="pe-2">
          <a
            href={state[name + "_url"] || uploadedFileURL}
            target="_blank"
            rel="noopener noreferrer"
            className="uploadedFile"
          >
            <span className="ms-2">{pic_name || uploadedFileName}</span>
          </a>
          <span
            onClick={handleRemoveFile}
            className="ms-2 removeUploadedFile cursor-pointer"
            title={t("Remove")}
          >
            <Icon name="RemoveIcon" isIcon={true} width="1vw" height="1vw" />
          </span>
          {infoIconWrapper && (
            <span title={helpText} className="ms-2 cursor-pointer">
              <Icon name="infoIcon" isIcon={true} width="1vw" height="1vw" />
            </span>
          )}
        </span>
      ) : (
        <span
          className="ms-2 cursor-pointer"
          onClick={handleImageClick}
          title={t("Upload file")}
        >
          <Image src={uploadIcon} imageHeight="1vw" imageWidth="1vw" />
        </span>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        autoComplete="off"
      />
      {error && <span className="text-danger ms-2">{error}</span>}
    </>
  );
};

export default DocumentsUploadEmployee;
