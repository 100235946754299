import { InitialPCstate, EmployeeInviteState } from "./Interfaces";

export const initialPCstate: InitialPCstate = {
  paritaire_commitee_id: null,
  employee_type_id: null,
  function_id: null,
  minWage: "12,8752",
  actual_salary: "888888",
  is_actual_added: 0,
};

export const initialstate: EmployeeInviteState = {
  formData: {
    first_name: '',
    last_name: '',
    email: '',
    verify_email: '',
    phone_number: '',
    companies: [] as number[],
    default_emp_legal: [
      initialPCstate
    ]
  }
}