import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

// Define the structure for a shift schedule
interface Shifts {
    start_time: string; // Start time of the shift
    end_time: string;
    sequence: number;
}
interface Schedule {
    location_id: number;
    project_id?: number;
    cost_center?: number;
    shifts: Shifts[];
    error?: string;
    warning?: string[];
}interface ShiftError {
    date: string;
    error: string;
}

interface EmployeeErrors {
    employee_id: number;
    shift_errors: ShiftError[];
    employee_error: string;
}

// Define a map where each date maps to a list of schedules
interface ScheduleMap {
    [date: string]: Schedule; // Each date can have multiple shifts
}

// Define the structure of an Employee object
interface Employee {
    company_id: number;
    location_id?: number;
    location?: string;
    project_id?: number;
    cost_center_id?: number;
    employee_id: number; // Unique identifier for the employee
    employee_name: string;
    employee_type_id: number;
    employee_type: string;
    pc_id: number;
    age: number;
    pc_max_age: number;
    pc: string;
    function_id: number;
    function: string;
    salary: string;
    newSalary?: string;
    start_date: string;  // Start date of the employee's employment
    end_date: string;    // End date of the employee's employment
    schedule?: ScheduleMap; // Optional: Schedule map for the employee
    error?: string;
    warning?: string;
}

// Define the structure of the planning state
interface PlanningState {
    show: boolean; // Flag to control the visibility of the planning section
    tab_id: string; // Identifier for the currently active tab
    company_id: number;
    location_id: number;
    data: {
        contract_type: string; // Type of contract
        week_number: number;   // Current week number
        dates: string[];       // List of dates (as strings)
        planning: Employee[]; // List of employees
    };
}
// Define the structure for the action payload to update employee dates
interface UpdateEmployeeDatesPayload {
    employee_id: number; // Unique identifier for the employee
    start_date: string; // New start date
    end_date: string;   // New end date
}
// Define the structure for updating employee details
interface UpdateEmployeeDetailsPayload {
    employee_id: number;
    pc_id: number;
    pc: string;
    function_id?: number;
    employee_type_id?: number;
    newSalary?: string;
    function_name?: string;
    employee_type?: string;
    pc_max_age?: number;
}


// Initial state for the planning slice
const initialState: PlanningState = {
    show: false,
    tab_id: 'tab_1',
    company_id: 0,
    location_id: 0,
    data: {
        contract_type: 'day',
        week_number: 0,
        dates: [], // Initialize with an empty array of dates
        planning: [] // Initialize with an empty array of employees
    }
};

// Create the planning slice with reducers to manage the state
const PlanningSlice = createSlice({
    name: 'planning',
    initialState,
    reducers: {
        // Toggle visibility of the planning section
        setShow: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload;
        },
        // Set the currently active tab
        setTab: (state, action: PayloadAction<string>) => {
            state.tab_id = action.payload;
        },
        // Set the company_id
        setCompanyId: (state, action: PayloadAction<number>) => {
            state.company_id = action.payload;
        },
        // Set the location_id
        setLocationId: (state, action: PayloadAction<number>) => {
            state.location_id = action.payload;
        },
        // Reset visibility and tab to initial state
        resetShow: (state) => {
            state.show = initialState.show;
            state.tab_id = initialState.tab_id;
            state.company_id = initialState.company_id;
            state.location_id = initialState.location_id;
        },
        // Update the contract type
        setContractType: (state, action: PayloadAction<string>) => {
            state.data.contract_type = action.payload;
            // If the contract type is "day", clear all schedules and dates
            if (action.payload === 'day') {
                // Clear all schedules for each employee
                state.data.planning.forEach(employee => {
                    if (employee.schedule) {
                        employee.schedule = {}; // Set the schedule to an empty object
                    }
                });
                // Clear the dates
                state.data.dates = [];
            }
        },
        // Update the week number and reset employee schedules
        setWeekNumber: (state, action: PayloadAction<{ weekNumber: number, schedule?: ScheduleMap }>) => {
            state.data.week_number = action.payload.weekNumber;

            // Reset employee schedules if contract_type is 'week' and there are existing dates
            if (state.data.contract_type === 'week' && state.data.dates.length > 0) {
                state.data.planning.forEach(employee => {
                    // Check if a new schedule is provided in the payload
                    if (employee.schedule) {
                        if (action.payload.schedule) {
                            // Override the current schedule with the one passed
                            employee.schedule = action.payload.schedule;
                        } else {
                            // Clear the schedule if no schedule is passed
                            employee.schedule = {};
                        }
                    }
                });
            }
        },

        addDate: (state, action: PayloadAction<string>) => {
            state.data.dates = [action.payload]; // Add the new date to the dates array

            // If the contract type is "day", clear all schedules and initialize for the first date
            if (state.data.contract_type === 'day' && state.data.dates.length > 0) {
                state.data.planning.forEach(employee => {
                    if (employee.schedule) {
                        employee.schedule = {
                            [state.data.dates[0]]: {
                                location_id: state.location_id,
                                shifts: [{ // Schedule should be an array
                                    start_time: "", // First shift start time
                                    end_time: "",    // First shift end time
                                    sequence: 1
                                }]
                            }
                        };
                    }
                });
            }
        }
        ,
        // Set the list of dates
        setDates: (state, action: PayloadAction<string[]>) => {
            state.data.dates = action.payload; // Set to an array of string dates
        },
        // Clear all dates
        clearDates: (state) => {
            state.data.dates = [];
        },
        // Add an employee to the list, avoiding duplicates
        addEmployee: (state, action: PayloadAction<Employee>) => {
            const employeeExists = state.data.planning.some(
                (emp) => emp.employee_id === action.payload.employee_id
            );
            if (!employeeExists) {
                state.data.planning.push(action.payload);
            }
        },
        // Remove an employee from the list by ID
        removeEmployee: (state, action: PayloadAction<number>) => {
            state.data.planning = state.data.planning.filter(
                (emp) => emp.employee_id !== action.payload
            );
        },
        // Update the schedule for a specific employee
        updateEmployeeSchedule: (
            state,
            action: PayloadAction<{ employee_id: number; scheduleMap: ScheduleMap }>
        ) => {
            const { employee_id, scheduleMap } = action.payload;
            const employee = state.data.planning.find(
                (emp) => emp.employee_id === employee_id
            );
            if (employee) {
                employee.schedule = scheduleMap; // Update the schedule map
                employee.error = "";
            }
        },
        // Update the updateEmployeeScheduleForDate reducer
        updateEmployeeScheduleForDate: (
            state,
            action: PayloadAction<{
                employee_id: number;
                date: string;
                schedule: Shifts[];
                warnings?: string[]; // Change this to an array
                error?: string;
            }>
        ) => {
            const { employee_id, date, schedule, error, warnings } = action.payload;
            const employee = state.data.planning.find(
                (emp) => emp.employee_id === employee_id
            );

            if (employee) {
                if (employee.schedule && employee.schedule[date]) {
                    employee.schedule[date].shifts = schedule;
                    employee.schedule[date].error = error ?? "";
                    employee.schedule[date].warning = warnings ?? []; // Use an empty array as default
                } else {
                    if (!employee.schedule) {
                        employee.schedule = {};
                    }
                    employee.schedule[date] = {
                        location_id: state.location_id,
                        shifts: schedule ?? [],
                        error: error ?? "",
                        warning: warnings ?? [] // Use an empty array as default
                    };
                }
            }
        },

        // New reducer to clear all schedules
        clearAllSchedules: (state) => {
            state.data.planning.forEach(employee => {
                // Only clear the schedule if it exists
                if (employee.schedule) {
                    employee.schedule = {}; // Set the schedule to an empty object
                }
            });
        },

        // Reset all data to initial state
        resetAllData: (state) => {
            state.show = initialState.show;
            state.tab_id = initialState.tab_id;
            state.company_id = initialState.company_id;
            state.location_id = initialState.location_id;
            state.data = {
                ...initialState.data
            };
        },
        // Set errors for a specific employee's schedule
        setScheduleErrors: (
            state,
            action: PayloadAction<{ employee_id: number; date: string; errors: string }>
        ) => {
            const { employee_id, date, errors } = action.payload;
            const employee = state.data.planning.find((emp) => emp.employee_id === employee_id);
            if (employee && date === "") {
                employee.error = errors;
            }
            if (employee && employee.schedule && employee.schedule[date]) {

                employee.schedule[date].error = errors;
            }
        },

        // Update the setScheduleWarnings reducer
        setScheduleWarnings: (
            state,
            action: PayloadAction<{ employee_id: number; date: string; warnings: string[] }>
        ) => {
            const { employee_id, date, warnings } = action.payload;
            const employee = state.data.planning.find((emp) => emp.employee_id === employee_id);
            if (employee && employee.schedule && employee.schedule[date]) {
                employee.schedule[date].warning = warnings;
            }
        },

        // New reducer to update the start and end date of an employee
        updateEmployeeDates: (state, action: PayloadAction<UpdateEmployeeDatesPayload>) => {
            const { employee_id, start_date, end_date } = action.payload;
            const employee = state.data.planning.find(emp => emp.employee_id === employee_id);
            if (employee) {
                employee.start_date = start_date; // Update start date
                employee.end_date = end_date;     // Update end date
            }
        },
        // Reducer to set general errors for an employee
        setEmployeeError: (
            state,
            action: PayloadAction<{ employee_id: number; error: string }>
        ) => {
            const { employee_id, error } = action.payload;
            const employee = state.data.planning.find(emp => emp.employee_id === employee_id);
            if (employee) {
                employee.error = error; // Set the error for the employee
            }
        },

        // Reducer to set general warnings for an employee
        setEmployeeWarning: (
            state,
            action: PayloadAction<{ employee_id: number; warning: string }>
        ) => {
            const { employee_id, warning } = action.payload;
            const employee = state.data.planning.find(emp => emp.employee_id === employee_id);
            if (employee) {
                employee.warning = warning; // Set the warning for the employee
            }
        },
        // Reducer to handle adding core errors for employees
        addCoreErrors: (state, action: PayloadAction<EmployeeErrors[]>) => {
            action.payload.forEach(coreError => {
                const employee = state.data.planning.find(emp => emp.employee_id === coreError.employee_id);

                if (employee) {
                    // Update general error for the employee if present
                    if (coreError.employee_error) {
                        employee.error = coreError.employee_error;
                    } else {
                        employee.error = "";
                    }
                    // Loop through the shift errors for specific dates
                    coreError.shift_errors.forEach(shiftError => {
                        if (employee.schedule && employee.schedule[shiftError.date]) {
                            // Set the error for the specific date in the schedule
                            employee.schedule[shiftError.date].error = shiftError.error;
                        } else {
                            // Create the schedule entry for the date if it doesn't exist
                            if (!employee.schedule) {
                                employee.schedule = {};
                            }
                            employee.schedule[shiftError.date] = {
                                location_id: state.location_id,
                                shifts: [], // You can set an empty shifts array as a placeholder
                                error: shiftError.error ?? "", // Assign the error
                                warning: []// Initialize warning if not provided
                            };
                        }
                    });
                }
            });
        },
        // New reducer to update an employee's PC, function, employee type, and salary
        updateEmployeeDetails: (state, action: PayloadAction<UpdateEmployeeDetailsPayload>) => {
            const { employee_id, pc_id, function_id, employee_type_id, newSalary, pc, employee_type, function_name, pc_max_age } = action.payload;
            const employee = state.data.planning.find(emp => emp.employee_id === employee_id);

            if (employee) {
                if (pc_id !== undefined && pc != undefined) {
                    employee.pc_id = pc_id;
                    employee.pc = pc;
                    employee.pc_max_age = pc_max_age ?? -1;
                }
                if (function_id !== undefined && function_name != undefined) {
                    employee.function_id = function_id;
                    employee.function = function_name;

                }
                if (employee_type_id !== undefined && employee_type != undefined) {
                    employee.employee_type_id = employee_type_id;
                    employee.employee_type = employee_type;

                }
                if (newSalary !== undefined) {
                    employee.newSalary = newSalary;
                }
            }
        }
    }
});

// Export the actions for use in components
export const {
    setShow,
    setTab,
    setCompanyId,
    setLocationId,
    resetShow,
    setContractType,
    setWeekNumber,
    addDate,
    setDates,
    clearDates,
    addEmployee,
    removeEmployee,
    updateEmployeeSchedule,
    resetAllData,
    clearAllSchedules,
    setScheduleErrors, // Export the new actions
    setScheduleWarnings,
    updateEmployeeScheduleForDate,
    updateEmployeeDates, // Export the new action
    updateEmployeeDetails,
    addCoreErrors,
} = PlanningSlice.actions;

// Selectors to access specific parts of the state
export const selectCompanyId = (state: RootState) => state.planning.company_id;
export const selectLocationId = (state: RootState) => state.planning.location_id;
export const selectPlanningShow = (state: RootState) => state.planning.show;
export const selectTab = (state: RootState) => state.planning.tab_id;
export const selectData = (state: RootState) => state.planning.data;
export const selectContractType = (state: RootState) => state.planning.data.contract_type;
export const selectWeekNumber = (state: RootState) => state.planning.data.week_number;
export const selectDates = (state: RootState) => state.planning.data.dates;
export const selectEmployees = (state: RootState) => state.planning.data.planning;


// Selector: Get schedule keys (dates) for a particular employee by employee_id
export const selectEmployeeScheduleKeysById = (state: RootState, employee_id: number): string[] => {
    const employee = state.planning.data.planning.find(
        (emp: Employee) => emp.employee_id === employee_id
    );
    return employee?.schedule ? Object.keys(employee.schedule) : [];
};

// Selector: Get the schedule for a particular employee by employee_id
export const selectEmployeeScheduleById = (state: RootState, employee_id: number): ScheduleMap => {
    const employee = state.planning.data.planning.find(
        (emp: Employee) => emp.employee_id === employee_id
    );
    return employee?.schedule;
};
// Update the selectEmployeesWithScheduleErrors selector
export const selectEmployeesWithScheduleErrors = (state: RootState): number[] => {
    return state.planning.data.planning
        .filter((emp: Employee) => {
            return (emp.schedule && Object.values(emp.schedule).some((schedule: Schedule) =>
                (schedule.error && schedule.error != "")
            )) || (emp.error && emp.error != "");
        })
        .map((emp: Employee) => emp.employee_id);
};


// Export the reducer to be used in the store
export default PlanningSlice.reducer;
