import { Dispatch } from "redux";
import { addCoreErrors, selectDates, selectEmployees } from "../../../features/planning/planningSlice";
import { RootState } from "../../../store";
import { t } from "../../CentralDataMangement/translation/Translation";
import { isBeforeLiveTime } from "./PlanningValidations";
import { pcMaxAndMinHrs } from "./PlanningTimingValidations";
import { calculateShiftsTotalTime } from "../../common/utlis/PlanningTimingHelper";

interface EmployeeError {
  date: string;
  error: string;
}

interface EmployeeErrors {
  employee_id: number;
  shift_errors: EmployeeError[]; // Shift-related errors
  employee_error: string; // Employee-level error
}

export const validatePlanningAllEmployeeFields = (state: RootState, dispatch: Dispatch): boolean => {
  const employees = selectEmployees(state);

  let hasErrors = false;
  let employeesErrors: EmployeeErrors[] = [];

  // Store all shifts with their respective dates for cross-date validation
  let allShiftsByEmployee: { [employeeId: number]: { date: string; shift: any }[] } = {};

  // Iterate over each employee's schedule
  for (let employee of employees) {
    const employeeErrors: EmployeeErrors = {
      employee_id: employee.employee_id,
      shift_errors: [],
      employee_error: "" // To hold employee-level errors
    };

    // Check if the employee has a schedule
    if (!employee.schedule || Object.keys(employee.schedule).length === 0) {
      employeeErrors.employee_error = t('No schedule for current employee.');
      hasErrors = true;
    }

    // Track days planned for each employee
    const daysPlanned = new Set<string>();

    // Iterate over each date in the employee's schedule
    for (let date in employee.schedule) {
      const shifts = employee.schedule[date]?.shifts;
      const totalHours = calculateShiftsTotalTime(shifts, 2);

      // Check if shifts exist for the current date
      if (!shifts || shifts.length === 0) {
        employeeErrors.shift_errors.push({
          date,
          error: t('No shifts for employee on this date.')
        });
        hasErrors = true;
        continue; // Continue to the next date
      }

      // Sort shifts by start_time to check for overlap
      const sortedShifts = [...shifts].sort((a, b) =>
        new Date(`${date} ${a.start_time}`).getTime() - new Date(`${date} ${b.start_time}`).getTime()
      );

      // Check each shift
      for (let i = 0; i < sortedShifts.length; i++) {
        const shift = sortedShifts[i];

        // Check for missing shift times
        if (!shift.start_time || !shift.end_time) {
          employeeErrors.shift_errors.push({
            date,
            error: t('Empty shift times for employee on this date.')
          });
          hasErrors = true;
        }

        // Check if the start time is before the current live time
        if (isBeforeLiveTime(date, shift.start_time)) {
          employeeErrors.shift_errors.push({
            date,
            error: t('Shift starts before the current live time.')
          });
          hasErrors = true;
        }
        // Check max and min hours validation
        let maxAndMinValidations = pcMaxAndMinHrs(state, employee.pcId, totalHours);
        if (maxAndMinValidations != "") {
          employeeErrors.shift_errors.push({
            date,
            error: maxAndMinValidations
          });
          hasErrors = true;
        }

        // Check for shift overlaps on the same date
        if (i < sortedShifts.length - 1) {
          const nextShift = sortedShifts[i + 1];
          const currentShiftEndTime = new Date(`${date} ${shift.end_time}`).getTime();
          const nextShiftStartTime = new Date(`${date} ${nextShift.start_time}`).getTime();

          if (currentShiftEndTime > nextShiftStartTime) {
            employeeErrors.shift_errors.push({
              date,
              error: t('Shift overlap detected on this date.')
            });
            hasErrors = true;
          }
        }

        // Add shifts to a list for cross-date overlap check
        if (!allShiftsByEmployee[employee.employee_id]) {
          allShiftsByEmployee[employee.employee_id] = [];
        }
        allShiftsByEmployee[employee.employee_id].push({ date, shift });
      }
      // Track the date for this employee
      daysPlanned.add(date);
    }
    // Validate based on employee type for maximum days scheduled
    const employeeType = employee.employee_type_id; // Assuming employee has a 'type' property
    if (![55, 57].includes(employeeType) && daysPlanned.size > 6) {
      employeeErrors.employee_error = t('Employee can only plan a maximum of 6 days in a week.');
      hasErrors = true;
    } else if ([61, 62].includes(employeeType)) {
      let consecutiveDays = 0;
      let previousDate = null;

      for (let date of Array.from(daysPlanned).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())) {
        // Check if the current date is consecutive to the previous date
        if (previousDate) {
          const currentDateObj = new Date(date);
          const previousDateObj = new Date(previousDate);

          // Check if the current date is the next day of the previous date
          const nextDay = new Date(previousDateObj);
          nextDay.setDate(previousDateObj.getDate() + 1); // Adding a day to previous date

          if (currentDateObj.getTime() === nextDay.getTime()) {
            consecutiveDays++;
          } else {
            consecutiveDays = 1; // Reset count if not consecutive
          }
        } else {
          consecutiveDays = 1; // First date
        }

        // Set the previous date for the next iteration
        previousDate = date;

        // Check for more than 2 consecutive days planning
        if (consecutiveDays > 2) {
          employeeErrors.employee_error = t('Employee can only plan for 2 consecutive days. Please provide a gap of at least 1 day after planning 2 days.');
          hasErrors = true;
          break; // Exit the loop since we found an error
        }
      }

    } else {
      // If there are no errors, reset the employee error
      employeeErrors.employee_error = ""; // Clear any existing employee-level errors
    }


    // Only push errors to the final array if there are errors
    if (employeeErrors.employee_error || employeeErrors.shift_errors.length > 0) {
      employeesErrors.push(employeeErrors);
    }
  }

  // Cross-date overlap check
  for (let employeeId in allShiftsByEmployee) {
    const shiftsForEmployee = allShiftsByEmployee[employeeId];

    // Sort all shifts across dates by their start time and ensure dates are handled correctly
    const sortedShiftsAcrossDates = shiftsForEmployee.sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.shift.start_time}`).getTime();
      const dateB = new Date(`${b.date} ${b.shift.start_time}`).getTime();
      return dateA - dateB;
    });

    for (let i = 0; i < sortedShiftsAcrossDates.length - 1; i++) {
      const currentShift = sortedShiftsAcrossDates[i];
      const nextShift = sortedShiftsAcrossDates[i + 1];
      let currentShiftEndTime = new Date(`${currentShift.date} ${currentShift.shift.end_time}`).getTime();
      // If the shift ends past midnight, adjust the date
      if (currentShift.shift.start_time > currentShift.shift.end_time) {
        currentShiftEndTime += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
      }
      const nextShiftStartTime = new Date(`${nextShift.date} ${nextShift.shift.start_time}`).getTime();
      // Adjust logic to account for shifts that overlap across days
      if (currentShiftEndTime > nextShiftStartTime) {
        employeesErrors.push({
          employee_id: parseInt(employeeId),
          shift_errors: [
            {
              date: nextShift.date, // Show error on the next shift's date
              error: t('Shift overlaps with a shift from the previous day.')
            }
          ],
          employee_error: ""
        });
        hasErrors = true;
      }
    }
  }

  // Dispatch errors to the state
  dispatch(addCoreErrors(employeesErrors));

  return hasErrors; // Return true if any errors were found
};
