import { t } from "../../CentralDataMangement/translation/Translation";

export const getTitle = (type: string = "day"): string => {
    let title = "";
    switch (type) {
        case "day":
            title = t("Employee scheduling daily overview");
            break;
        case "week":
            title = t("Employee scheduling weekly overview");
            break;
        case "month":
            title = t("Employee scheduling monthly overview");
            break;
        default:
            break;
    }
    return title;
};