import React from "react";
import LabelField from "../common/atoms/LabelField";
import { t } from "../common/atoms/translation";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import { EmergencyContactProps } from "./services/Interfaces";

const EmergencyContact: React.FC<EmergencyContactProps> = ({
  state,
  handleChange,
}) => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <LabelField title={t("Emergency contact")} className="tab-title" />
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Name of contact person") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="text"
            value={state.emergency_contact.name}
            handleChange={handleChange("emergency_contact", "name")}
            name="name"
            id="name"
            isMandatory={true}
            error={state?.errors?.["emergency_contact.name"] ?? undefined}
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
      <div className="col-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Phone") + ":"}
            className="inputFieldColor"
            colClassName="col-8"
            type="tel"
            value={state.emergency_contact.contact_number}
            handleChange={handleChange("emergency_contact", "contact_number")}
            name="contact_number"
            id="contact_number"
            isMandatory={true}
            error={
              state?.errors?.["emergency_contact.contact_number"] ?? undefined
            }
            labelWrapperClassName="col-4 align-self-center"
          />
        </div>
      </div>
    </div>
  );
};

export default EmergencyContact;
