import React, { useState } from "react";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import { t } from "../../CentralDataMangement/translation/Translation";
import Header from "../../common/layout/Header";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import Button from "../../common/atoms/Button";
import EmployeeOthers from "../EmployeeOthers";
import { initialstate } from "./State";
import { EmployeeInviteState } from "./Interfaces";

const InviteEmployeeOrganism: React.FC = () => {
	const [state, setState] = useState<EmployeeInviteState>(initialstate);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setState((prevState: any) => ({
			...prevState,
			form: {
				...prevState.form,
				[name]: value
			}
		}))
	}

	return (
		<>
			<Header headerName={t('Invite employee')} headerClassName="myAccountCardTitle" />
			<div className="managepagesBg mb-4">
				<OverFlowWrapper className="employeeRegistrationHeight container-fluid ps-0">
					<div className="d-flex justify-content-center gap-3 mt-3">
						<LabelWithInputField
							label={t("Email") + ":"}
							className="inputFieldColor"
							value={state?.formData?.verify_email}
							handleChange={handleChange}
							name="verify_email"
							id="verify_email"
							isMandatory={true}
						/>
						<Button
							title={t("Verify")}
							className="saveBtn"
							handleClick={() => { }}
						/>
					</div>
					<div className="row mt-3">
						<div className="row col-6 me-2">
							<LabelWithInputField
								label={t("First name") + ":"}
								className="inputFieldColor"
								colClassName="col-8"
								type="text"
								value={state?.formData?.first_name}
								handleChange={handleChange}
								name="first_name"
								isMandatory={true}
								id="first_name"
								labelWrapperClassName="col-4 align-self-center"
								autoComplete="off"
							/>
						</div>
						<div className="row col-6">
							<LabelWithInputField
								label={t("Last name") + ":"}
								className="inputFieldColor"
								colClassName="col-8"
								type="text"
								value={state?.formData?.last_name}
								handleChange={handleChange}
								name="last_name"
								isMandatory={true}
								id="last_name"
								labelWrapperClassName="col-4 align-self-center"
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="row col-6 me-2">
							<LabelWithInputField
								label={t("Email") + ":"}
								className="inputFieldColor"
								colClassName="col-8"
								type="text"
								value={state?.formData?.email}
								handleChange={handleChange}
								name="email"
								isMandatory={true}
								id="email"
								labelWrapperClassName="col-4 align-self-center"
								autoComplete="off"
							/>
						</div>
						<div className="row col-6">
							<LabelWithInputField
								label={t("Phone number") + ":"}
								className="inputFieldColor"
								colClassName="col-8"
								type="text"
								value={state?.formData?.phone_number}
								handleChange={handleChange}
								name="phone_number"
								isMandatory={true}
								id="phone_number"
								labelWrapperClassName="col-4 align-self-center"
								autoComplete="off"
							/>
						</div>
					</div>
					{/* <EmployeeOthers
						state={state}
						setState={setState}
						handleChange={handleChange}
						optionsData={optionsData}
						handleSelectChange={handleSelectChange}
					/> */}
					<div className="mt-3">
						<Button
							title="Submit"
							className="modalSaveBtn"
							handleClick={() => console.log(state.formData)}
						/>
					</div>
				</OverFlowWrapper>
			</div>
		</>
	)
};

export default InviteEmployeeOrganism;
