import React, { useState } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import TimePicker from "../common/atoms/TimePicker";
// import { t } from "../common/atoms/translation";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import InputTextfield from "../common/atoms/InputTextField";
import { addConvertDecimalToTime } from "../common/utlis/dateHelper";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { formatTimeInput, calculateShiftsTotalTime } from "../common/utlis/PlanningTimingHelper";

interface Props {
  showLastAction?: boolean;
  isTimeRegistration?: boolean;
  category?: "open" | "closed";
  activeEmployee?: any;
  schedule?: any;
  date?: string;
  shiftIndex: number;
  onEncodageChange: (updatedEncodage: any) => void;
  onCheckboxChange: (date: string) => void;
  selectedShift: string[];
}

const ShiftTiming: React.FC<Props> = ({
  showLastAction = false,
  isTimeRegistration = false,
  category = "open",
  activeEmployee,
  schedule,
  date,
  shiftIndex,
  onEncodageChange,
  onCheckboxChange,
  selectedShift = []


}) => {
  const IconProps = {
    width: "0.8vw",
    height: "0.8vw",
    isIcon: true,
  };

  const [errorStartTime, setErrorStartTime] = useState<string>("");
  const [errorStartTime2, setErrorStartTime2] = useState<string>("");

  const [errorEndTime, setErrorEndTime] = useState<string>("");
  const [errorEndTime2, setErrorEndTime2] = useState<string>("");





  const handleChange = (field: string, value: string, index: number) => {
    const formattedValue = formatTimeInput(value);

    const updatedEncodage = schedule.encodage.map((enc: any, encIndex: number) =>
      encIndex === index ? { ...enc, [field]: formattedValue } : enc
    );
    onEncodageChange(updatedEncodage);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (date) {
      onCheckboxChange(date);
    }
  };

  const shiftCells = (index: number, typeIndex: number, type: string, data: any) => (
    <>
      <td key={`shift1-${0}`} className="bodyBg align-middle">
        <div className="d-flex align-items-center justify-content-center">
          {/* <InputTextfield
            value={data?.[0]?.start_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => handleChange("start_time", e.target.value, 0)}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          /> */}

          <InputTextfield
            value={data?.[0]?.start_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => {
              const inputValue = e.target.value;
              // Restrict input to valid time format
              if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                setErrorStartTime(""); // Clear any previous error
                handleChange("start_time", inputValue, 0);
              } else {
                setErrorStartTime(t("Invalid time format. Please use HH:MM format.")); // Set error message
              }
            }}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          />
          {typeIndex == 2 && errorStartTime && <div className="text-danger">{errorStartTime}</div>}


          <div className="mx-1">{" - "}</div>
          {/* <InputTextfield
            value={data?.[0]?.end_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => handleChange("end_time", e.target.value, 0)}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          /> */}

          <InputTextfield
            value={data?.[0]?.end_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => {
              const inputValue = e.target.value;
              // Restrict input to valid time format
              if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                setErrorEndTime(""); // Clear any previous error
                handleChange("end_time", inputValue, 0);
              } else {
                setErrorEndTime("Invalid time format. Please use HH:MM format."); // Set error message
              }
            }}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          />
          {typeIndex == 2 && errorEndTime && <div className="text-danger">{errorEndTime}</div>}

        </div>
      </td>

      <td key={`shift2-${1}`} className="bodyBg align-middle">
        <div className="d-flex align-items-center justify-content-center">
          {/* <InputTextfield
            value={data?.[1]?.start_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => handleChange("start_time", e.target.value, 1)}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          /> */}

          <InputTextfield
            value={data?.[1]?.start_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => {
              const inputValue = e.target.value;
              // Restrict input to valid time format
              if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                setErrorStartTime2(""); // Clear any previous error
                handleChange("start_time", inputValue, 1);
              } else {
                setErrorStartTime2("Invalid time format. Please use HH:MM format."); // Set error message
              }
            }}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          />
          {typeIndex == 2 && errorStartTime2 && <div className="text-danger">{errorStartTime2}</div>}

          <div className="mx-1">{" - "}</div>
          {/* <InputTextfield
            value={data?.[1]?.end_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => handleChange("end_time", e.target.value, 1)}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          /> */}

          <InputTextfield
            value={data?.[1]?.end_time}
            className="form-control employeeDailyOverviewTable"
            handleChange={(e) => {
              const inputValue = e.target.value;
              // Restrict input to valid time format
              if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                setErrorEndTime2(""); // Clear any previous error
                handleChange("end_time", inputValue, 1);
              } else {
                setErrorEndTime2("Invalid time format. Please use HH:MM format."); // Set error message
              }
            }}
            isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
          />
          {typeIndex == 2 && errorEndTime2 && <div className="text-danger">{errorEndTime2}</div>}

        </div>
      </td>

      <td key={`total-${index}`} className="bodyBg align-middle">
        <div className="totalHours text-white poppins-medium">
          {(typeIndex === 0 || typeIndex === 1) &&
            addConvertDecimalToTime(
              data?.[0]?.total_count || "0",
              data?.[1]?.total_count || "0"
            )
          }
          {typeIndex === 2 &&
            calculateShiftsTotalTime(schedule.encodage)
          }
        </div>
      </td>

    </>
  );



  return (
    <table
      className={`${isTimeRegistration ? "timeRegistrationTable" : "shiftTimingTable"
        } table table-borderless mb-0`}
    >
      <thead>
        {isTimeRegistration ? (
          <>
            <tr>
              <th></th>
              {[
                "Planned hours",
                "Clocked-in hours",
                "Effective hours worked",
              ].map((category) => (
                <th
                  key={category}
                  colSpan={3}
                  className="text-center categorySection"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                    <span className="ms-2">{t(category)}</span>
                  </div>
                </th>
              ))}
              <th></th>
            </tr>
            <tr>
              <th></th>
              {[...Array(3)].map((_, index) => (
                <React.Fragment key={index}>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 1"}
                  </th>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 2"}
                  </th>
                  <th className="text-start text-center shiftNumber">
                    {t("Total")}
                  </th>
                </React.Fragment>
              ))}
              <th></th>
            </tr>
          </>
        ) : (
          <tr>
            <th></th>
            <th>{t("Shift") + " 1"}</th>
            <th>{t("Shift3") + " 2"}</th>
            <th className="text-start">{t("Total")}</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        {isTimeRegistration &&

          <React.Fragment key={shiftIndex}>
            <tr className="spaced-row">
              <td className="p-0">{date}</td>
              {shiftCells(shiftIndex, 0, category, schedule?.planned)}
              {shiftCells(shiftIndex, 1, category, schedule?.timeRegistration)}
              {shiftCells(shiftIndex, 2, category, schedule?.encodage)}
              <td>

                {category === 'open' && <CheckBoxField
                  name={"validate"}
                  isChecked={date ? selectedShift.includes(date) : false}
                  onChangeHandler={handleCheckboxChange}
                />}

              </td>
            </tr>
            <tr>
              <td
                colSpan={isTimeRegistration ? 11 : 5}
                style={{ height: "0.7vw" }}
                className="emptyRow p-0"
              />
            </tr>
          </React.Fragment>
        }

      </tbody>
    </table>
  );
};

export default translate(ShiftTiming);
