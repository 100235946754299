import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ApiCall } from "../../../../services/ApiServices";
import { t } from "../../../CentralDataMangement/translation/Translation";
import {
  ADD_COEFFICIENT_TYPES,
  EDIT_COEFFICIENT_TYPES,
} from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import LabelField from "../../../common/atoms/LabelField";
import Button from "../../../common/atoms/Button";

const OperationCoefficientTypes = ({ rows }) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract id from query parameters
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  // Determine if the operation is an edit or an add based on the URL
  const isEditing = location.pathname.includes("edit");

  const initialRow = rows.find((row) => row.id == id) || {};
  const [state, setState] = useState({
    name: isEditing ? initialRow.name : "",
    rows,
    editFlow: isEditing,
    editUrl: EDIT_COEFFICIENT_TYPES,
    createUrl: ADD_COEFFICIENT_TYPES,
    newItems: isEditing ? [initialRow] : [],
    nameWarning: false,
    typeName: t("coefficient"),
    uniqueError: false,
    duplicates: [],
  });

  const handleInputChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      name: event?.target?.value,
      nameWarning: false,
      uniqueError: false,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, createUrl, editUrl, newItems, editFlow } = state;

    if (!name.trim()) {
      setState((prevState) => ({ ...prevState, nameWarning: true }));
      inputRef.current.focus();
      return;
    }

    try {
      const apiUrl = editFlow ? `${editUrl}/${newItems[0]?.id}` : createUrl;
      const method = editFlow ? "PUT" : "POST";
      const result = await ApiCall.service(apiUrl, method, { name: name });
      if (result.success) {
        // navigate('/manage/coefficient-types');
        window.location.reload();
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <>
      <Header
        headerName={
          state.editFlow
            ? t("Edit coefficient type")
            : t("Add coefficient type")
        }
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink={() => navigate("/manage/coefficient-types")} />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
          <div className="row">
            <div className="col-12">
              <LabelField title={t("Name")} />
              <input
                ref={inputRef}
                type="text"
                value={state.name}
                className={`form-control ${
                  state.nameWarning ? "border-danger" : ""
                } inputFieldColor ps-2`}
                onChange={handleInputChange}
                placeholder={t("Coefficient Name")}
              />
              {state.nameWarning && (
                <small className="text-danger">
                  {t("Please provide the name")}
                </small>
              )}
            </div>
            <div className="col-12 mt-3">
              <Button
                className="searchBtn float-end"
                type="submit"
                title={state.editFlow ? t("Update") : t("Add")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OperationCoefficientTypes;
