import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import Image from "../atoms/Image";
import Icon from "../../../Icon";
import UserImage from "../../../static/images/User.svg";
import { selectAuth } from "../../../features/auth/AuthSlice";
import { useSelector } from "react-redux";
import LabelField from "../atoms/LabelField";
import { t } from "../../CentralDataMangement/translation/Translation";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";
interface Employee {
  id: number;
  first_name?: string;
  last_name?: string;
  is_fav?: boolean;
  profile_picture_url: string | null;
  employee_type?: string;
  employee_type_id?: number;
  employee_type_category?: string;
  employee_type_category_id?: number;
  paritaire_commitee?: string;
  paritaire_commitee_id?: number;
  functional_salary?: string;
  function_id?: number;
  function_name?: string;
  locations: [];
  name: string;
  email?: string;
  official_name: string;
  projects: [];
}

interface CardConfig {
  cardClassName: string;
  employeeInformationClass: string;
  cardMainClassName: string;
  iconClassName?: string;
  isCardIcon?: boolean;
  iconName?: string;
  favouriteIconName: string;
  noFavouriteIconName: string; // Add this for the "unfavorited" state
  isFavourite?: boolean;
  isRelatedCompany?: boolean;
}

interface Company {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string;
  locations: [];
  projects: [];
}

interface EmployeeDetailsCardProps {
  employee_data: any;
  cardConfig: CardConfig;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  handleFavIcon?: MouseEventHandler<HTMLButtonElement>;
  favouriteIconClassName?: string;
  Company?: Company[];
}

const EmployeeDetailsCard: React.FC<EmployeeDetailsCardProps> = ({
  employee_data,
  cardConfig,
  handleClick,
  handleFavIcon,
  favouriteIconClassName,
  Company,
}) => {
  const { profile_picture_url } = employee_data;
  const {
    cardClassName,
    employeeInformationClass,
    cardMainClassName,
    iconClassName,
    isCardIcon,
    iconName,
    favouriteIconName,
    noFavouriteIconName, // Add unfavorited icon
    isFavourite,
    isRelatedCompany,
  } = cardConfig;

  // Optionally, you can use this for conditional rendering based on the user
  const currentUser = useSelector(selectAuth);
  const cardRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<string>("bottom");

  useEffect(() => {
    if (cardRef.current) {
      const { top, bottom } = cardRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bottom;
      const spaceAbove = top;

      if (spaceBelow < 200 && spaceAbove > 200) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  }, []);
  return (
    <div className={`${cardMainClassName} ${tooltipPosition}`} ref={cardRef}>
      {isCardIcon && (
        <div className={iconClassName}>
          <span onClick={handleClick}>
            <Icon isIcon={true} width="1vw" height="1vw" name={iconName} />
          </span>
        </div>
      )}
      <div className={`${cardClassName}`}>
        {handleFavIcon && (
          <div className={favouriteIconClassName}>
            <span onClick={handleFavIcon}>
              <Icon
                className="cursor-pointer"
                isIcon={true}
                width="1vw"
                height="1vw"
                name={isFavourite ? favouriteIconName : noFavouriteIconName} // Toggle between icons
              />
            </span>
          </div>
        )}
        <div
          className={`${isRelatedCompany ? "me-3" : "mx-3 employeeCardIcon"}`}
        >
          <Image
            src={profile_picture_url || UserImage} // Default image if none provided
            imageWidth="3vw"
            imageHeight="3vw"
            imageBorderRadius="50%"
          />
        </div>
        <div className={employeeInformationClass}>
          <div>
            {employee_data?.first_name + " " + employee_data?.last_name}
          </div>
          <div className="empDetails">
            <div>{employee_data?.employee_type}</div>
            <div>{employee_data?.function_name}</div>
            <div>{employee_data?.functional_salary}</div>
            <div>{employee_data?.paritaire_commitee}</div>
            <div>{employee_data?.email}</div>
          </div>
        </div>
      </div>
      {isRelatedCompany && (
        <>
          <div className="adminCardDetails mt-2">
            <span>{t("Manager since") + ":"}</span>
            <span className="ms-2 colorDarkPurple">
              {/* {new Date(employee_data?.admin_from).toLocaleDateString("en-GB")} */}
              {new Date(employee_data?.admin_from)
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\//g, "-")}
            </span>
          </div>
          {Company && Company?.length > 0 && (
            <div>
              <div className="related-company-data adminCardDetails scrollBar">
                <div className="managerOf">
                  <LabelField title={t("Manager of") + ":"} />
                </div>
                {Company.map((company) => (
                  <div className="mb-2 d-block me-2" key={company.id}>
                    <div className="d-flex align-items-center">
                      <div style={{ minWidth: "3.5vw" }}>
                        <Image
                          src={
                            company.profile_picture_url || defaultCompanyIcon
                          } // Default image if none provided
                          imageWidth="3vw"
                          imageHeight="3vw"
                          imageBorderRadius="50%"
                        />
                      </div>
                      <div className="flex-1">
                        <LabelField title={company.name} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeDetailsCard;
