import { FormErrors } from "./Interfaces";

export const defaultDomicileAddress = {
  id: null,
  street: "",
  number: "",
  postal_code: "",
  box: "",
  city: "",
  country: null,
  latitude: 0,
  longitude: 0,
};

export const defaultOptionData = {
  initial: [],
  gender: [],
  education: [],
  civil_status: [],
  dependent_spouce: [],
  driving_license: [],
  languages: [],
  employee_type_groups: [],
  transporatations: [],
  ibancountries: [],
};

export const getDefaultFormValues =  {
    id: 0,
    is_national: true,
    nationality: 29,
    registry_number: "",
    work_permit: {
      number: "",
      end_date: "",
    },
    title: null,
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone_number: null,
    education: null,
    security: {
      password: "",
      confirm_password: "",
    },
    gender: null,
    date_of_birth: "",
    place_of_birth: "",
    country_of_birth: 29,
    civil_status: 0,
    desired_employee_type: 0,
    language: 2,
    bank_account: {
      type: "iban",
      iban: "",
      bic: null,
      iban_country_code: "BE",
    },
    address: {
      residence: {
        id: null,
        street: "",
        number: "",
        postal_code: "",
        box: "",
        city: "",
        country: 29,
        latitude: 0,
        longitude: 0,
      },
      domicile: {
        id: null,
        street: "",
        number: "",
        postal_code: "",
        box: "",
        city: "",
        country: 29,
        latitude: 0,
        longitude: 0,
      },
    },
    driving_license: 0,
    transport_type: null,
    dependencies: {
      dependent_spouce: null,
      dependent_children: 0,
    },
    emergency_contact: {
      name: null,
      contact_number: null,
      email: "",
      relation: "",
    },
    profile_picture_url: "",
    profile_picture_id: null,
    profile_picture: null,
    front_pic: null,
    back_pic: null,
    work_permit_file: null,
    front_pic_url: "",
    front_pic_id: null,
    back_pic_url: "",
    back_pic_id: null,
    front_pic_name: null,
    back_pic_name: null,
    work_permit_file_name: null,
    work_permit_file_id: null,
    work_permit_file_url: "",
    user_terms: {
      is_term_condition: true,
      is_contract_sign: true,
      is_temp_employement: true,
      is_gdpr: true,
    },
    default_emp_legal: [],
    companies: [],
    role: "employee",
    status: "active",
    source: "web",
    extra_ref: "",
    errors: {} as FormErrors,
    national_registry_number: "",
    ssnVerified: false,
    sameAddress: true,
    loading: false,
    validationLoading: false,
    companiesOptions: [],
    showPassword: false,
    showConfirmPassword: false,
};


// EU countries
export const euCountries: string[] = [
  "301", "302", "198", "153", "120", "29", "49", "71", "73", "75", "79", 
  "85", "91", "92", "101", "118", "119", "126", "149", "154", "155", "164", 
  "181", "205", "219", "222", "230", "250", "251", "255", "275", "298",
];

// IBAN country Code length dependding on the country
export const CODE_LENGTHS:any = {
  AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
  CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
  FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
  HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
  LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
  MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
  RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
  AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
  SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
};

// constants for validations
export const NAME_FIELDS = [ "first_name", "last_name", "emergency_contact.name"];
export const ADDRESS_STRING_FIELDS = ["place_of_birth", "address.residence.street", "address.domicile.street", "address.residence.city", "address.domicile.city"];
export const ADDRESS_NUMBER_FIELDS = ["address.residence.postal_code", "address.domicile.postal_code", "address.residence.box", "address.domicile.box"];
export const ADDRESS_HOUSE_NUMBER_FIELDS = ["address.residence.number", "address.domicile.number"];
export const STRING_FIELDS = [
  "first_name",
  "last_name",
  "email",
  "confirm_email",
  "phone_number",
  "date_of_birth",
  "place_of_birth",
  "front_pic_url",
  "back_pic_url",
];

export const NUMBER_FIELDS = [
  "nationality",
  "title",
  "education",
  "gender",
  "country_of_birth",
  "civil_status",
  "desired_employee_type",
  "language",
  "driving_license",
  "transport_type",
];

export const ADDRESS_FIELDS = ["street", "city", "postal_code", "country", "number"];
