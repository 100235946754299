import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/translation";
import LabelField from "../common/atoms/LabelField";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import CheckBoxField from "../common/atoms/CheckBoxField";
import Image from "../common/atoms/Image";
import defaultCompanyIcon from "../../static/images/emptyCompany.png";
import Button from "../common/atoms/Button";
import { ApiCall } from "../../services/ApiServices";
import {
  GET_EMPLOYEE_TYPES_BY_PC,
  GET_FUNCTIONS_BY_PC,
  PC_LIST_BY_COMPANY_ID,
} from "../../routes/ApiEndpoints";
import { useSelector } from "react-redux";
import {
  EmployeeFormData,
  HandleChange,
  OptionsData,
} from "./services/Interfaces";
import { selectAuth } from "../../features/auth/AuthSlice";

type OptionType = {
  value: string | number;
  label: string;
};
interface FunctionOptionType {
  label: string;
  value: number;
  function_id?: number;
  function_name?: string;
  salary?: string;
}

interface EmployeeOthersProps {
  state: EmployeeFormData;
  setState: React.Dispatch<React.SetStateAction<EmployeeFormData>>;
  handleChange: HandleChange;
  optionsData: OptionsData;
  handleSelectChange: (
    selectedOption: OptionType,
    fieldPath: string,
    index?: number
  ) => void;
}
const EmployeeOthers: React.FC<EmployeeOthersProps> = ({
  state,
  setState,
  handleChange,
  handleSelectChange,
}) => {
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
  const [pcOptions, setPCOptions] = useState<OptionType[][]>([]);
  const [fuctionsOptions, setFuctionsOptions] = useState<FunctionOptionType[][]>([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState<OptionType[][]>([]);
  const [companyData, setCompanyData] = useState<{companies: any[];}>({companies: [],});
  const [selectedFunctionIds, setSelectedFunctionIds] = useState<Set<number>>(new Set());

  const user = useSelector(selectAuth);
  // For Setting companies data from redux
  useEffect(() => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
    }));
  }, [user?.companies]);

  const removeFunctionId = (index:number) => {
    // Update the selectedFunctionIds state to remove the function ID for the deleted item
    setSelectedFunctionIds((prevSelected) => {
      const updatedSet = new Set(prevSelected);
      const removedFunctionId = state.default_emp_legal[index]?.function_id;

      // Remove the function ID if it exists in the set
      if (removedFunctionId) {
        updatedSet.delete(removedFunctionId);
      }

      return updatedSet;
    });
  }

  const removeFunctionEmployeeTypeOptions = (index:number) => {
    setFuctionsOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = [];
      return newOptions;
    });
    setEmployeeTypeOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = [];
      return newOptions;
    });
  }

  // Fetching PC options by company ID
  const fetchPCOptions = async (index: number, selectedCompanyId: number) => {
    if (selectedCompanyId !== 0) {
      try {
        const result = await ApiCall.getService(
          `${PC_LIST_BY_COMPANY_ID}/${selectedCompanyId}`,
          "GET"
        );
        setPCOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = result?.data;
          return newOptions;
        });
      } catch (error) {
        console.error("Error fetching PC options:", error);
      }
    } else {
      // If company is not selected, reset the PC options and make company id 0
      setState((prevData) => ({
        ...prevData,
        default_emp_legal: prevData.default_emp_legal.map((item, i) =>
          i === index
            ? {
                ...item,
                company_id: 0,
              }
            : item
        ),
      }));
      setPCOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = [];
        return newOptions;
      });
    }
    // If company is changed, reset the value and options of Functions and Employee-types
    setState((prevData) => ({
      ...prevData,
      default_emp_legal: prevData.default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              paritaire_commitee_id: null,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
            }
          : item
      ),
    }));
    removeFunctionEmployeeTypeOptions(index);
    removeFunctionId(index);
  }
  // Featching Functions and Employee-types by PC ID
  const fetchOptions = async (index: number, selectedPcId: number) => {
    if (selectedPcId !== 0) {
      try {
        const functionsResult = await ApiCall.getService(
          `${GET_FUNCTIONS_BY_PC}/${selectedPcId}`,
          "GET"
        );
        const employeeTypesResult = await ApiCall.getService(
          `${GET_EMPLOYEE_TYPES_BY_PC}/${selectedPcId}`,
          "GET",
          "central-data-management",
          false
        );
        setFuctionsOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = functionsResult?.data?.functions;
          return newOptions;
        });

        setEmployeeTypeOptions((prevOptions) => {
          const newOptions = [...prevOptions];
          newOptions[index] = employeeTypesResult?.data?.employee_types;
          return newOptions;
        });
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    } else {
      removeFunctionEmployeeTypeOptions(index);
    }
    removeFunctionId(index);
    // If any change happen pc id then clear the function and employee type and set the min wage to default value
    setState((prevData) => ({
      ...prevData,
      default_emp_legal: prevData.default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
            }
          : item
      ),
    }));
  };

  const handleFunctionChange = (
    index: number,
    fieldPath: string,
    selectedOption: FunctionOptionType | null
  ) => {
    setState((prevFormData) => {
      const updatedEmpLegal = prevFormData.default_emp_legal.map(
        (item: any, i: number) => {
          if (i === index) {
            return {
              ...item,
              [fieldPath]: selectedOption ? selectedOption.value : null,
              minWage: selectedOption ? selectedOption.salary || "" : "",
            };
          }
          return item;
        }
      );

      // Update the selectedFunctionIds set
      setSelectedFunctionIds((prevSelected) => {
        const updatedSet = new Set(prevSelected);
        const previousValue = prevFormData.default_emp_legal[index]?.function_id;
        if (previousValue) {
          updatedSet.delete(previousValue); // Remove previous value if it existed
        }
        if (selectedOption) {
          updatedSet.add(selectedOption.value); // Add the new selected value if it exists
        }
        return updatedSet;
      });

      return {
        ...prevFormData,
        default_emp_legal: updatedEmpLegal,
      };
    });
  };

  // For handling company checkboxes while ------Edit Employee---- filling Checkboxes
  useEffect(() => {
    if (Array.isArray(state.companies) && companyData?.companies) {
      // Initialize checked items as a Set from state.companies
      const initialCheckedItems = new Set(state.companies);
      setCheckedItems(initialCheckedItems);

      // Filter and map company data to create the initial companiesOptions
      const initialCompaniesOptions = companyData.companies
        .filter((company) => initialCheckedItems.has(company.id)) // Use Set for O(1) lookup
        .map((company) => ({
          label: company.name,
          value: company.id,
        }));

      setState((prevData) => ({
        ...prevData,
        companiesOptions: initialCompaniesOptions,
      }));
    }
  }, [companyData, state.companies]);

  const handleCheckBoxClick = (company: any, isChecked: boolean) => {
    setCheckedItems((prev) => {
      const updated = new Set(prev);
      if (isChecked) {
        updated.add(company.id); // Add to checked items
      } else {
        updated.delete(company.id); // Remove from checked items
      }
      return updated;
    });

    // Call handleCompanyChange directly to update `companies` and `companiesOptions`
    handleCompanyChange(company, isChecked);
  };

  const handleCompanyChange = (company: any, isChecked: boolean) => {
    setState((prevData) => {
      // Update the `companies` array with only company IDs
      const updatedCompanies = isChecked
        ? [...prevData.companies, company.id]
        : prevData.companies.filter((id) => id !== company.id);

      // Update the `companiesOptions` array with company objects
      const updatedCompaniesOptions = isChecked
        ? [
            ...prevData.companiesOptions,
            { label: company.name, value: company.id },
          ]
        : prevData.companiesOptions.filter(
            (option) => option.value !== company.id
          );

      return {
        ...prevData,
        companies: updatedCompanies,
        companiesOptions: updatedCompaniesOptions,
      };
    });
  };

  // Filtering selected companies id in default_emp_legal array of objects
  const validateDefaultEmpLegal = () => {
    setState((prevData) => {
      const updatedData = { ...prevData };
  
      // Specify the type for indicesToRemove
      const indicesToRemove: number[] = []; // Array to keep track of indices to remove
  
      // Loop through default_emp_legal and filter out invalid entries
      updatedData.default_emp_legal = updatedData.default_emp_legal.filter((entry, index) => {
        const isValid =
          entry.company_id === null || updatedData.companies.includes(entry.company_id);
  
        // If the entry is invalid, mark the index for removal
        if (!isValid) {
          indicesToRemove.push(index);
        }
  
        return isValid; // Only keep valid entries
      });
  
      // Store all current options in temporary variables directly from state
      const allPCOptions = [...pcOptions]; // Use the state variable directly
      const allFunctionsOptions = [...fuctionsOptions]; // Use the state variable directly
      const allEmployeeTypeOptions = [...employeeTypeOptions]; // Use the state variable directly
  
      // Filter options based on indices to remove
      const updatedPCOptions = allPCOptions.filter((_, i) => !indicesToRemove.includes(i));
      const updatedFunctionsOptions = allFunctionsOptions.filter((_, i) => !indicesToRemove.includes(i));
      const updatedEmployeeTypeOptions = allEmployeeTypeOptions.filter((_, i) => !indicesToRemove.includes(i));
  
      // Update options arrays in the state
      setPCOptions(updatedPCOptions);
      setFuctionsOptions(updatedFunctionsOptions);
      setEmployeeTypeOptions(updatedEmployeeTypeOptions);
  
       // Update selectedFunctionIds based on remaining default_emp_legal
      const updatedSelectedFunctionIds = new Set<number>(
        updatedData.default_emp_legal.map(entry => entry.function_id) // Get function IDs
        .filter((id): id is number => id !== undefined && id !== null) // Filter out undefined and null
      );

      setSelectedFunctionIds(updatedSelectedFunctionIds);
  
      return updatedData; // Return the updated form data
    });
  };
  
  useEffect(() => {
    validateDefaultEmpLegal();
  }, [state.companies]);
  
  const setFormErrors = (name: string, isValid: boolean) => {
    setState((prevData) => {
      const updatedErrors = { ...prevData.errors };
      if (!isValid) {
        updatedErrors[name] = "This field is required";
      } else {
        delete updatedErrors[name];
      }
      return {
        ...prevData,
        errors: updatedErrors,
      };
    });
  };

  const validateFields = () => {
    const lastIndex = state.default_emp_legal.length - 1;
    const lastField = state.default_emp_legal[lastIndex];
    const isValid =
      lastField?.company_id &&
      lastField?.paritaire_commitee_id &&
      lastField?.employee_type_id &&
      lastField?.function_id &&
      lastField?.minWage.trim() !== "";

    if (!isValid) {
      setFormErrors(
        `default_emp_legal.${lastIndex}.company_id`,
        !!lastField?.company_id
      );
      setFormErrors(
        `default_emp_legal.${lastIndex}.paritaire_commitee_id`,
        !!lastField?.paritaire_commitee_id
      );
      setFormErrors(
        `default_emp_legal.${lastIndex}.employee_type_id`,
        !!lastField?.employee_type_id
      );
      setFormErrors(
        `default_emp_legal.${lastIndex}.function_id`,
        !!lastField?.function_id
      );
      setFormErrors(
        `default_emp_legal.${lastIndex}.minWage`,
        !!lastField?.minWage
      );
    }

    return isValid;
  };

  const handleAddField = () => {
    const isValid = validateFields();
    if (isValid || state.default_emp_legal.length === 0) {
      const newIndex = state.default_emp_legal.length;
      // Add a new entry to the default_emp_legal array in state
      setState((prevFormData) => ({
        ...prevFormData,
        default_emp_legal: [
          ...prevFormData.default_emp_legal.slice(0, newIndex),
          {
            company_id: null,
            paritaire_commitee_id: 0,
            employee_type_id: 0,
            function_id: 0,
            minWage: "",
            actual_salary: "",
            is_actual_added: 0,
          },
          ...prevFormData.default_emp_legal.slice(newIndex),
        ],
      }));
    }
  };

  const handleRemoveField = (index: number) => {
    removeFunctionId(index);
    // Remove PC, function options and employee types for the removed index
    setPCOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    setFuctionsOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
    setEmployeeTypeOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );

    setState((prevFormData) => {
      const updatedEmpLegal = prevFormData.default_emp_legal.filter(
        (_: any, i: number) => i !== index
      );
      Object.keys(prevFormData.errors).forEach((key) => {
        if (key.startsWith(`default_emp_legal.`)) {
          const newIndex = key.split(".")[1];
          if (Number(newIndex) > index) {
            const newKey = `default_emp_legal.${Number(newIndex) - 1}`;
            prevFormData.errors[newKey] = prevFormData.errors[key];
            delete prevFormData.errors[key];
          } else if (Number(newIndex) === index) {
            delete prevFormData.errors[key];
          }
        }
      });
      return {
        ...prevFormData,
        default_emp_legal: updatedEmpLegal,
      };
    });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <LabelField
            title={t("To which company would you like to add this employee")}
            className="tab-title"
            isMandatory
          />
          {state?.errors?.companies && (
            <span className="text-danger"> {state?.errors?.companies}</span>
          )}
        </div>
        <div className="col-12 mb-3">
          {companyData?.companies?.map((company) => (
            <div
              key={company?.id}
              className={`checkBoxContainer ${
                checkedItems.has(company.id) ? "border-class" : ""
              } d-inline-block`}
            >
              <CheckBoxField
                name="addemployee"
                value="0"
                id={`company-${company.id}`}
                lineHeight="25px"
                className="checkBoxLabel"
                checkBoxWrapper="addEmployeeToCompany me-4 pe-3"
                wrapperClass="d-inline-flex"
                isChildren
                isChecked={checkedItems.has(company.id)}
                onChangeHandler={(event) => {
                  handleCheckBoxClick(company, event.target.checked); // Handle check/uncheck
                }}
              >
                <div data-bs-toggle="tooltip" title={company?.name}>
                  <Image
                    src={company?.profile_picture_url ?? defaultCompanyIcon}
                    imageWidth="3vw"
                    imageHeight="3vw"
                    imageBorderRadius="50%"
                  />
                </div>
              </CheckBoxField>
            </div>
          ))}
        </div>

        <div className="col-12">
          <LabelField
            title={t("Function & hourly wage")}
            className="tab-title"
          />
        </div>
        {state?.errors?.default_emp_legal && (
          <span className="text-danger">
            {state?.errors?.default_emp_legal}
          </span>
        )}
      </div>
      <div className="row">
        {state.default_emp_legal.map((field, index) => (
          <div key={index} className="row position-relative">
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Desired company:"
                  title="Desired company"
                  name="company_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={state.companiesOptions || []} // Pass the options here
                  value={
                    state.companiesOptions.find(
                      (option) =>
                        option.value ===
                        state.default_emp_legal[index]?.company_id
                    ) || null
                  }
                  onChange={(selectedOption: any) => {
                    fetchPCOptions(index, Number(selectedOption.value));
                    handleSelectChange(
                      selectedOption,
                      `default_emp_legal.${index}.company_id`,
                      index
                    );
                  }}
                  error={
                    state.errors[`default_emp_legal.${index}.company_id`] || ""
                  }
                  isTranslate
                  labelWrapperClassName="col-4"
                  colClassName="col-8"
                />
              </div>
            </div>

            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Paritair committee:"
                  title="Paritair committee"
                  name="paritaire_commitee_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={pcOptions[index] || []} // Pass the options here
                  value={
                    (pcOptions[index] || []).find(
                      (option: OptionType) =>
                        option.value ===
                        state.default_emp_legal[index]?.paritaire_commitee_id
                    ) || null
                  }
                  onChange={(selectedOption: OptionType) => {
                    if (selectedOption.value !== undefined) {
                      fetchOptions(index, Number(selectedOption.value));
                    }
                    handleSelectChange(
                      selectedOption,
                      `default_emp_legal.${index}.paritaire_commitee_id`,
                      index
                    );
                  }}
                  error={
                    state.errors[
                      `default_emp_legal.${index}.paritaire_commitee_id`
                    ] || null
                  }
                  isTranslate
                  labelWrapperClassName="col-4"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Function:"
                  title="Function"
                  name="function_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={(fuctionsOptions[index] || [])
                    .filter(
                      (option: FunctionOptionType) =>
                        !selectedFunctionIds.has(option.value) ||
                        option.value ===
                          state.default_emp_legal[index]?.function_id
                    )
                    .sort((a: FunctionOptionType, b: FunctionOptionType) =>
                      a.label.localeCompare(b.label)
                    )}
                  value={
                    (fuctionsOptions[index] || []).find(
                      (option: FunctionOptionType) =>
                        option.value ===
                        state.default_emp_legal[index]?.function_id
                    ) || null
                  }
                  onChange={(selectedOption: FunctionOptionType | null) => {
                    handleFunctionChange(index, "function_id", selectedOption);
                  }}
                  isTranslate
                  error={
                    state.errors[`default_emp_legal.${index}.function_id`] || ""
                  }
                  labelWrapperClassName="col-4"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row multiSelectWrapper">
                <SelectWithSearch
                  fieldLabel="Employee type:"
                  title="Employee type"
                  name="employee_type_id"
                  placeHolder="Select"
                  isMandatory
                  search
                  options={(employeeTypeOptions[index] || []).sort(
                    (a: OptionType, b: OptionType) =>
                      a.label.localeCompare(b.label)
                  )}
                  value={
                    (employeeTypeOptions[index] || []).find(
                      (option: OptionType) =>
                        option.value ===
                        state.default_emp_legal[index]?.employee_type_id
                    ) || null
                  }
                  onChange={(selectedOption: OptionType) => {
                    handleSelectChange(
                      selectedOption,
                      `default_emp_legal.${index}.employee_type_id` // Correct dynamic field path
                    );
                  }}
                  isMulti={false}
                  className="select-field"
                  error={
                    state.errors[
                      `default_emp_legal.${index}.employee_type_id`
                    ] || ""
                  }
                  isTranslate
                  labelWrapperClassName="col-4 align-self-center"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row">
                <LabelWithInputField
                  label="Minimum wage:"
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  id={`minWage-${index}`}
                  value={state.default_emp_legal[index]?.minWage || ""} // Access the value from the array
                  name="minWage"
                  error={
                    state.errors[`default_emp_legal.${index}.function_id`] || ""
                  }
                  isDisabled={true}
                  labelWrapperClassName="col-4 align-self-center"
                  autoComplete="off"
                >
                  <span className="colorPrimary helpText">
                    *If flexi excl holiday pay
                  </span>
                </LabelWithInputField>
              </div>
            </div>
            <div className="col-4 mb-3">
              <div className="row">
                <LabelWithInputField
                  label="Actual wage:"
                  className="inputFieldColor"
                  colClassName="col-8"
                  type="text"
                  id={`actual_salary-${index}`}
                  value={state.default_emp_legal[index]?.actual_salary || ""} // Access the value from the array
                  handleChange={handleChange(
                    "default_emp_legal",
                    "actual_salary",
                    undefined,
                    index
                  )}
                  name="actual_salary"
                  error={
                    state.errors[`default_emp_legal.${index}.actual_salary`] ||
                    ""
                  }
                  labelWrapperClassName="col-4 align-self-center"
                  autoComplete="off"
                >
                  <span className="colorPrimary helpText">
                    *If flexi excl holiday pay
                  </span>
                </LabelWithInputField>
              </div>
            </div>
            {state.default_emp_legal.length !== 1 ? (
              <div className="col-1 position-absolute bottom-0 right-0 text-center">
                <Button
                  title="X"
                  className="modalSaveBtn"
                  handleClick={() => handleRemoveField(index)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
        <div className="col-12 mb-3 py-3 text-center">
          <Button
            title={"+ Add another PC"}
            className="modalSaveBtn"
            handleClick={handleAddField}
          />
        </div>
      </div>
    </>
  );
};
export default EmployeeOthers;
