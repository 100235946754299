import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory for navigation
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import Link from "../common/atoms/Link";
// import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import * as ROUTE from "../../routes/RouteConstants";
import {
	selectPlanningShow,
	setShow,
} from "../../features/planning/planningSlice";
import PlanningTabs from "../Planning/PlanningTabs";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import { FullScreenLoading } from "../common/utlis/FullScreenLoading";
import OverFlowWrapper from "../common/OverFlowWrapper";
import BackLink from "../common/atoms/BackLink";

const EmployeeOverview = () => {
	const navigate = useNavigate();
	const show = useSelector(selectPlanningShow);

	interface DataItem {
		id: number;
		first_name: string;
		last_name: string;
		is_fav: boolean;
		profile_picture_url: string | null;
		employee_type: string;
		employee_type_id: number;
		employee_type_category: string;
		employee_type_category_id: number;
		paritaire_commitee: string;
		paritaire_commitee_id: number;
		functional_salary: string;
		function_id: number;
		function_name: string;
	}

	const [data, setData] = useState<DataItem[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const companyId = useSelector(
		(state: any) => state?.auth?.currentCompany?.id
	);
	const dispatch = useDispatch();
	const fetchInfo = async () => {
		try {
			const res = await ApiCall.getService(
				`companyEmployees?company_id=${companyId}`,
				"GET"
			);
			if (res.status !== 200) {
				throw new Error("Network response was not ok");
			}
			if (res.status === 200) {
				setData(res.data);
				// dispatch(setEmployeeData(res.data)); // Dispatch the data to Redux
			} else {
				throw new Error(res.message);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	if (loading)
		return (
			<div>
				<FullScreenLoading />
			</div>
		);

	const handleEmployeeClick = (employee: any) => {
		const employeeId = employee?.id; // Get the actual employee ID
		navigate(`${ROUTE.EMPLOYEE_EDIT.replace(":id", employeeId)}`);
	};

	// const handleEmployeeClick = (employee: any) => {
	//   navigate(`${ROUTE.EMPLOYEE_EDIT}/${employee?.id}`);
	// };

	const toggleFavorite = async (employeeId: number, is_fav: boolean) => {
		try {
			setData(data);
			// Prepare data object for API call
			const dataObj = {
				employee_id: employeeId,
				company_id: companyId,
				is_favourite: !is_fav,
			};
			// Make the API call to update the backend
			const res = await ApiCall.service("Employeefavourite", "POST", dataObj, true);
			if (res.status == 200) {
				const updatedData = data.map((employee) =>
					employee.id === employeeId
						? { ...employee, is_fav: !is_fav }
						: employee
				);
				setData(updatedData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<Header
				headerName={t("Your pool")}
				headerClassName="myAccountCardTitle"
			/>
			<div className="search-bar pb-2">
				<div className="row">
					<BackLink backLink="/" />
				</div>
			</div>
			<div className="createpagesBg">
				<div className="row mb-3">
					<div className="col-4 d-flex">
						<Link className="me-3" href="">
							<span title={t("Home")}>
								<Icon
									isIcon={true}
									className="secondaryColorHoverEffect"
									name="houseIcon"
									width="1.3vw"
									height="1.3vw"
								/>
							</span>
						</Link>
						<AutoCompleteWithSearch
							placeholder={t("Search employee")}
							suggestions={[]}
							value=""
							handleChange={() => { }}
						/>
					</div>
					<div className="col-6">
						<Button
							title={t("Employee scheduling")}
							className="saveBtn me-3"
							handleClick={() => dispatch(setShow(true))}
						/>
						<Button
							title={t("Add employee")}
							className="saveBtn me-3"
							handleClick={() => navigate(ROUTE.EMPLOYEE_INVITE)}
						/>
						<Button
							title={t("Register employee")}
							className="saveBtn"
							handleClick={() => navigate(ROUTE.EMPLOYEE_REGISTRATION)}
						/>
					</div>
					<div className="col-2 text-end">
						<div className="d-inline-block me-4 poppins-medium">
							{t("Total") + ":"}
							<span className="ms-1">{data?.length}</span>
						</div>
						<span onClick={() => { }}>
							<Icon name="FilterIcon" width="1vw" height="1vw" />
						</span>
					</div>
				</div>
				<OverFlowWrapper className="yourPoolWrapper">
					<div className="container-fluid ps-0">
						<div className="row">
							{data?.length > 0 ? (
								data?.map((employee_data) => (
									<div className="col-3 mb-4" key={employee_data?.id}>
										<EmployeeDetailsCard
											employee_data={employee_data}
											cardConfig={{
												cardClassName:
													"text-break text-start d-flex align-items-center justify-content-center",
												employeeInformationClass: "planningEmployeeDetails",
												cardMainClassName:
													"employeeDetailsCardPlanning colorPrimary position-relative h-100",
												iconClassName:
													"text-end cardEditIcon position-absolute top-0 end-0 cursor-pointer",
												isCardIcon: true,
												iconName: "EditIcon",
												favouriteIconName: "starIcon", // Star icon for favorite
												noFavouriteIconName: "whiteStarIcon", // Empty star icon for not favorite
												isFavourite: employee_data.is_fav, // Corrected field for favorite status
											}}
											handleClick={() => handleEmployeeClick(employee_data)}
											handleFavIcon={() =>
												toggleFavorite(employee_data?.id, employee_data?.is_fav)
											} // Toggle favorite when icon is clicked
										/>
									</div>
								))
							) : (
								<div className="col-12">{t("No employees available")}</div>
							)}
						</div>
					</div>
				</OverFlowWrapper>
			</div>
			{show && <PlanningTabs />}
		</>
	);
};

export default EmployeeOverview;
