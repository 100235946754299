import React from "react";
import InviteEmployeeOrganism from "../../../components/EmployeeRegistration/invite/InviteEmployeeOrganism";

const InviteEmployee: React.FC = () => {
	return (
		<InviteEmployeeOrganism />
	);
};

export default InviteEmployee;
